.modal-active,
.modaal-noscroll {
  #msta_langArea.msta-default {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
  }
}

body.hd-active {
  .global-menu {
    @include mq(no-tablet) {
      #msta_langArea.msta-default {
        background: transparent;
        &:before {
          background: url(../../img/common/icon_world.png) no-repeat;
          background-size: contain;
        }
        &:after {
          border-right-color: $white;
          border-bottom-color: $white;
        }
        .lang-text {
          color: $white;
        }
      }
    }
  }
}

.header {
  $this: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: $pc-header-height;
  background: $main-color;
  @include transition;
  z-index: 999;
  @include mq(tablet) {
    height: $sp-header-height;
  }
  &.active {
    background: $white;
    .logo {
      a {
        .text {
          color: $text-color;
        }
      }
    }
    .btn-area {
      display: none;
    }
  }
  .btn-spmenu {
    @include mq(pc) {
      width: 65px;
      height: $pc-header-height;
      display: flex !important;
    }
    @include mq(tablet) {
      width: 50px;
      height: $sp-header-height;
    }
    .bar-wrap {
      width: 30px;
      @include mq {
        height: 15px;
      }
      > span {
        width: 30px;
        &:nth-child(2) {
          @include mq {
            top: 7px;
          }
        }
      }
    }
    &.active {
      .bar-wrap {
        > span {
          &:nth-child(1) {
            transform: rotate(35deg);
          }
          &:nth-child(3) {
            transform: rotate(-35deg);
          }
        }
      }
    }
    p {
      @include ls(25);
    }
  }
  .h-inner {
    padding: 10px 0 10px 40px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(mpc) {
      padding-left: 15px;
    }
    @include mq(spc) {
      padding: 8px 0 8px 12px;
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq {
      height: 100%;
    }
    a {
      color: $white;
      display: flex;
      height: 100%;
      .img {
        @include mq(pc) {
          width: 57px;
        }
        @include mq(tablet) {
          width: 42px;
        }
        img {
          @include mq(tablet) {
            width: 100%;
            height: auto;
          }
        }
      }
      .img.museum-logo {
        margin: 0 0 0 8px;
        @include mq{
         position: relative;
         top: -3px;
        }
        @include mq(pc) {
          width: 50px;
        }
        @include mq(tablet) {
          width: 36px;
        }

        img {
          @include mq(tablet) {
            width: 100%;
            height: auto;
          }
        }
      }
      .text {
        margin-left: 13px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @include mq {
          display: none;
        }
      }
      .jp {
        font-size: 2rem;
        font-weight: bold;
        @include ls(25);
        margin-top: 2px;
        @include mq(mpc) {
          font-size: 1.7rem;
        }
      }
      .en {
        font-size: 1.2rem;
        font-weight: normal;
        @include ls(75);
        display: block;
        margin-top: 8px;
        @include mq(tablet) {
          margin-top: 5px;
        }
        @include mq(mpc) {
          font-size: 1rem;
        }
      }
    }
  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .h-nav {
    @include mq(pc) {
      margin-right: 15px;
    }
    @include mq(tablet) {
      background: $white;
      width: 100%;
      height: 37px;
      position: fixed;
      top: $sp-header-height;
      left: 0;
    }
    ul {
      height: 37px;
      display: flex;
      align-items: center;
      @include mq(tablet) {
        justify-content: center;
      }
    }
    li {
      margin: 0 14px;
      @include mq(mpc) {
        margin: 0 9px;
      }
      @include mq {
        margin: 0 10px;
        height: 100%;
      }
    }
    a {
      @include link_color($white);
      font-size: 1.4rem;
      font-weight: bold;
      @include mq(mpc) {
        font-size: 1.2rem;
      }
      @include mq(tablet) {
        @include link_color($text-color);
        font-size: 1.2rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include mq(msp) {
          font-size: 1.1rem;
        }
        @include mq(ssp) {
          font-size: 1rem;
        }
      }
      &:hover {
        opacity: .7;
      }
    }
  }
  .btn-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(spc) {
      position: relative;
      max-width: 500px;
      margin: 0 auto;
    }
  }
  .beginner-btn {
    a {
      font-size: 1.4rem;
      font-weight: bold;
      background: $white;
      width: 158px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @include mq(mpc) {
        font-size: 1.2rem;
        width: 138px;
        height: 35px;
      }
      @include mq {
        font-size: 1rem;
        width: auto;
        height: 30px;
        padding: 10px 5px;
      }
      &:hover {
        @include mq(pc) {
          color: #BA1300;
          opacity: .9;
        }
      }
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 10px;
        background: url(../../img/common/icon_beginner.svg) no-repeat;
        background-size: contain;
        @include mq {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
  }
  .lang-select-wrap {
    width: 170px;
    height: 40px;
    position: relative;
    @include mq(mpc) {
      height: 35px;
    }
    @include mq(pc) {
      margin-left: 16px;
      @include mq(mpc) {
        width: 147px;
        margin-left: 10px;
      }
    }
    @include mq(spc) {
      width: 110px !important;
      margin-left: 5px !important;
      order: 1;
    }
    @include mq {
      height: 30px;
    }
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(../../img/common/icon_lang.svg) no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      z-index: 10;
      @include mq(tablet) {
        left: 10px;
      }
    }
    &:after {
      content: '\f0d7';
      font-family: $font-awesome;
      font-size: 1.1rem;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      z-index: 10;
      @include mq(mpc) {
        right: 10px;
      }
      @include mq {
        right: 10px;
      }
    }
  }
  .lang-select {
    width: 100%;
    height: 100%;
    background-color: transparent !important;
    padding: 0 !important;
    position: relative !important;
    top: 0 !important;
    z-index: 0 !important;
    @include mq(spc) {
      right: 0 !important;
    }
    .lang-text {
      display: none;
    }
    select {
      font-size: 1.4rem;
      font-weight: bold;
      @include ls(50);
      width: 100%;
      height: 100%;
      padding-left: 48px;
      cursor: pointer;
      text-indent: 0.01px;
      text-overflow: ellipsis;
      border: none;
      outline: none;
      background: $white;
      background-image: none;
      box-shadow: none;
      -webkit-appearance: none;
      appearance: none;
      @include mq(tablet) {
        padding-left: 38px;
      }
      @include mq(mpc) {
        font-size: 1.2rem;
      }
      @include mq {
        font-size: 1rem;
      }
      option {
        color: $text-color !important;
        &.msta_selected {
          color: #999 !important;
        }
      }
    }
  }
  .menu-conts {
    display: none;
    width: 100%;
    background: $white;
    position: fixed;
    top: $pc-header-height;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    @include mq {
      top: 60px;
    }
    &-inner {
      padding: 31px;
      height: 100%;
      min-height: 100vh;
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }
      @include mq {
        padding: 15px;
      }
    }
    &-box {
      @include mq(pc) {
        width: 48%;
      }
    }
    .list01 {
      line-height: 1.5;
      > li {
        + li {
          @include mq(pc) {
            margin-top: 30px;
          }
          @include mq {
            margin-top: 10px;
          }
        }
        &:nth-child(1) {
          .tour-btn {
            background: #55b5c6;
          }
          img {
            @include mq {
              width: 31px;
            }
          }
          .tour-conts {
            background: #e3f1f4;
          }
          .ttl {
            color: #55b5c6;
          }
          ol {
            a {
              @include mq(pc) {
                border-color: #55b5c6;
                &:hover {
                  background: #e3f1f4;
                }
                &:after {
                  background: url(../../img/common/icon_arrow02_aqua.svg) no-repeat;
                  background-size: contain;
                }
              }
            }
          }
          .num {
            background: #55b5c6;
          }
          .btn-rsv {
            a {
              background: #55b5c6;
              &:hover {
                background: #449bab;
              }
            }
          }
          .btn-rsvd {
            a {
              &:hover {
                color: #55b5c6;
                border-color: #55b5c6;
              }
            }
          }
        }
        &:nth-child(2) {
          .tour-btn {
            background: #ff007e;
          }
          img {
            @include mq {
              width: 33px;
            }
          }
          .tour-conts {
            background: #fdecf4;
          }
          .ttl {
            color: #ff007e;
          }
          ol {
            a {
              @include mq(pc) {
                border-color: #ff007e;
                &:hover {
                  background: #fdecf4;
                }
                &:after {
                  background: url(../../img/common/icon_arrow02_pink.svg) no-repeat;
                  background-size: contain;
                }
              }
            }
          }
          .num {
            background: #ff007e;
          }
          .btn-rsv {
            a {
              background: #ff007e;
              &:hover {
                background: #d7006a;
              }
            }
          }
          .btn-rsvd {
            a {
              &:hover {
                color: #ff007e;
                border-color: #ff007e;
              }
            }
          }
        }
      }
      .tour-btn {
        color: $white;
        font-size: 1.4rem;
        font-weight: bold;
        @include ls(25);
        line-height: 21px;
        padding: 18px 20px 18px 74px;
        display: block;
        position: relative;
        cursor: pointer;
        @include mq {
          padding: 15px 35px 15px 53px;
          white-space: nowrap;
          &.active {
            &:after {
              transform: rotate(180deg);
            }
          }
          &:before,
          &:after {
            content: '';
            width: 18px;
            height: 1px;
            background: $white;
            display: block;
            position: absolute;
            top: 50%;
            right: 15px;
            @include mq {
              right: 10px;
            }
          }
          &:after {
            transform: rotate(90deg);
            transition: .3s ease;
          }
        }
        img {
          width: auto;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          @include mq {
            left: 12px;
          }
        }
        em {
          color: #fff000;
          font-size: 1.7rem;
        }
        > span {
          font-size: 1.6rem;
          @include mq {
            font-size: 1.5rem;
          }
        }
      }
      .tour-conts {
        @include ls(25);
        padding: 30px;
        @include mq(pc) {
          display: block !important;
        }
        @include mq(mpc) {
          padding: 20px;
        }
        @include mq {
          padding: 15px;
          display: none;
        }
        .text {
          font-size: 1.3rem;
          text-align: center;
          @include mq(pc) {
            display: none;
          }
        }
        .sub-note {
          font-size: 1.1rem;
          font-weight: normal;
          text-align: center;
          margin-top: 8px;
          @include mq(pc) {
            display: none;
          }
        }
        .ttl {
          font-size: 1.8rem;
          font-weight: bold;
          text-align: center;
          margin-top: 3px;
          @include mq(pc) {
            display: none;
          }
        }
        ol {
          font-size: 1.6rem;
          margin-top: 10px;
          @include mq(pc) {
            margin-top: -10px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }
          li {
            @include mq(pc) {
              width: calc(50% - 5px);
              margin-top: 10px;
            }
            + li {
              @include mq {
                margin-top: 10px;
              }
            }
          }
          a {
            width: 100%;
            display: block;
            position: relative;
            &:hover {
              &:after {
                right: 17px;
              }
            }
            @include mq(pc) {
              font-weight: bold;
              background: $white;
              border-width: 1px;
              border-style: solid;
              border-radius: 50px;
              padding: 14px 40px 12px 52px;
              @include mq(mpc) {
                line-height: 1.3;
              }
              &:after {
                content: '';
                width: 14px;
                height: 12px;
                display: block;
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                transition: .3s ease all;
              }
            }
            @include mq {
              padding-left: 28px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
            }
          }
          .num {
            color: $white;
            font-size: 1.3rem;
            font-weight: bold;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0;
            top: 0;
            @include mq(pc) {
              top: 50%;
              left: 20px;
              transform: translateY(-50%);
            }
          }
          .sub {
            font-size: 1.2rem;
            display: block;
          }
        }
        .btn-box {
          margin-top: 20px;
          @include mq(pc) {
            display: flex;
            justify-content: space-between;
            @include mq(mpc) {
              display: block;
            }
          }
          > div {
            + div {
              text-align: center;
              @include mq {
                margin-top: 5px;
              }
            }
          }
        }
        .btn-rsv {
          position: relative;
          height: 65px;
          @include mq(pc) {
            width: calc(100% - 240px);
            @include mq(mpc) {
              width: 100%;
            }
          }
          a {
            color: $white;
            font-size: 1.8rem;
            font-weight: bold;
            width: 100%;
            height: 100%;
            padding-left: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @include mq {
            }
            &:after {
              content: '';
              width: 26px;
              height: 26px;
              background: url(../../img/common/icon_calendar02.svg) no-repeat;
              background-size: contain;
              position: absolute;
              top: 50%;
              left: 28px;
              transform: translateY(-50%);
              @include mq {
                left: 19vw;
              }
            }
          }
          span {
            font-size: 1.2rem;
            font-weight: normal;
            display: block;
          }
        }
        .btn-rsvd {
          background: $white;
          @include mq(pc) {
            width: 230px;
            height: 65px;
            @include mq(mpc) {
              width: 100%;
              margin-top: 10px;
            }
          }
          @include mq {
            height: 55px;
          }
          a {
            font-size: 1.2rem;
            font-weight: normal;
            width: 100%;
            height: 100%;
            border-width: 1px;
            border-style: solid;
            border-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .3s ease;
          }
        }
        .search-box {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mq(pc) {
            display: none;
          }
          input {
            border: 1px solid #999;
            border-radius: 5px;
            width: calc(100% - 41px);
            padding: 6px;
            background: $white;
            -webkit-appearance: none;
            appearance: none;
          }
          button {
            color: $white;
            font-size: 1.6rem;
            background: $black01;
            width: 35px;
            height: 35px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .def01 {
      @include mq {
        margin-top: 20px;
      }
      dt {
        font-size: 2.4rem;
        @include ls(75);
        border-bottom: 1px solid currentColor;
        @include mq(pc) {
          padding-bottom: 20px;
        }
        @include mq {
          font-size: 1.8rem;
          height: 65px;
          display: flex;
          align-items: center;
          position: relative;
          &.active {
            &:after {
              transform: rotate(180deg);
            }
          }
          &:before,
          &:after {
            content: '';
            width: 18px;
            height: 1px;
            background: #333;
            display: block;
            position: absolute;
            top: 50%;
            right: 15px;
            @include mq {
              right: 10px;
            }
          }
          &:after {
            transform: rotate(90deg);
            transition: .3s ease;
          }
        }
      }
      > div {
        + div {
          @include mq(pc) {
            margin-top: 40px;
          }
          @include mq {
            margin-top: 10px;
          }
        }
        > div {
          margin-top: 24px;
          @include mq(pc) {
            display: block !important;
          }
          @include mq {
            margin-top: 10px;
            display: none;
          }
        }
      }
      dd {
        + dd {
          margin-top: 5px;
        }
        a {
          font-size: 1.5rem;
          font-weight: bold;
          @include ls(25);
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          line-height: 1.4;
          small{
            font-size: 1.2rem;
            @include mq {
              font-size: 1.0rem;
            }
          }

          @include mq(pc) {
            height: 52px;
            border: 1px solid $black01;
            border-radius: 26px;
            padding: 10px 29px;
            &:hover {
              background: #e7e7e7;
              &:after {
                right: 17px;
              }
            }
          }
          @include mq {
            padding: 10px 0px;
            br.sp{
              display: block;
            }
          }
          &:after {
            content: '';
            width: 14px;
            height: 12px;
            background: url(../../img/common/icon_arrow02_black.svg) no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            transition: .3s ease;
          }
        }
      }
    }
    .list02 {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      li {
        width: calc(100% / 3 - 3px);
        height: 50px;
        display: flex;
        @include mq {
          width: calc(33vw - 15px);
          height: calc(33vw - 15px);
        }
      }
      a {
        font-size: 1.2rem;
        font-weight: bold;
        @include ls(5);
        border: 1px solid #c01920;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        @include mq(pc) {
          padding: 15px 10px;
          &:hover {
            color: $white;
            background: #c01920;
            i {
              color: #c01920;
              background: $white;
            }
          }
        }
        @include mq {
          font-size: 1.2rem;
          justify-content: center;
          flex-direction: column;
        }
      }
      i {
        color: $white;
        background: #c01920;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq(pc) {
          font-size: 1.6rem;
          margin-right: 12px;
          width: 30px;
          height: 30px;
          transition: .3s ease;
        }
        @include mq {
          font-size: 2.1rem;
          margin-bottom: 10px;
          width: 50px;
          height: 50px;
        }
      }
    }
    .list03 {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        &.fb a {
          background: $color-fb;
        }
        &.ig a {
          background: $color-ig;
        }
        &.tw a {
          background: $color-tw;
        }
        a {
          color: $white;
          font-size: 1.8rem;
          width: 36px;
          height: 36px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }
}

.global-menu {
  width: calc(100% - 280px);
  height: 80px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background: $white;
  display: flex;
  justify-content: flex-end;
  transition: .3s ease;
  @include mq(tablet) {
    width: 100%;
    height: 70px;
    padding-right: 191px;
    justify-content: space-between;
    @include mq {
      height: 60px;
      padding-right: 0;
    }
  }
  .logo {
    padding: 9px 0 9px 10px;
    @include mq(no-tablet) {
      display: none;
    }
    @include mq(tablet) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .logo-box {
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      a {
        display: block;
        transition: .3s ease opacity;
        &:hover {
          opacity: .8;
        }
        &:first-child {
          width: 57px;
          margin-right: 6px;
          @include mq {
            width: 50px;
          }
        }
        &:last-child {
          width: 43px;
          @include mq {
            width: 37px;
          }
        }
      }
    }
    .text {
      width: auto;
      display: none;
      vertical-align: middle;
      @include mq(tablet) {
        display: inline-block;
        margin-left: 11px;
        @include mq(spc) {
          display: none;
        }
      }
    }
  }
  &.fixed,
  &.active {
    background: $white;
    .list > li > a {
      color: $text-color;
    }
    .list .def dt {
      color: $text-color;
      &:after {
        border-right-color: $text-color;
        border-bottom-color: $text-color;
      }
    }
  }
  &.active {
    .lang-text {
      color: $white;
    }
    .menu-conts {
      .btm-list {
        animation: btm-fade 1s ease forwards;
      }
      @keyframes btm-fade {
        0% {
          opacity: 0;
        }
        40% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      .sns-list {
        @include mq(pc) {
          opacity: 1;
        }
      }
    }
  }
  .spaccess {
    position: absolute;
    top: 0;
    right: 239px;
    height: 100%;
    display: none;
    a {
      display: flex;
      align-items: center;
      color: #fff;
      background: #005aad;
      border-right: 1px solid #fff;
      font-weight: bold;
      height: 100%;
      padding: 0 10px;
      font-size: 14px;
    }
    @include mq() {
      display: block;
    }
  }
  .spshop {
    position: absolute;
    top: 0;
    right: 162px;
    height: 100%;
    display: none;
    a {
      display: flex;
      align-items: center;
      color: #fff;
      background: #005aad;
      border-right: 1px solid #fff;
      font-weight: bold;
      height: 100%;
      padding: 0 10px;
      font-size: 14px;
    }
    @include mq() {
      display: block;
    }
  }
  .lang-select {
    position: fixed;
    z-index: 999;
    @include mq(no-tablet) {
      width: 80px;
      height: 80px;
      top: 80px;
      right: 0;
    }
    @include mq(tablet) {
      width: 120px;
      height: 70px;
      top: 0;
      right: 70px;
      @include mq {
        height: 60px;
        top: 0;
        right: 52px;
        border-right: 1px solid $white;
      }
    }
  }
  .lang-text {
    color: $text-color;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: .05em;
    padding: 33px 0 14px;
    vertical-align: middle;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
    cursor: pointer;
    pointer-events: none;
    @include mq(tablet) {
      display: none;
    }
  }
  #msta_langArea.msta-default {
    text-align: center;
    background-color: $white;
    padding: 0;
    position: absolute;
    z-index: 999 !important;
    transition: .3s ease;
    @include mq(no-tablet) {
      width: 100%;
      height: 100%;
      top: 0 !important;
      right: 0 !important;
    }
    @include mq(tablet) {
      background: #005AAD;
      height: 100%;
      top: 0 !important;
      @include mq {
        height: 100%;
      }
    }
    &:hover {
      @include mq(no-tablet) {
        opacity: .8;
      }
    }
    &:before {
      content: '';
      width: 23px;
      height: 23px;
      background: url(../../img/common/icon_world_aqua.png) no-repeat;
      background-size: contain;
      display: block;
      position: absolute;
      pointer-events: none;
      @include mq(no-tablet) {
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
      @include mq(tablet) {
        background: url(../../img/common/icon_world.png) no-repeat;
        background-size: contain;
        width: 18px;
        height: 18px;
        top: calc(50% - 10px);
        left: 17px;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      border-right: 2px solid #006FA2;
      border-bottom: 2px solid #006FA2;
      position: absolute;
      pointer-events: none;
      @include mq(no-tablet) {
        left: 50%;
        bottom: 12px;
        transform: translateX(-50%) rotate(45deg);
      }
      @include mq(tablet) {
        border-right-color: $white;
        border-bottom-color: $white;
        width: 5px;
        height: 5px;
        top: 48%;
        right: 13px;
        transform: translateY(-50%) rotate(45deg);
      }
    }
    .msta-lang-list {
      color: transparent;
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: .05em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border-radius: 0;
      border: 0;
      margin: 0;
      padding: 33px 0 14px;
      background: none transparent;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      text-align: -webkit-center;
      cursor: pointer;
      @include mq(tablet) {
        color: $white;
        padding: 0 20px 0 38px;
      }
    }
    .msta_standby {
      color: $text-color;
    }
  }
  .btn-spmenu {
    width: 80px;
    height: 60px;
    background: #005AAD;
    display: flex !important;
    margin-left: 0;
    transition: .3s ease background;
    @include mq(tablet) {
      position: fixed;
      top: 0;
      right: 0;
    }
    &:hover {
      @include mq(pc) {
        background: #006fcf;
      }
    }
    @include mq(tablet) {
      width: 70px;
      height: 70px;
      @include mq {
        width: 60px;
        height: 60px;
      }
    }
    @include mq {
      &.active .bar-wrap > span:nth-child(1) {
        width: 18px;
        top: 5px;
      }
      &.active .bar-wrap > span:nth-child(3) {
        width: 18px;
        top: 5px;
      }
    }
    p {
      font-family: $font-mont;
      font-size: 1.3rem;
      font-weight: bold;
      margin-top: 10px;
      @include mq {
        display: none;
      }
    }
    .bar-wrap {
      width: 24px;
      height: 16px;
      @include mq {
        width: 17px;
        height: 14px;
      }
      > span {
        width: 24px;
        height: 2px;
        @include mq {
          width: 17px;

        }
        &:nth-child(2) {
          top: 7px;
          @include mq {
            top: 6px;
          }
        }
      }
    }
  }
  .list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mq(pc) {
      height: 100%;
      padding-right: 15px;
    }
    @include mq {
      background: $white;
      width: 100%;
      height: 52px;
      border-top: 1px solid #D9DFE9;
      border-bottom: 1px solid #D9DFE9;
      justify-content: space-between;
      position: fixed;
      top: 60px;
      left: 0;
    }
    > li {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0 16px;
      height: 100%;
      @include mq(no-tablet) {
        color: $white;
      }
      @include mq(pc) {
        @include mq(spc) {
          margin: 0 10px;
        }
      }
      @include mq {
        text-align: center;
        border-right: 1px solid #D9DFE9;
        margin: 0;
        width: calc(100% / 3);
        &:nth-child(3) {
          border-right: none;
        }
        &:nth-child(n+4) {
          display: none;
        }
      }
      &.tablet {
        @include mq(tablet) {
          display: none;
        }
      }
      > a {
        color: $text-color;
        @include ls(50);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s ease;
        &:hover {
          color: #006FA2;
        }
      }
    }
    .def {
      height: 100%;
      position: relative;
      dt {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include mq(no-tablet) {
          color: $text-color;
          transition: .3s ease color;
        }
        @include mq {
          color: $text-color;
          font-size: 1.2rem;
          @include ls(25);
          padding-bottom: 11px;
          flex-direction: column;
          position: relative;
        }
        &:after {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-right: 2px solid $text-color;
          border-bottom: 2px solid $text-color;
          transform: rotate(45deg);
          @include mq(pc) {
            margin-top: -4px;
            margin-left: 5px;
          }
          @include mq(tablet) {
            border-right-color: #005AAD !important;
            border-bottom-color: #005AAD !important;
          }
          @include mq {
            position: absolute;
            bottom: 14px;
            left: calc(50% - 2px);
          }
        }
        &.active {
          color: #006FA2;
          @include mq(no-tablet) {
            &:after {
              border-right-color: #006FA2 !important;
              border-bottom-color: #006FA2 !important;
              margin-top: 3px;
              transform: rotate(-135deg);
            }
          }
          @include mq {
            color: $white;
            background: #006FA2;
            &:after {
              border-right-color: $white !important;
              border-bottom-color: $white !important;
              bottom: 10px;
              transform: rotate(-135deg);
            }
          }
        }
      }
      dd {
        opacity: 0;
        position: absolute;
        top: 80px;
        left: -50px;
        transition: .3s ease;
        @include mq(tablet) {
          top: 70px;
          @include mq {
            width: 100%;
            position: fixed;
            top: 112px;
            left: 0;
          }
        }
        &:not(.active) {
          pointer-events: none;
        }
        &.active {
          opacity: 1;
        }
        ul {
          @include mq(pc) {
            width: 200px;
          }
        }
        li {
          + li {
            border-top: 1px solid #4EA6CE;
          }
        }
        a {
          color: $white;
          font-size: 1.5rem;
          font-weight: bold;
          @include ls(50);
          line-height: 1.5;
        //  background: #006FA2;
          background: #006fcf;
          padding: 11px 11px 11px 38px;
          display: block;
          position: relative;
          transition: .3s ease;
          @include mq {
            text-align: left;
          }
          &:hover {
            @include mq(pc) {
              background: #00577e;
            }
          }
          &:before {
            content: '';
            display: block;
            width: 12px;
            height: 13px;
            background: url(../../img/common/icon_arrow01_aqua.png) no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
          }
          &._pdf {
            &:before {
              width: 15px;
              height: 16px;
              background: url(../../img/common/icon_pdf_aqua.png) no-repeat;
              background-size: contain;
            }
          }
          &._rsvd {
            &:before {
              width: 16px;
              height: 18px;
              background: url(../../img/common/icon_login_aqua.png) no-repeat;
              background-size: contain;
              left: 14px;
            }
          }
        }
      }
    }
  }
  .menu-conts {
    display: none;
    color: $white;
    width: calc(100vw - 280px);
    background: #005AAD;
    position: fixed;
    top: 0;
    left: 280px;
    bottom: 0;
    overflow-y: scroll;
    @include mq(tablet) {
      width: 100%;
      top: 0;
      left: 0;
      @include mq {
        top: 60px;
      }
    }
    &-inner {
      padding: 31px 31px 10vh;
      min-height: calc(100vh - 80px);
      @include mq(pc) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @include mq(tablet) {
        min-height: calc(100vh - 70px);
        @include mq {
          min-height: calc(100vh - 60px);
          padding: 15px 15px 150px;
          position: relative;
        }
      }
    }
    .sns-list {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 20px;
      right: 105px;
      transition: .3s ease;
      @include mq(pc) {
        opacity: 0;
      }
      @include mq(tablet) {
        right: 195px;
        @include mq {
          position: absolute;
          top: auto;
          bottom: 75px;
          right: 50%;
          transform: translateX(50%);
        }
      }
      li {
        margin: 0 5px;
        a {
          color: $white;
          font-size: 1.4rem;
          width: 35px;
          height: 35px;
          border: 1px solid $white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: #2381d9;
          }
        }
      }
    }
    .def {
      @include mq(pc) {
        display: flex;
        justify-content: center;
      }
      > div {
        border-top: 4px solid #0968BF;
        padding-top: 17px;
        @include mq(pc) {
          width: 320px;
        }
        + div {
          @include mq(pc) {
            margin-left: 64px;
          }
          @include mq {
            margin-top: 40px;
          }
        }
        > dt {
          font-size: 2rem;
          @include ls(80);
          margin-bottom: 30px;
          @include mq {
            font-size: 1.8rem;
            margin-bottom: 20px;
          }
        }
        > div {
          border-top: 1px solid #0A6DC8;
          margin-top: 27px;
          padding-top: 13px;
          > dt {
            color: #82B9EC;
            font-size: 1.7rem;
            @include ls(50);
            margin-bottom: 24px;
            @include mq {
              margin-bottom: 15px;
            }
          }
        }
      }
      dd {
        + dd {
          margin-top: 25px;
          @include mq {
            margin-top: 15px;
          }
        }
        a {
          color: $white;
          font-size: 1.5rem;
          font-weight: bold;
          @include ls(50);
          padding-left: 25px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          line-height: 1.4;
          @include mq(pc) {
            transition: .3s ease;
            &:hover {
              color: #82B9EC;
            }
          }
          &:before {
            content: '';
            width: 12px;
            height: 13px;
            background: url(../../img/common/icon_arrow01_aqua.png) no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
          &._pdf {
            &:before {
              width: 13px;
              height: 14px;
              background: url(../../img/common/icon_pdf_aqua.png) no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
    .btm-list {
      border-top: 1px solid #1E85E4;
      width: calc(100% - 280px);
      display: flex;
      justify-content: center;
      position: fixed;
      bottom: 0;
      right: 0;
      opacity: 0;
      @include mq(tablet) {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      li {
        width: calc(100% / 3);
        + li {
          border-left: 1px solid #1E85E4;
        }
      }
      a {
        color: $white;
        font-size: 1.4rem;
        @include ls(25);
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq(pc) {
          transition: .3s ease;
          &:hover {
            background: #0068c9;
          }
        }
        @include mq {
          font-size: 1.3rem;
        }
      }
      img {
        margin-right: 6px;
        width: auto;
      }
    }
  }
  /* ==========================
    初期設定
  ========================== */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  img {
    width: 100%;
  }

  /* ==========================
    フローティングバナー（追従バナー）
  ========================== */
  .bl_floatingBanner {
    position: fixed;
    bottom: 0;
    right: 10px;
    /* width: 150px; */
    max-width: 100%;
    @include mq{
      margin: 0 50px;
      right: 0;
    }
  }
  .bl_floatingBanner.js_close {
    display: none;
  }
  .bl_floatingBanner_img {
    display: block;
    transition: 0.3s;
  }
  .bl_floatingBanner_img:hover {
    opacity: 0.6;
  }
  .bl_floatingBanner_close {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 0;
    top: -15px;
    cursor: pointer;
  }
  .bl_floatingBanner_close span:nth-of-type(1) {
    position: absolute;
    top: 7px;
    right: 0;
    width: 15px;
    height: 1px;
    background-color: #999;
    transform: rotate(45deg);
  }
  .bl_floatingBanner_close span:nth-of-type(2) {
    position: absolute;
    top: 0;
    right: 7px;
    width: 1px;
    height: 15px;
    background-color: #999;
    transform: rotate(45deg);
  }

  /* ==========================
    コンテンツの中身
  ========================== */
  .ly_inner {
    width: 100%;
    max-width: 1080px;
    margin: 100px auto;
    padding: 20px;
    background-color: #ccc;
  }
  .ly_inner h2 {
    font-size: 150%;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .md_textblock > * + * {
    margin-top: 10px;
  }
}