#top2 {
  .page {
    padding-top: 0;
  }
  &:not(.hd-fixed):not(.hd-active) {
    @include mq(no-tablet) {
      #msta_langArea.msta-default {
        background: transparent;
        &:before {
          background: url(/img/common/icon_world.png) no-repeat;
          background-size: contain;
        }
        &:after {
          border-right-color: $white;
          border-bottom-color: $white;
        }
        .lang-text {
          color: $white;
        }
      }
    }
  }
  .global-menu {
    &:not(.fixed) {
      @include mq(pc) {
        background: transparent;
        .list > li > a {
          color: $white;
        }
        .list .def dt {
          color: $white;
          &:after {
            border-right-color: $white;
            border-bottom-color: $white;
          }
        }
        .lang-text {
          color: $white;
        }
      }
    }
  }
  .ttl {
    font-weight: bold;
    text-align: center;
    &._white {
      .jp {
        color: $white;
      }
    }
    .en {
      color: #006FA2;
      font-family: $font-mont;
      font-weight: 500;
      font-size: 4rem;
      @include ls(75);
      @include mq {
        font-size: 3.5rem;
      }
    }
    .jp {
      font-size: 1.8rem;
      @include ls(25);
      @include lh(18,32);
      display: block;
      @include mq {
        font-size: 1.5rem;
      }
    }
  }
  .sec-mainv {
    .mainv {
      height: calc(100vh - 51px);
      position: relative;
      background: #ccc;
      @include mq(pc) {
        height: calc(100vh - 121px);
        @include mq {
          height: calc(100vh - 230px);
        }
      }
    }
    .mainv-slide {
      height: 100%;
      img {
        @include object-fit(cover);
      }
    }
    .slick-list {
      height: 100%;
    }
    .slick-track {
      height: 100%;
    }
    .mainv-btm-box {
      width: calc(100% - 80px);
      position: absolute;
      bottom: 30px;
      @include mq(pc) {
        left: 40px;
      }
      @include mq {
        right: 15px;
        bottom: 15px;
      }
    }
    .mainv-text {
      color: $white;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      @include mq {
        padding-bottom: 60px;
      }
      h1 {
        font-family: $font-mont;
        font-size: 4.2rem;
        font-weight: 500;
        @include ls(280);
        @include mq {
          font-size: 3rem;
        }
      }
      p {
        font-family: $font-zen;
        font-size: 2rem;
        @include ls(140);
        margin-top: 13px;
        @include mq {
          font-size: 1.8rem;
        }
      }
    }
    .rate {
      color: $white;
      font-family: $font-zen;
      text-align: center;
      background: linear-gradient(#007AC7, #0044A9);
      width: 180px;
      height: 180px;
      border-radius: 50%;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include mq {
        width: 140px;
        height: 140px;
        right: 15px;
        bottom: 15px;
      }
      dt {
        font-family: $font-zen;
        font-size: 1.4rem;
        @include mq {
          font-size: 1.1rem;
        }
      }
      dd {
        font-family: $font-readex;
        font-size: 3rem;
        line-height: 1em;
        margin-top: 15px;
        padding: 0 26px;
        position: relative;
        @include mq {
          font-size: 2rem;
          margin-top: 11px;
          padding: 0 21px;
        }
        &:before,
        &:after {
          content: '';
          width: 22px;
          height: 53px;
          display: block;
          background: url(../../img/top/illust_rate.png) no-repeat;
          background-size: contain;
          position: absolute;
          bottom: -2px;
          @include mq {
            width: 17px;
            height: 40px;

            bottom: 0;
          }
        }
        &:before {
          left: 0;
        }
        &:after {
          transform: scale(-1,1);
          right: 0;
        }
        strong {
          font-size: 5.4rem;
          font-weight: 500;
          margin-right: 5px;
          @include mq {
            font-size: 4.5rem;
          }
        }
      }
      p {
        font-family: $font-zen;
        font-size: 1rem;
        @include ls(25);
        margin-top: 5px;
        @include mq {
          font-size: 1rem;
          margin-top: 3px;
        }
      }
      p{
        >strong{
          display: block;
          padding: 20px;
          font-size: 1.5rem;
          line-height: 1.7;
        }
      }
    }
    .mainv-news {
      text-align: center;
      border-radius: 5px;
      width: 100%;
      margin-top: 30px;
      overflow: hidden;
      @include mq(pc) {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @include mq {
        width: calc(100% - 60px);
        border: 2px solid #000;
        border-radius: 10px;
        margin: 30px 30px 0;
      }
      dt {
        color: $white;
        font-size: 1.5rem;
        font-weight: bold;
        @include ls(25);
        background: #000;
        width: 172px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq(pc) {
          height: 100%;
        }
        @include mq {
          font-size: 1.6rem;
          padding: 12px 5px;
          width: 100%;
        }
        &:before {
          content: '';
          width: 14px;
          height: 17px;
          background: url(/img/top/icon_bell.png) no-repeat;
          background-size: contain;
          display: block;
          margin-right: 9px;
          @include mq {
            margin-top: -1px;
          }
        }
      }
      dd {
        font-weight: bold;
        @include ls(25);
        text-align: left;
        width: calc(100% - 172px);
        background: $white;
        padding: 14px 45px 16px 24px;
        position: relative;
        @include mq(pc) {
          white-space: nowrap;
        }
        @include mq(no-tablet) {
          height: 100%;
        }
        @include mq {
          @include lh(14,22);
          padding: 18px;
          width: 100%;
        }
        .date {
          color: #7C7C7C;
          font-family: $font-readex;
          font-size: 1.4rem;
          font-weight: 500;
          @include ls(25);
          margin-right: 20px;
          @include mq(pc) {
            display: inline-block;
            vertical-align: middle;
          }
          @include mq {
            display: block;
            margin-bottom: 3px;
          }
        }
      }
    }
    .tour-today {
      .def {
        display: flex;
        @include mq(pc) {
          height: 51px;
        }
        @include mq {
          flex-wrap: wrap;
        }
        > dt {
          font-size: 1.5rem;
          font-weight: bold;
          @include ls(25);
          background: $white;
          width: 212px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mq(pc) {
            @include mq(spc) {
              font-size: 1.3rem;
              width: 182px;
            }
          }
          @include mq {
            font-size: 1.6rem;
            text-align: center;
            width: 100%;
            height: 45px;
          }
        }
        > dd {
          color: $white;
          width: calc(50% - 106px);
          padding: 0 27px;
          @include mq(pc) {
            @include mq(spc) {
              width: calc(50% - 86px);
              padding: 0 15px;
            }
          }
          @include mq {
            width: 50%;
            height: 73px;
            padding: 8px;
          }
          &:nth-of-type(1) {
            background: #0071AC;
          }
          &:nth-of-type(2) {
            background: #0A4C89;
          }
        }
      }
      .indef {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include mq(pc) {
          @include mq(spc) {
            font-size: 1.4vw;
          }
        }
        @include mq {
          flex-wrap: wrap;
        }
        > dt {
          font-weight: bold;
          @include mq(pc) {
            margin-right: 14px;
          }
          @include mq {
            font-size: 1.3rem;
            text-align: center;
            width: 100%;
          }
          strong {
            font-family: $font-readex;
            font-size: 2rem;
            font-weight: 500;
            @include ls(80);
            margin-left: 7px;
            @include mq(pc) {
              @include mq(spc) {
                font-size: 2vw;
              }
            }
            @include mq {
              font-size: 1.8rem;
            }
          }
        }
        > dd {
          font-weight: bold;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @include mq {
            font-size: 1.2rem;
            width: 100%;
            justify-content: center;
          }
        }
        .icon {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: $white;
          padding: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mq {
            margin-right: 4px;
            width: 20px;
            height: 20px;
            padding: 5px;
          }
          &:after {
            content: '';
            width: 16px;
            height: 16px;
            display: block;
            @include mq {
              width: 10px;
              height: 10px;
            }
          }
          &._01 {
            &:after {
              background: url(../../img/common/icon_circle.png) no-repeat;
              background-size: contain;
            }
          }
          &._02 {
            &:after {
              background: url(../../img/common/icon_triangle.png) no-repeat;
              background-size: contain;
            }
          }
          &._03 {
            &:after {
              background: url(../../img/common/icon_none.png) no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
  }
  .sec-info {
    background: #121212;
    border-top: 1px solid #D4D4D4;
    padding: 60px 0 120px;
    @include mq {
      padding: 70px 0;
    }
    .top-bnr-slide {
      width: calc(100% + 120px);
      margin-left: -60px;
      margin-bottom: 100px;
      cursor: pointer;
      opacity: 0;
      transition: .5s ease opacity;
      @include mq {
        width: calc(100% + 100px);
        margin: 0 -50px 50px;
      }
      .slick-slide {
        margin: 0 10px;
        width: 340px;
        @include mq {
          width: 300px;
          margin: 0 20px;
        }
        a {
          display: block;
          &:hover {
            opacity: .7;
          }
        }
      }
      .slide-arrow {
        @include mq(pc) {
          display: none;
        }
        @include mq {
          background: $white;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 40%;
          z-index: 30;
          img {
            width: 12px;
          }
        }
        &._prev {
          left: 57px;
          transform: rotate(180deg);
        }
        &._next {
          right: 57px;
        }
      }
      .slide-dots {
        margin-top: 15px;
        text-align: center;
        button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
        li {
          display: inline-block;
          margin: 0 5px;
          button {
            font-size: 0;
            position: relative;
            text-indent: -9999px;
            &:before{
              content: '';
              width: 8px;
              height: 8px;
              border: 1px solid $white;
              border-radius: 50%;
              display: block;
            }
          }
          &.slick-active{
            button:before {
              background: $main-color02;
              border-color: $main-color02;
            }
          }
        }
      }
    }
    .top-movie {
      @include mq {
        margin: 0 -20px 0;
      }
      video {
        width: 100%;
        height: auto;
      }
    }
  }
  .sec-news {
    padding: 80px 0 90px;
    @include mq {
      padding: 20px 0 50px;
    }
    .box-wrap {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }
      @include mq {
        margin-top: 30px;
      }
    }
    .box {
      width: 50%;
      padding-bottom: 90px;
      position: relative;
      @include mq {
        padding: 30px 0;
        width: 100%;
      }
      &:first-child {
        @include mq(pc) {
          padding-right: 40px;
        }
      }
      &:nth-child(2) {
        @include mq(pc) {
          padding-left: 40px;
          .btn {
            left: calc(50% + 20px);
          }
        }
      }
    }
    .info-list {
      margin-top: 24px;
      @include mq {
        margin-top: 5px;
        padding: 0 15px;
      }
      li {
        border-bottom: 1px solid #DBDBDB;
        padding: 16px 0 14px;
        @include mq {
          padding: 14px 0 13px;
        }
        > span {
          font-family: $font-readex;
          color: #7C7C7C;
          font-size: 1.4rem;
          display: block;
          @include mq {
            font-size: 1.2rem;
          }
        }
        a {
          @include link_color($text-color);
          font-size: 1.7rem;
          @include ls(25);
          margin-top: 4px;
          display: block;
          transition: .3s ease color;
          &:hover {
            text-decoration: none;
            color: $main-color02;
          }
          @include mq {
            font-size: 1.6rem;
          }
        }
      }
    }
    .btn03 {
      @include mq(pc) {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
      @include mq {
        padding: 0 15px;
        margin-top: 40px;
      }
    }
    .note-tour {
      color: $main-color02;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      border: 3px solid $main-color02;
      padding: 30px;
      margin-top: 100px;
      @include mq {
        font-size: 1.6rem;
        padding: 15px;
        margin-top: 20px;
      }
    }
  }
  .tour-mainv {
    max-height: 30vw;
  }
  .sec-tour {
    padding-top: 105px;
    @include mq {
      padding-top: 60px;
    }
    &.active {
      .overlay {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .lead {
      font-size: 1.8rem;
      @include ls(25);
      @include lh(18,42);
      text-align: center;
      margin-top: 40px;
      @include mq {
        font-size: 1.4rem;
        @include lh(14,26);
        margin-top: 30px;
      }
    }
    .tab-list {
      margin-top: 64px;
      display: flex;
      justify-content: space-between;
      li {
        font-size: 2.1rem;
        font-weight: bold;
        @include ls(80);
        text-align: center;
        width: 50%;
        padding-bottom: 13px;
        border-bottom: 6px solid #b5b5b5;
        opacity: .5;
        cursor: pointer;
        transition: .3s ease;
        &:hover {
          @include mq(pc) {
            color: #006FA2;
            border-bottom-color: currentColor;
            opacity: 1;
          }
        }
        @include mq {
          color: #9E9E9E;
          font-size: 1.2rem;
          width: 50%;
          height: 80px;
          border: 2px solid #CACACA;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        &:nth-child(1) {
          @include mq {
            border-radius: 20px 0 0 20px;
            border-right: 0;
          }
        }
        &:nth-child(2) {
          @include mq {
            border-radius: 0 20px 20px 0;
            border-left: 0;
          }
        }
        &.active {
          color: #006FA2;
          border-bottom-color: currentColor;
          opacity: 1;
          @include mq {
            color: $white;
            border-color: #006EB4;
            background: #006EB4;
          }
        }
        .new {
          color: $white;
          font-family: Century Gothic, sans-serif;
          font-size: 1.2rem;
          font-weight: bold;
          @include ls(100);
          background: #CC2A0A;
          width: 38px;
          height: 20px;
          padding-top: 1px;
          margin-right: 9px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        em {
          font-size: 2.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mq {
            font-size: 1.6rem;
          }
        }
      }
    }
    .tour-conts {
      background: #F9F9F9;
      padding: 50px 60px 320px 60px;
      margin: 50px -60px 0;
      //display: none;
      @include mq {
        padding: 30px 15px 90px;
        margin: 0 -15px;
      }
      &.active {
        display: block;
      }
      .timetable-box {
        @include mq {
          display: none;
        }
      }
    }
    h3 {
      font-family: $font-zen;
      font-size: 2.8rem;
      @include ls(80);
      @include lh(28,54);
      text-align: center;
      margin-bottom: 35px;
      @include mq {
        font-size: 2.2rem;
        margin-bottom: 25px;
      }
    }
    .note {
      text-align: right;
      margin-top: 20px;
    }
    .timetable-btn {
      font-size: 1.6rem;
      font-weight: bold;
      @include ls(25);
      background: $white;
      border: 2px solid #CACACA;
      border-radius: 10px;
      background: $white;
      width: 275px;
      height: 60px;
      margin: 0 auto 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:before,
      &:after {
        content: '';
        width: 11px;
        height: 2px;
        background: $main-color;
        display: block;
        position: absolute;
        top: calc(50% - 2px);
        right: 15px;
      }
      &:after {
        transform: rotate(90deg);
      }
    }
    .timetable-box {
      @include mq {
        width: calc(100% - 60px);
        height: 75vh;
        border-radius: 20px;
        background: #F4F5F7;
        padding: 195px 20px 20px;
        position: fixed;
        top: 15vh;
        left: 30px;
        z-index: 999;
        overflow-y: scroll;
      }
      h3 {
        margin-top: 23px;
      }
      .place-def {
        @include mq(pc) {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        dt {
          background: $white;
          font-size: 1.6rem;
          font-weight: bold;
          width: 90px;
          height: 34px;
          border-radius: 17px;
          margin-right: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mq {
            color: #707070;
            font-size: 1.3rem;
            border-radius: 5px;
            width: 100%;
            height: 25px;
          }
        }
        dd {
          font-size: 1.8rem;
          font-weight: bold;
          @include mq {
            font-size: 1.5rem;
            text-align: center;
            margin-top: 9px;
          }
          img {
            width: 18px;
            height: auto;
            margin-left: 8px;
            display: inline-block;
            @include mq {
              width: 12px;
            }
          }
        }
      }
      .timetable-wrap {
        margin-top: 34px;
        @include mq(pc) {
          display: flex;
          justify-content: space-between;
        }
        @include mq {
          margin-top: 17px;
        }
        .box {
          width: calc(50% - 10px);
          overflow: hidden;
          @include mq(pc) {
            background: $white;
            border-radius: 10px;
            box-shadow: 0 10px 14px #E7E7E7;
          }
          @include mq {
            width: 100%;
            + .box {
              margin-top: 17px;
            }
          }
        }
        h4 {
          font-size: 2rem;
          font-weight: bold;
          @include ls(25);
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mq {
            color: #707070;
            background: $white;
            font-size: 1.3rem;
            font-weight: bold;
            width: 100%;
            height: 25px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .def-wrap {
          @include mq {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
          }
        }
        .def {
          @include mq {
            width: calc(50% - 5px);
          }
          > dt {
            font-family: $font-zen;
            color: $white;
            font-size: 2rem;
            @include ls(50);
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mq(pc) {
              @include mq(spc) {
                font-size: 2.1vw;
              }
            }
            @include mq {
              font-size: 1.4rem;
              height: 35px;
              border-radius: 10px 10px 0 0;
            }
            &.plan01 {
              background: #09704A;
            }
            &.plan02.plan03 {
              @include mq(pc) {
                > div {
                  width: 50%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &:nth-of-type(1) {
                    background: #124394;
                  }
                  &:nth-of-type(2) {
                    background: #B3A000;
                  }
                }
              }
              @include mq {
                background:linear-gradient(90deg,#124394 0%,#124394 50%,#B3A000 50%,#B3A000 100%);
              }
            }
            &.plan-new {
              background: $main-color02;
            }
          }
        }
        .indef {
          font-size: 1.8rem;
          @include mq(pc) {
            padding: 5px 24px 15px;
          }
          @include mq {
            background: $white;
          }
          > div {
            border-bottom: 1px solid #EAECEE;
            padding: 10px 0;
            @include mq(pc) {
              display: flex;
              justify-content: flex-start;
            }
            @include mq {
              padding: 10px 9px;
            }
            &:last-child {
              border-bottom: none;
            }
            dt {
              font-family: $font-readex;
              color: #838383;
              @include ls(50);
              @include mq(pc) {
                width: 130px;
                margin-right: 10px;
              }
              @include mq {
                color: #000;
                font-size: 1.4rem;
              }
              span {
                font-family: $font-sans_serif;
                font-size: 1.6rem;
                font-weight: bold;
                @include mq(pc) {
                  margin-left: 6px;
                }
                @include mq {
                  font-size: 1.3rem;
                }
              }
            }
            dd {
              font-weight: bold;
              @include ls(25);
              @include mq {
                font-size: 1.6rem;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .plan-box {
      @include mq(pc) {
        margin-top: 80px;
      }
      h3 {
        @include mq {
          display: none;
        }
      }
      .plan-list {
        @include plan-list;
      }
    }
    .floor-map{
      color: #006EB4;
      border: 2px solid #006FA2;
      width: 300px;
      margin: 20 auto 0;
      text-align: center;
      border-radius: 5px;
      border-spacing: border-box;
      background: #fff;
      @include mq {
        width: 70%;
      }

      a{
        height: auto;
        width: 100%;
        height: 60px;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-top: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          background: $key-color;
          color: #fff;
        }
        &:before{
          content: '';
          display: block;
          margin: -1px 5px 0 0;
          width: 15px;
          height: 16px;
          background: url(/img/common/icon_pdf_aqua.png) no-repeat;
          background-size: contain;
       }

      }
    }
    .fee-box {
      margin-top: 70px;
      .table-wrap {
        box-shadow: 0 10px 14px #E7E7E7;
        @include mq {
          overflow-x: scroll;
        }
      }
      table {
        width: 100%;
        background: $white;
        @include mq {
          width: 600px;
        }
        th, td {
          text-align: center;
          border-right: 1px solid #CBCFD7;
          border-bottom: 1px solid #CBCFD7;
          &:last-child {
            border-right: 0;
          }
        }
        tr:last-child {
          th, td {
            border-bottom: 0;
          }
        }
        thead {
          th, td {
            width: 25%;
            border-bottom: 1px solid #CBCFD7 !important;
          }
          th {
            color: $white;
            @include ls(50);
            div {
              font-size: 1.25vw;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
              @media screen and (min-width: 1650px) {
                font-size: 2rem;
              }
              @include mq {
                font-size: 1.5rem;
              }
            }
            &.plan01 {
              background: #09704A;
            }
            &.plan02 {
              background: #124394;
            }
            &.plan03 {
              background: #B3A000;
            }
            &.plan04 {
              background: #6A6A6A;
            }
            &.plan05 {
              background: #0C3060;
            }
            &.plan06 {
              background: #B03009;
            }
          }
        }
        tbody {
          th, td {
            font-weight: bold;
            padding: 15px;
          }
          th {
            @include ls(25);
          }
          tr.fee {
            td {
              font-family: $font-readex;
              font-size: 1.8rem;
              font-weight: normal;
              span {
                font-family: $font-sans_serif;
                font-weight: bold;
                margin-right: 3px;
              }
            }
          }
        }
      }
      .btn03 {
        margin-top: 26px;
      }
    }
  }
  .sec-point {
    background: #121212;
    padding-bottom: 160px;
    @include mq {
      padding-bottom: 80px;
    }
    .point-mainv {
      margin: -12vw 0 0 -60px;
      max-height: 27vw;
    }
    .ttl-box {
      position: relative;
      margin-top: 115px;
      @include mq {
        margin-top: 50px;
      }
      &._01 {
        p {
          right: -70px;
        }
      }
      &._02 {
        h2 {
          justify-content: flex-end;
        }
        p {
          left: -98px;
        }
      }
      h2 {
        color: $white;
        font-size: 3.6rem;
        @include ls(80);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @include mq {
          font-size: 2rem;
        }
        span {
          color: #0078AF;
          font-family: $font-zen;
          font-size: 2rem;
          font-weight: bold;
          @include ls(80);
          border-bottom: 3px solid #0078AF;
          padding-bottom: 2px;
          margin-right: 8px;
          @include mq {
            font-size: 1.4rem;
          }
        }
      }
      p {
        position: absolute;
        top: -100%;
        @include mq {
          width: 90vw;
        }
      }
    }
    .list {
      margin-top: 83px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include mq {
        margin-top: 40px;
      }
      li {
        width: calc(50% - 20px);
        border: 1px solid #444;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @include mq(spc) {
          width: 100%;
        }
        &:nth-child(n+3) {
          margin-top: 39px;
        }
        + li {
          @include mq(spc) {
            margin-top: 20px !important;
          }
        }
        .img-wrap {
          width: 45%;
          height: 100%;
          position: absolute;
          max-width: 300px;
          top: 0;
          left: 0;
          @include mq {
            width: 30%;
          }
          img {
            @include object-fit(cover);
          }

        }
        .text-wrap {
          color: $white;
          padding: 50px 25px 50px calc(45% + 25px);
          width: 100%;
          @media screen and (max-width: 1400px) and (min-width: 1281px) {
            padding: 50px 25px 50px calc(40% + 30px);
          }
          @include mq {
            padding: 20px 20px 20px calc(30% + 20px);
          }
        }
        .num {
          color: $key-color02;
          font-family: $font-mont;
          border-bottom: 3px solid $key-color02;
          padding-bottom: 2px;
        }
        .st {
          font-family: $font-zen;
          font-size: 2rem;
          margin-top: 10px;
          @include mq {
            font-size: 1.8rem;
          }
          span {
            color: #006FA2;
            font-family: $font-readex;
            font-size: 3.4rem;
            @include ls(50);
            margin-right: 6px;
            @include mq {
              font-size: 2.4rem;
            }
            > em {
              font-size: 5.6rem;
              line-height: 1em;
              margin-right: 8px;
              @include mq {
                font-size: 3.6rem;
                margin-right: 3px;
              }
            }
          }
          > em {
            color: #006FA2;
            margin-right: 3px;
          }
        }
        .text {
          font-family: $font-zen;
          font-size: 1.6rem;
          @include ls(80);
        }
      }
    }
    .btn03 {
      margin-top: 11px;
      + .btn03 {
        margin-top: 10px;
      }
      a {
        font-size: 1.5rem;
        min-width: auto;
        @include mq {
          font-size: 1.4rem;
          min-width: 100%;
          height: 45px;
        }
      }
    }
  }
  .sec-topics {
    padding: 106px 0;
    @include mq {
      padding: 60px 0;
    }
    .topic-slide {
      margin-top: 57px;
      @include mq(pc) {
        width: calc(100% - 20px);
      }
      .item {
        font-size: 1.6rem;
        margin: 0 13px;
        @include mq {
          font-size: 1.2rem;
          margin: 0 5px;
        }
        > p {
          @include lh(16,28);
        }
      }
      .img-wrap {
        margin-bottom: 23px;
        width: 100%;
        height: 240px;
        max-width: 400px;
        @include mq(tablet) {
          height: 21vw;
          margin-bottom: 7px;
        }
        img {
          @include object-fit(contain);
        }
      }
      .date {
        color: #7C7C7C;
        font-family: $font-readex;
        font-size: 1.4rem;
        @include ls(25);
        margin-bottom: 7px;
        display: block;
        @include mq {
          font-size: 1.1rem;
          margin-bottom: 4px;
        }
      }
      .slide-arrow {
        width: 33px;
        height: auto;
        position: absolute;
        top: 33%;
        z-index: 999;
        cursor: pointer;
        transition: .3s ease all;
        @include mq {
          width: 25px;
        }
        &._prev {
          left: -30px;
          transform: rotate(180deg);
          &:hover {
            @include mq(pc) {
              left: -34px;
              @include mq(tablet) {
                left: -13px;
              }
            }
          }
          @include mq(tablet) {
            left: -10px;
          }
        }
        &._next {
          right: -30px;
          &:hover {
            @include mq(pc) {
              right: -34px;
              @include mq(tablet) {
                right: -13px;
              }
            }
          }
          @include mq(tablet) {
            right: -10px;
          }
        }
      }
      .slide-dots {
        margin-top: 20px;
        text-align: center;
        button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
        li {
          display: inline-block;
          margin: 0 5px;
          button {
            font-size: 0;
            position: relative;
            text-indent: -9999px;
            &:before{
              content: '';
              width: 8px;
              height: 8px;
              background: #C5C5C5;
              border-radius: 50%;
              display: block;
            }
          }
          &.slick-active{
            button:before {
              background: $main-color02;
              transform: scale(1.5);
            }
          }
        }
      }
    }
    .btn03 {
      margin-top: 38px;
      @include mq {
        margin-top: 25px;
      }
      a {
        width: 367px;
        margin: 0 auto;
        @include mq {
          width: 100%;
        }
      }
    }
  }
}
