.header-rsv {
  $this: &;
  color: $white;
  //position: fixed;
  //top: 0;
  //left: 0;
  margin: 0 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
 // background: $main-color;
  background: #016fa2;
  @include transition;
  z-index: 999;
  @include mq(spc) {
    min-width: auto;
    height: $sp-header-height-rsv;
    position: fixed;
    top: 0;
    left: 0;
  }
  &.fixed {
    background: $white;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: flex;
      color: #fff;
      img {
        height: auto;
        &.gc {
          width:62px;
          height:51px ;
          @include mq(mpc) {
           // width: 57.6px;
           width: auto;
            height:40px;
          }
          @include mq(spc) {
           // width: 50.4px
           width: auto;
            height: 35px;
          }

        }
        &.gdm {
          width: 50px;
          margin-left: 5px;
          height: 55px;
          @include mq(mpc) {
            width: 40px;
            margin-left: 8px;
            height: 44px;
          }
          @include mq(spc) {
            width: 35px;
            margin-left: 5px;
            height: 39px;
          }
        }
      }
    }
    .text {
      margin-left: 18px;
      @include mq(mpc) {
        margin-left: 9px;
      }
      @include mq(spc) {
        margin-left: 12px;
      }
      > span {
        font-size: 1.3rem;
        font-weight: normal;
        @include lh(13,18.2);
        display: block;
        @include mq(mpc) {
          font-size: 1vw;
        }
        @include mq(spc) {
          font-size: 1.0rem;
        }
      }
      strong {
        font-size: 1.9rem;
        @include ls(75);
        display: block;
        margin-top: 2px;
        @include mq(mpc) {
          font-size: 1.5vw;
        }
        @include mq(spc) {
          font-size: 1.4rem;
          margin-top: 2px;
        }
      }
    }
  }
  .h-inner {
    padding: 6px 10px 8px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(mpc) {
      padding-left: 15px;
    }
    @include mq(spc) {
      padding: 8px 0 8px 12px;
    }
  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &._right {
      @media screen and (min-width: 980px) {
        display: flex !important;
      }
      @include mq(spc) {
        display: none;
        width: 100%;
        padding: 20px 18px;
        background: $main-color;
        position: fixed;
        top: $sp-header-height-rsv;
        left: 0;
      }
    }
  }
  .rsv-btn {
    &._pc {
      @include mq(spc) {
        display: none;
      }
    }
    &._sp {
      @media screen and (min-width: 980px) {
        display: none;
      }
    }
    @include mq(pc) {
      margin-left: 24px;
      @include mq(mpc) {
        margin-left: 16px;
      }
    }
    @include mq(spc) {
      max-width: 500px;
      margin: 20px auto 0 !important;
    }
    a {
      color: $white;
      font-size: 1.4rem;
      font-weight: bold;
      @include ls(25);
      background: $key-color;
      border-radius: 30px;
      width: 260px;
      height: 57px;
      padding: 15px 30px 15px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @include mq(mpc) {
        font-size: 1.3rem;
        text-align: center;
        width: 170px;
        line-height: 1.45;
      }
      @include mq(spc) {
        width: 100%;
      }
      &:hover {
        background: #561e17;
      }
      &:after {
        content: '';
        width: 6px;
        height: 10px;
        background: url(../../img/common/icon_arrow01_white.svg) no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        @include mq(spc) {
          right: 15px;
        }
      }
    }
  }
  .btn-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(spc) {
      flex-direction: column;
      position: relative;
      //max-width: 500px;
      margin: 0 auto;
    }
  }
  .fee-btn{
    @include mq(spc) {
      width: 100%;
      margin: 0 0 10px;
      order: 3;
    }
    > * {
      color: #fff;
      cursor: pointer;
      font-size: 1.3rem;
      font-weight: bold;
      @include ls(50);
      //background: $white;
      width: 100px;
      border: 1px solid #fff;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 10px;
      &:hover{
        background: #fff;
        color: $key-color;
      }
      @include mq(mpc) {
        width: 100px;
      }
      @include mq(spc) {
        width: 100%;
      }
      /*&:before {
        content: '';
        width: 15px;
        height: 17px;
        background: url(../../img/common/icon_money.svg) no-repeat;
        background-size: contain;
        position: absolute;
        top: 52%;
        left: 12px;
        transform: translateY(-50%);
        @include mq(mpc) {
          left: 10px;
        }
        @include mq(spc) {
          left: 15px;
        }
      }
      &:after {
        content: '';
        width: 6px;
        height: 10px;
        background: url(../../img/common/icon_arrow01_black.svg) no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }*/
    }

  }
  .mypage-btn {
    margin: 0 0 0 10px;
    @include mq {
      margin: 0;
    }
    @include mq(spc) {
      width: 100%;
      margin-top: 10px;
      order: 2;
    }
    &._login {
      span{
        color: $main-color;
        background: #e4f6ff;
        color: $white;
          background: $key-color;
          &:before {
            background: url(../../img/common/icon_mypage_white.svg) no-repeat;
            background-size: contain;
          }
          &:after {
            background: url(../../img/common/icon_arrow01_white.svg) no-repeat;
            background-size: contain;
          }
      }
      > * {
        transition: .3s ease;
        cursor: pointer;
        &:after {
          transform: translateY(-50%) rotate(90deg);
        }
        &:hover {
          @include mq(pc) {
            color: #222;
            background: #fff;
            &:before {
              background: url(../../img/common/icon_mypage.svg) no-repeat;
              background-size: contain;
            }
            &:after {
              background: url(../../img/common/icon_arrow01.svg) no-repeat;
              background-size: contain;
            }
          }
        }
        &.active {
          color: #222;
          background: #fff;
          &:before {
            background: url(../../img/common/icon_mypage.svg) no-repeat;
            background-size: contain;
          }
          &:after {
            background: url(../../img/common/icon_arrow01.svg) no-repeat;
            background-size: contain;
          }
        }
      }
    }
    > * {
      color: $text-color;
      font-size: 1.4rem;
      font-weight: bold;
      @include ls(50);
      background: $white;
      width: 150px;
      height: 40px;
      display: flex;
      justify-content: left;
      align-items: center;
      position: relative;
      padding: 0 0 0 38px;
      @include mq(mpc) {
        width: 150px;

      }
      @include mq(spc) {
        width: 100%;
        padding: 0 0 0 48px;
      }
      &:before {
        content: '';
        width: 247px;
        height: 24px;
        background: url(../../img/common/icon_user.svg) no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        @include mq(mpc) {
          left: 10px;
        }
        @include mq(spc) {
          left: 10px;
        }
      }
      &:after {
        content: '';
        width: 6px;
        height: 10px;
        background: url(../../img/common/icon_arrow01_black.svg) no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);

      }
      > span {
        @include mq(mpc) {
          display: none;
        }
      }
    }
  }
  .mypage-info-list {
    width: 220px;
    padding: 10px;
    position: fixed;
    top: 55px;
    opacity: 0;
    transition: .3s ease opacity;
    pointer-events: none;
    z-index: 10;
    @include mq(spc) {
      width: 100%;
      padding: 0;
      position: absolute;
      top: 90px;
    }
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
    ul {
      padding: 10px;
      background: $key-color;
      position: relative;
      @include mq(pc) {
        border-radius: 0;
      }
      @include mq(spc) {
        padding: 0 10px 10px;
      }
      &:before {
        @include mq(pc) {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 7px 12px 7px;
          border-color: transparent transparent $key-color transparent;
          position: absolute;
          top: -12px;
          left: 20px;
        }
      }
    }
    li {
      + li {
        margin-top: 2px;
      }
      a {
        color: $key-color;
        font-size: 1.4rem;
        font-weight: bold;
        width: 100%;
        height: 54px;
        background: $white;
        padding: 0 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        @include mq(spc) {
          height: 47px;
        }
        &:hover {
          color: $white;
          background: $key-color-hover;
          &:after {
            background: url(../../img/common/icon_arrow01_white.svg) no-repeat;
            background-size: contain;
          }
        }
        &:after {
          content: '';
          width: 6px;
          height: 10px;
          background: url(../../img/common/icon_arrow01.svg) no-repeat;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
        }
      }
    }
  }
  .lang-select-wrap {
    width: 150px;
    height: 40px;
    position: relative;
    @include mq(pc) {
      margin-left: 10px;
      @include mq(mpc) {
        width: 150px;
        margin-left: 10px;
      }
    }
    @include mq(spc) {
      width: 100% !important;
      margin-left: 0 !important;
      order: 1;
    }
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(../../img/common/icon_lang.svg) no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      z-index: 10;
    }
    &:after {
      content: '';
      width: 6px;
      height: 10px;
      background: url(../../img/common/icon_arrow01_black.svg) no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%) rotate(90deg);
      z-index: 10;


    }
  }
  .lang-select {
    width: 100%;
    height: 100%;
    background-color: transparent !important;
    padding: 0 !important;
    position: relative !important;
    top: 0 !important;
    z-index: 0 !important;
    @include mq(spc) {
      right: 0 !important;
    }
    select {
      font-size: 1.4rem;
      font-weight: bold;
      @include ls(50);
      width: 100%;
      height: 100%;
      padding: 1px 0 0 48px;
      cursor: pointer;
      text-indent: 0.01px;
      text-overflow: ellipsis;
      border: none;
      outline: none;
      background: $white;
      background-image: none;
      box-shadow: none;
      -webkit-appearance: none;
      appearance: none;
      option {
        color: $text-color !important;
        &.msta_selected {
          color: #999 !important;
        }
      }
    }
  }
  .tel-def {
    @include mq(pc) {
      margin-left: 19px;
      border-left: 1px solid $white;
      @include mq(mpc) {
        margin-left: 11px;
      }
    }
    @include mq(spc) {
      margin-top: 24px;
      border-left: 0 !important;
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include mq(pc) {
        height: 52px;
        padding: 0 29px;
        @include mq(mpc) {
          padding: 0 16px;
          white-space: nowrap;
        }
      }
      + div {
        @include mq(pc) {
          border-top: 1px solid $white;
          @include mq(spc) {
            border-top: 0;
          }
        }
        @include mq(spc) {
          margin-top: 15px;
        }
      }
    }
    dt {
      font-size: 1.3rem;
      @include mq(spc) {
        font-weight: bold;
      }
    }
    dd {
      margin-top: 3px;
      @include mq(spc) {
        margin-top: 5px;
      }
      a {
        @include link_color($white);
        font-family: $font-roboto;
        font-size: 1.6rem;
        font-weight: 500;
        @include ls(50);
        cursor: default;
        @include mq(spc) {
          font-size: 2.4rem;
        }
        &:before {
          content: '';
          width: 18px;
          height: 18px;
          margin-right: 10px;
          background: url(../../img/common/icon_tel.svg) no-repeat;
          background-size: contain;
          display: inline-block;
          vertical-align: -3px;
          @include mq(spc) {
            margin-right: 6px;
          }
        }
      }
    }
  }
}


.header.fixed .h-nav {
  a {
    @include link_color($text-color);
    &:hover {
      @include mq(pc) {
        @include link_color($main-color);
      }
    }
  }
}
