body {
  overflow-x: hidden;
}

.slick-slide {
  height: auto;
}

.wrapper {
  overflow: hidden;
  position: relative;

  &._col {

    //    @include mq(pc) {
    //      padding-top: 65px;
    //    }
    //    @include mq(tablet) {
    //      padding-top: 87px;
    //    }
    .page {
      &:not(._pb) {
        padding-bottom: 0;
      }

      @include mq(pc) {
        font-size: 1.6rem;
        padding-left: 280px;
        padding-top: 80px;
      }

      @include mq(tablet) {
        padding: 70px 0 0 0;

        @include mq {
          padding-top: 110px;
        }
      }

      @include mq(mpc) {
        font-size: 1.4rem;
      }
    }
  }
}

#p-psw .wrapper,
#p-mpg.print-receipt .wrapper,
#p-mpg .wrapper,
#p-rsv-ls .wrapper,
#p-rsv .wrapper {
  padding-top: 0 !important;

  @include mq(spc) {
    padding-top: 65px !important;
  }
}

.col-cmn-inner {
  padding: 0 60px;

  @include mq {
    padding: 0 15px;
  }
}

.cmn-mainv {
  color: $white;
  @include ls(75);

  .mainv {
    width: 100%;
    height: 250px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @include mq {
      height: 200px;
    }
  }

  .mainv-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .2);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      @include object-fit(cover);
    }
  }

  .sttl {
    font-size: 1.8rem;

    @include mq {
      font-size: 1.6rem;
    }
  }

  h1 {
    font-size: 3rem;
    text-align: center;
    margin-top: 10px;
    width: 100%;

    @include mq {
      font-size: 2.3rem;
    }
  }

  h2 {
    font-size: 3rem;
    margin-top: 10px;

    @include mq {
      font-size: 2.2rem;
    }
  }
}

.breadcrumbs {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 50px;

    @include mq {
      padding: 15px;
    }
  }

  li {
    font-size: 1rem;
    @include ls(75);

    &:not(:last-child) {
      &:after {
        content: '\f054';
        font-family: $font-awesome;
        font-size: 1rem;
        margin: 0 9px;
      }
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.cmn-tab-list {
  margin: 70px 0 50px;

  @include mq {
    margin: 10px 0 25px;
  }

  &._p-joriku-old {
    li {
      &:nth-child(2) {
        &.active {
          a {
            color: $normal-old;
            border-bottom-color: $normal-old;
          }
        }

        a {
          &:hover {
            color: $normal-old;
            border-bottom-color: $normal-old;
          }
        }
      }

      &:nth-child(3) {
        &.active {
          a {
            color: $yusen-old;
            border-bottom-color: $yusen-old;

            span {
              background: $yusen-old;
            }
          }
        }

        a {
          @include mq {
            flex-direction: column;
          }

          &:hover {
            color: $yusen-old;
            border-bottom-color: $yusen-old;

            span {
              background: $yusen-old;
            }
          }
        }

        span {
          color: $white;
          font-size: 1.2rem;
          font-weight: bold;
          background: #fa555c;
          width: 90px;
          height: 26px;
          padding: 6px 10px;
          margin-right: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border-radius: 13px;
          position: relative;
          transition: .3s ease;

          @include mq {
            font-size: 1rem;
            width: 59px;
            height: 20px;
            border-radius: 10px;
            padding: 5px;
            margin: -2em 0 2px 0;
            justify-content: center;
          }

          /*&:before {
            content: '';
            width: 38px;
            height: 31px;
            background: url(../../img/common/img_ganshokun03.png) no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
            @include mq {
              display: none;
            }
          }*/
        }
      }

      &:nth-child(4) {
        &.active {
          a {
            color: $premium-old;
            border-bottom-color: $premium-old;
          }
        }

        a {
          &:hover {
            color: $premium-old;
            border-bottom-color: $premium-old;
          }
        }
      }
    }
  }

  ul {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq {
      padding: 0 15px;
    }
  }

  li {
    width: 100%;
    position: relative;

    &:not(:last-child) {
      margin-right: 1px;

      &:after {
        content: '';
        width: 1px;
        height: 35px;
        background: #ccc;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }

    &.active {
      a {
        color: $main-color;
        pointer-events: none;
        cursor: default;
        border-bottom-color: currentColor;
      }
    }
  }

  a {
    font-weight: bold;
    text-align: center;
    @include ls(75);
    border-bottom: 1px solid #ccc;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease;

    @include mq(pc) {
      white-space: nowrap;
    }

    @include mq {
      font-size: 1.1rem;
      height: 50px;
    }

    &:hover {
      color: $main-color;
      border-bottom-color: currentColor;
    }
  }
}

.page {
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 0px 0 120px;

  @include mq {
    font-size: 1.3rem;
    padding: 0 0 40px;
  }

  // wrapperが._colの場合font-size1.6rem
  &._pb {
    padding-bottom: 80px;

    @include mq {
      padding-bottom: 0;
    }

    .sidebar {
      @include mq(tablet) {
        margin-top: 80px;

        @include mq(msp) {
          margin-top: 30px;
        }
      }
    }
  }
}

.sec-cmn-info {
  @include mq(pc) {
    padding: 50px 0;
  }

  .info-list {
    @include mq {
      margin-top: 5px;
    }

    li {
      border-bottom: 1px solid #ddd;
      padding: 16px 0 14px;

      @include mq {
        padding: 14px 0 13px;
      }

      >span {
        color: #666;
        font-size: 1.4rem;
        display: block;

        @include mq {
          font-size: 1.1rem;
        }
      }

      a {
        margin-top: 4px;
        display: block;
        text-decoration: underline;

        &:hover {
          color: $main-color02;
          text-decoration: none;
        }
      }
    }
  }

  .pager {
    margin-top: 50px;

    @include mq {
      margin-top: 20px;
    }

    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    li {
      margin: 0 5px;

      @include mq {
        margin-top: 10px;
      }

      &.prev,
      &.next {
        a {
          width: 80px;

          @include mq {
            width: 65px;
          }
        }
      }

      &.prev {
        a {
          padding-right: 6px;

          &:before {
            content: '';
            width: 12px;
            height: 10px;
            background: url(../../img/common/icon_arrow02.svg) no-repeat;
            background-size: contain;
            display: inline-block;
            margin-right: 10px;
            transform: rotate(180deg);
          }
        }
      }

      &.next {
        a {
          padding-left: 6px;

          &:after {
            content: '';
            width: 12px;
            height: 10px;
            background: url(../../img/common/icon_arrow02.svg) no-repeat;
            background-size: contain;
            display: inline-block;
            margin-left: 10px;
          }
        }
      }

      &.active {
        a {
          background: $main-color;
          pointer-events: none;
          cursor: default;
        }
      }
    }

    a {
      color: $white;
      font-size: 1.4rem;
      font-weight: bold;
      background: $black01;
      padding-top: 1px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .3s ease all;

      @include mq {
        font-size: 1.2rem;
        width: 30px;
        height: 30px;
      }

      &:hover {
        background: $key-color;
      }
    }
  }
}

.sec-cmn-info-dtl {
  @include mq(pc) {
    padding: 50px 0;
  }

  .text-box {
    line-height: 1.2;
    margin-top: 30px;
  }

  img {
    max-width: 900px;
    margin: 0 auto;
    display: block;

    +img {
      margin-top: 20px;
    }
  }

  .btn {
    @include mq {
      text-align: center;
      margin-top: 25px;
    }

    a {
      @include link_color($white);
      font-size: 1.2rem;
      @include ls(75);
      background: $black01;
      width: 160px;
      height: 35px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @include mq {
        width: 225px;
      }

      &:hover {
        background: $key-color;

        &:after {
          right: 17px;
        }
      }

      &:before {
        content: '';
        width: 12px;
        height: 11px;
        background: url(../../img/common/icon_arrow02.svg) no-repeat;
        background-size: contain;
        position: absolute;
        left: 20px;
        top: 49%;
        transform: translateY(-50%) rotate(180deg);
        transition: .3s ease all;
      }
    }
  }
}

.align-c {
  text-align: center;
}

.required {
  color: $white;
  font-size: 1.2rem;
  text-align: center;
  background: $red;
  width: 44px;
  padding: 1px;
  margin-right: 10px;
  border-radius: 10px;
  display: inline-block;
  vertical-align: 1px;
}

span.small {
  display: block;
  font-size: 1.2rem;
  color: $red;
}

.optional {
  color: $white;
  font-size: 1.2rem;
  text-align: center;
  background: #D6D6D6;
  width: 44px;
  padding: 1px;
  margin-right: 10px;
  border-radius: 10px;
  display: inline-block;
  vertical-align: 1px;
}

.inner {
  width: 100%;
  max-width: $pc-min-width;
  margin: 0 auto;

  @include mq {
    padding: 0 19px;
  }
}

// input
.form-def {
  max-width: 860px;
  width: calc(100% - 40px);
  margin: 0 auto;

  @include mq {
    width: 100%;
  }

  >div {
    +div {
      margin-top: 40px;
    }

    >dt {
      font-size: 1.6rem;
      font-weight: bold;

      .comment {
        color: $red;
        font-size: 1.4rem;
        font-weight: normal;
        margin-left: 10px;
      }
    }

    >dd {
      margin-top: 10px;
    }

    .agree-text {
      color: #6E2921;
      margin-top: 10px;
      padding-left: 10px;
      font-weight: bold;

      span {
        font-size: 2rem;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: '';
          width: 9px;
          height: 14px;
          background: url(../../img/common/icon_arrow01.svg) no-repeat;
          background-size: contain;
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
  }
}

.form-def02 {
  width: 860px;
  margin: 0 auto;

  *:disabled {
    background: #ccc;
  }

  @include mq {
    font-size: 1.4rem;
    width: 100%;
  }

  +.form-def02 {
    margin-top: 60px;
  }

  >dt {
    font-size: 1.6rem;
    font-weight: bold;
    background: #F6F6F6;
    padding: 8px 14px;
    margin-bottom: 20px;
  }

  >div {
    @include mq(pc) {
      display: flex;
      justify-content: flex-start;
    }

    &:last-of-type {
      >dt {
        @include mq(pc) {
          // border-bottom: 2px solid $key-color;
          border-bottom: 1px solid #D6D6D6;
          background: #F6F6F6;
        }
      }

      >dd {
        // border-bottom: 2px solid #E4E4E4;
        border-bottom: 1px solid #D6D6D6;
      }
    }

    >dt {
      @include mq(pc) {
        padding: 20px 5px 20px 10px;
      }
    }

    >dd {
      @include mq(pc) {
        padding: 20px 5px 20px 20px;
      }
    }

    >dt {
      font-size: 1.6rem;
      font-weight: bold;
      width: 258px;
      margin-right: 2px;

      @include mq(pc) {
        padding-top: 35px;
        // border-top: 2px solid $key-color;
        border-top: 1px solid #D6D6D6;
        background: #F6F6F6;

      }

      @include mq {
        width: 100%;
        //border-top: 2px solid #E4E4E4;
        border-top: 1px solid #d0d0d0;
        background: #F6F6F6;
        padding: 13px 0 13px 10px;
        margin: 0 0 10px;
      }

      .comment {
        font-size: 1.2rem;
      }
    }

    >dd {
      width: 600px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      @include mq(pc) {
        min-height: 94px;
        //  border-top: 2px solid #E4E4E4;
        border-top: 1px solid #D6D6D6;
      }

      @include mq {
        width: 100%;
        padding-bottom: 30px;
      }

      .attention-area {
        width: 100%;
      }
    }
  }

  .zip {
    >*:not(:first-child) {
      margin-top: 15px;
    }
  }

  .certificate {
    .select-wrap {
      margin-top: 8px;
    }
  }

  .note-box {
    margin-top: 15px;
  }

  .form-text-box {
    font-size: 1.3rem;
    line-height: 1.7;
    margin-top: 15px;
    padding: 15px 20px;
    border: 4px solid $gray01;

    a {
      @include link_color($key-color);
      text-decoration: underline;
      margin: 0 3px;
    }
  }
}

.error-text {
  color: $red;
  font-weight: bold;
  margin-top: 5px;

  &:before {
    content: '※';
  }

  &.holo-error {
    margin: -15px 0 20px 320px;

    @include mq {
      margin: -10px 0 15px 0;
    }
  }
}

.input-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  +.input-flex {
    margin-top: 15px;
  }
}

.input-text {
  margin: 0 10px;

  &:first-child {
    margin-left: 0;
  }

  @include mq {
    margin: 0 7px;
  }
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"] {
  font-size: 1.6rem;
  border: 1px solid #898989;
  padding: 12px 14px;
  width: 100%;

  &.w-s {
    width: 80px;
  }

  &.w-m {
    width: 320px;

    @include mq {
      width: 100%;
    }
  }
}

textarea {
  font-size: 1.6rem;
  border: 1px solid #898989;
  padding: 12px 14px;
  width: 100%;
  height: 97px;
  resize: vertical;
}

.select-text-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  .select-wrap {
    margin-right: 5px;
  }
}

.select-wrap {
  border: 1px solid #898989;
  border-radius: 3px;
  min-width: 66px;
  height: 51px;
  position: relative;

  
  &.w-m {
    width: 320px;
    
    @include mq {
      width: 100%;
    }
  }

  @include mq {
    min-width: 40px;
  }

  &:after {
    content: '';
    width: 6px;
    height: 10px;
    background: url(../../img/common/icon_arrow01_black.svg) no-repeat;
    background-size: contain;
    pointer-events: none;
    position: absolute;
    top: 40%;
    right: 10px;
    transform: rotate(90deg);

  }

  select{
    font-size: 1.6rem;
    width: 100%;
    height: 100%;
    padding: 15px 20px 15px 10px;
    cursor: pointer;
    text-indent: 0.01px;
    line-height: 1;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    @include mq {
      padding: 10px 15px 10px 5px;
      font-size: 1.4rem;
    }
  }
}

.check-wrap {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 0 5px 30px;
  margin-top: 10px;
  position: relative;
  width: auto;

  input[type="checkbox"] {
    display: none;

    &:checked {
      +label {
        &:before {
          background: $key-color;
          border: 1px solid $key-color;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  label {
    cursor: pointer;

    &:before {
      content: '';
      border: 1px solid #898989;
      border-radius: 3px;
      background: #fff;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      display: block;
      position: absolute;
      top: 46%;
      left: 5px;
    }

    &:after {
      content: '';
      border-right: 1px solid $white;
      border-bottom: 1px solid $white;
      width: 5px;
      height: 9px;
      left: 11px;
      margin-top: -7px;
      opacity: 0;
      display: block;
      position: absolute;
      top: 49%;
      transform: rotate(45deg);
    }
  }
}

.radio-wrap {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 0 5px 30px;
  position: relative;
  width: auto;

  +.radio-wrap {
    margin-left: 20px;

    @include mq {
      margin-left: 5px;
    }
  }

  +.cancel-reason {
    margin-left: 0;
    margin-top: 10px;

    @include mq {
      margin-left: 0;
    }
  }

  input[type="radio"] {
    display: none;

    &:checked {
      +label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  label {
    cursor: pointer;

    &:before {
      content: '';
      border: 1px solid #707070;
      border-radius: 50%;
      background: #fff;
      width: 18px;
      height: 18px;
      margin-top: -8px;
      display: block;
      position: absolute;
      top: 12px;
      left: 5px;
    }

    &:after {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $key-color;
      opacity: 0;
      display: block;
      position: absolute;
      top: 7px;
      left: 8px;
    }
  }
}

.scroll-box {
  font-size: 1.3rem;
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  padding: 20px;
  overflow-y: scroll;

  @include mq {
    padding: 15px;
  }

  h6 {
    color: #505050;
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
    background: $gray01;
    padding: 12px;
    margin-bottom: 10px;
  }

  p {
    +p {
      margin-top: 1em;
    }
  }
}

// ttl
.ttl01 {
  font-size: 2.8rem;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 20px;

  @include mq {
    font-size: 2.2rem;
  }

  img {
    width: 40px;
    margin: 0 auto;
    display: block;
  }
}

.ttl02 {
  font-size: 2.2rem;
  @include lh(22, 36);
  text-align: center;
  margin: 20px 0 20px;
  background: #505050;
  color: #fff;
  padding: 4px 10px;

  @include mq {
    font-size: 1.7rem;
    margin: 20px 0;
  }
}

.ttl03 {
  font-size: 2rem;
  @include lh(18, 25);
  text-align: center;
  margin-bottom: 17px;

  @include mq {
    font-size: 1.6rem;
  }
}

.ttl04 {
  font-size: 1.6rem;
  font-weight: bold;
  background: #F6F6F6;
  padding: 14px;
  margin-bottom: 20px;

  .comment {
    font-size: 1.3rem;
    font-weight: normal;
    margin-left: 10px;
    vertical-align: 1px;
  }
}

.ttl05 {
  font-size: 3rem;
  @include ls(75);
  text-align: center;
  margin-bottom: 35px;

  @include mq {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}

.ttl06 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;

  @include mq {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
}

.ttl07 {
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;

  @include mq {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
}

.ttl07 {
  text-align: center;
  font-weight: normal;
  @include ls(75);
  margin: 50px 0;

  @include mq {
    font-size: 1.5rem;
    margin: 25px 0;
  }

  em {
    background: linear-gradient(transparent 60%, #ade8f2 60%);
  }
}

.ttl08 {
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
  margin: 50px 0;
  @include ls(75);

  @include mq {
    font-size: 2rem;
    margin: 25px 0;
  }
}
.text01 {
  font-size: 1.3rem;
  font-weight: bold;
  @include lh(13, 21);
  text-align: center;
  margin-bottom: 20px;
}

.text02 {
  @include lh(14, 25);

  @include mq(pc) {
    text-align: center;
  }

  @include mq {
    font-size: 1.3rem;
  }
}

.text03 {
  @include lh(14, 25);

  @include mq(pc) {
    text-align: left;
  }

  @include mq {
    font-size: 1.3rem;
  }
}

.btn-box {
  margin-top: 30px;

  .btn {
    +.btn {
      margin-top: 20px;
    }
  }

  &._rsv {
    margin-top: 50px;

    @include mq(pc) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn {
      width: 40%;
      max-width: 425px;

      @include mq(pc) {
        margin: 0 10px;
        min-width: 300px;
      }

      @include mq {
        width: 100%;
        margin: 0 auto;
      }

      +.btn {
        @include mq {
          margin-top: 15px;
        }
      }

      a {
        color: $white;
        font-size: 2rem;
        font-weight: bold;
        @include ls(25);
        text-align: center;
        width: 100%;
        height: 80px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include mq {
          font-size: 1.8rem;
        }

        span {
          font-size: 1.4rem;
          font-weight: normal;
        }
      }

      &._rsv {
        a {
          background: $main-color02;
          position: relative;

          &:hover {
            background: #b7000e;
          }

          @include mq {
            padding-left: 10px;
          }

          &:before {
            content: '';
            width: 30px;
            height: 30px;
            background: url(../../img/common/icon_calendar02.svg) no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 44px;
            transform: translateY(-50%);

            @include mq(spc) {
              left: 24px;
            }
          }
        }
      }

      &._rsvd {
        a {
          background: #8c8c8c;

          @include mq {
            padding: 0 30px;
          }

          &:hover {
            background: #787878;
          }
        }
      }
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  >* {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: .3s ease;
  }

  &._01 {
    &._ex {
      >a {
        &:after {
          width: 16px;
          height: 16px;
          background: url(../../img/common/icon_ex.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    &._sp {
      >a {
        @include link_color($super);
        border-color: $super;

        &:hover {
          background: #ffefed;
        }

        &:after {
          background: url(../../img/common/icon_arrow01_sp.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    &._pr {
      >a {
        @include link_color($premium);
        border-color: $premium;

        &:hover {
          background: #e2f8fd;
        }

        &:after {
          background: url(../../img/common/icon_arrow01_pr.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    &._st {
      >a {
        @include link_color($standard);
        border-color: $standard;

        &:hover {
          background: #e6e6e6;
        }

        &:after {
          background: url(../../img/common/icon_arrow01_st.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    >* {
      color: $key-color;
      font-size: 1.4rem;
      font-weight: bold;
      @include ls(50);
      min-width: 260px;
      border: 2px solid $key-color;
      border-radius: 40px;
      padding: 9px 22px 8px 15px;
      cursor: pointer;

      &:hover {
        background: $hover-color;
      }

      &:after {
        content: '';
        width: 6px;
        height: 10px;
        background: url(../../img/common/icon_arrow01.svg) no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }
  }

  &._02 {
    >* {
      color: $white;
      font-size: 1.4rem;
      font-weight: bold;
      @include ls(50);
      min-width: 260px;
      background: $key-color;
      border-radius: 40px;
      padding: 9px 30px 8px 15px;
      cursor: pointer;

      &:hover {
        background: $key-color-hover;
      }

      &:after {
        content: '';
        width: 6px;
        height: 10px;
        background: url(../../img/common/icon_arrow01_white.svg) no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }
  }

  &._03 {
    >* {
      color: $main-color;
      font-size: 2rem;
      font-weight: bold;
      @include ls(50);
      min-width: 260px;
      border: 2px solid $main-color;
      border-radius: 40px;
      padding: 9px 30px 8px 15px;
      cursor: pointer;

      &:hover {
        border: 2px solid $key-color-hover;
      }
    }
  }

  &._04 {
    >* {
      color: #8c8c8c;
      font-size: 2rem;
      font-weight: bold;
      @include ls(50);
      min-width: 260px;
      border: 2px solid #8c8c8c;
      border-radius: 40px;
      padding: 9px 30px 8px 15px;
      cursor: pointer;

      &:hover {
        color: #3c3c3c;
        border: 2px solid #3c3c3c;
      }
    }
  }

  &._05 {
    >* {
      color: $white;
      font-size: 2rem;
      font-weight: bold;
      @include ls(50);
      min-width: 480px;
      background: $key-color;
      border-radius: 40px;
      padding: 20px 30px 20px 15px;
      cursor: pointer;

      &:hover {
        background: $key-color-hover;
      }
    }
  }

  &._06 {
    a {
      @include link_color($white);
      font-size: 2rem;
      font-weight: bold;
      @include ls(25);
      text-align: center;
      background: $black01;
      width: 480px;
      height: 70px;
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @include mq {
        font-size: 1.4rem;
        width: 100%;
        height: 60px;
      }

      &:hover {
        background: $key-color;

        &:after {
          right: 27px;
        }
      }

      &:after {
        content: '';
        width: 22px;
        height: 20px;
        background: url(../../img/common/icon_arrow02.svg) no-repeat;
        background-size: contain;
        position: absolute;
        right: 31px;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s ease all;
      }
    }
  }

  &._w460 {
    >* {
      @include mq(pc) {
        min-width: 460px;
      }

      @include mq {
        width: 100%;
      }
    }
  }

  .icon-ques {
    padding-left: 25px;
    position: relative;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(../../img/common/icon_question.svg) no-repeat;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .icon-money {
    &:before {
      content: '';
      width: 13px;
      height: 14px;
      background: url(../../img/common/icon_money.svg) no-repeat;
      background-size: contain;
      margin-right: 6px;
      display: inline-block;
      vertical-align: -2px;
    }
  }

  &._pdf {
    a {
      font-weight: bold;
      @include ls(25);
      border: 2px solid $main-color02;
      width: 380px;
      height: 64px;
      background: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &:before {
        content: '\f1c1';
        font-family: $font-awesome;
        color: $main-color;
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 29px;
        transform: translateY(-50%);
        transition: .3s ease;
      }

      &:hover {
        color: $white;
        background: $main-color02;

        &:before {
          color: $white;
        }
      }
    }
  }
}

.btn02 {
  a {
    font-size: 1.8rem;
    font-weight: bold;
    @include ls(75);
    text-align: center;
    width: 100%;
    padding: 24px;
    border: 2px solid $main-color;
    border-radius: 50px;
    margin: 0 auto;
    display: block;
    position: relative;

    @include mq {
      font-size: 1.6rem;
      padding: 15px 15px 15px 30px;
    }

    &:before {
      transition: .3s ease;
    }
  }

  &._document {
    a {
      &:before {
        content: '\f15c';
        color: $main-color;
        font-size: 2rem;
        font-family: $font-awesome;
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%);

        @include mq {
          left: 30px;
        }
      }

      &:hover {
        @include mq(pc) {
          color: $white;
          background: $main-color;
        }

        &:before {
          color: $white;
        }
      }
    }
  }

  &._pdf {
    a {
      &:before {
        content: '\f1c1';
        color: $main-color;
        font-size: 2rem;
        font-family: $font-awesome;
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%);

        @include mq {
          left: 30px;
        }
      }

      &:hover {
        @include mq(pc) {
          color: $white;
          background: $main-color;
        }

        &:before {
          color: $white;
        }
      }
    }
  }

  &._excel {
    a {
      &:before {
        content: '\f1c3';
        color: $main-color;
        font-size: 2rem;
        font-family: $font-awesome;
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%);

        @include mq {
          left: 30px;
        }
      }

      &:hover {
        @include mq(pc) {
          color: $white;
          background: $main-color;
        }

        &:before {
          color: $white;
        }
      }
    }
  }

  &._rsv {
    margin-top: 50px;

    @include mq {
      margin-top: 30px;
    }

    a {
      color: $white;
      font-size: 2.4rem;
      font-weight: bold;
      border: 0;
      max-width: 520px;
      background: $main-color02;

      @include mq(pc) {
        padding: 20px;
      }

      @include mq(msp) {
        font-size: 1.8rem;
        width: calc(100vw - 15px);
      }

      &:hover {
        background: $hover-color;
      }

      &:before {
        content: '';
        background: url(../../img/common/icon_calendar02.svg) no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50px;
        transform: translateY(-50%);
      }

      &:after {
        /*content: '';
        background: url(../../img/common/img_ganshokun02.png) no-repeat;
        width: 85px;
        height: 73px;
        background-size: contain;
        display: block;
        position: absolute;
        right: 20px;
        bottom: -13px;
        @include mq(msp) {
          width: 60px;
          height: 52px;
          right: 13px;
          bottom: -10px;
        }*/
      }
    }
  }
}

.btn03 {
  @include mq {
    text-align: center;
    margin-top: 25px;
  }

  &._white {
    a {
      @include link_color($white);
      border: 1px solid $white;
      background: transparent;

      &:hover {
        color: #016c9e;
        border-color: #016c9e;
      }
    }
  }

  &._en {
    a {
      font-family: $font-mont;
      @include ls(200);
    }
  }

  a {
    font-size: 1.8rem;
    font-weight: bold;
    @include ls(50);
    border: 2px solid #DBDBDB;
    border-radius: 5px;
    background: $white;
    min-width: 220px;
    max-width: 367px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: .3s ease;

    @include mq {
      width: 100%;
    }

    &:hover {
      color: #006FA2;
      border-color: #006FA2;
    }

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background: url(../../img/common/icon_arrow01.png) no-repeat;
      background-size: contain;
      margin-right: 14px;
      margin: -1px 14px 0 0;
    }
  }
}

.tbl01 {
  table {
    width: 100%;

    th,
    td {
      padding: 20px 15px;
      border: 1px solid #D6D6D6;

      @include mq {
        padding: 7px 5px;
      }
    }
  }

  thead {
    th {
      background: $gray01;

      @include mq {
        font-size: 1.1rem;
      }
    }
  }
}

.note-text {
  color: $red;
  @include ls(50);
  @include lh(14, 23);
  text-indent: calc(-1em - 4px);
  padding-left: calc(1em + 4px);
  margin-top: 8px;

  &:before {
    content: '※';
    margin-right: 4px;
  }
}

.note-list {
  &._red {
    color: $red;
  }

  li {
    @include ls(50);
    @include lh(14, 23);
    text-indent: calc(-1em - 4px);
    padding-left: calc(1em + 4px);

    a {
      color: $key-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &:before {
      content: '※';
      margin-right: 4px;
    }
  }
}

.note-box {
  margin-top: 6px;
  background: #FCF8F6;
  padding: 9px 16px 9px 0;

  &._bdr {
    border: 1px solid $red;
  }

  &._02 {
    text-align: center;
    margin-top: 30px;
    padding: 20px;

    p {
      color: #fe0000;
      padding: 0;

      &:before {
        content: '\f071';
        font-family: $font-awesome;
        font-size: 1.7rem;
        margin-right: 8px;
        display: inline-block;
      }
    }
  }

  &:not(._02) {
    p {
      color: $red;
      @include lh(14, 22);
      position: relative;
      padding-left: 44px;

      +p {
        margin-top: 2px;
      }

      &:before {
        content: '';
        background: url(../../img/common/icon_note.svg) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 1px;
        left: 16px;
      }
    }
  }

  a {
    @include link_color($red);
    border-bottom: 1px solid currentColor;
    margin: 0 5px 0 6px;
    position: relative;

    &:hover {
      border-bottom-color: transparent;
    }

    &:before {
      display: inline-block;
      margin-right: 3px;
    }

    &.link-mail {
      &:before {
        content: '';
        background: url(../../img/common/icon_mail.svg) no-repeat;
        background-size: contain;
        width: 15px;
        height: 11px;
        margin-left: 1px;
      }
    }

    &.link-tel {
      &:before {
        content: '';
        background: url(../../img/common/icon_tel02.svg) no-repeat;
        background-size: contain;
        width: 12px;
        height: 12px;
        vertical-align: -1px;
      }
    }
  }
}

// modal
.modal {
  font-size: 1.6rem;
  line-height: 1.5;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: .5s ease opacity;

  @include mq {
    font-size: 1.4rem;
  }

  &._center {
    text-align: center;
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }

  .btn {
    @include mq {
      width: 100%;
    }

    a {
      @include mq {
        min-width: auto;
        width: 100%;
      }
    }
  }

  .btn-close {
    width: 46px;
    height: 46px;
    background: $white;
    border-radius: 50%;
    padding: 13px;
    position: absolute;
    top: -66px;
    right: 0;
    cursor: pointer;
    transition: .3s ease opacity;

    &:hover {
      opacity: .7;
    }

    @include mq {
      width: 35px;
      height: 35px;
      padding: 11px;
      top: 10px;
      right: 20px;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, .8);
  }

  .modal-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq {
      width: 100%;
      height: 100% !important;
      padding: 55px 25px 25px;
    }
  }

  .modal-inner {
    background: $white;
    padding: 60px 34px;
    max-width: $pc-min-width;
    width: 90vw;
    max-height: 80vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include mq {
      padding: 20px;
      width: 100%;
      max-height: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  h3 {
    font-size: 2.8rem;
    text-align: center;

    @include mq {
      font-size: 1.8rem;
    }
  }

  .text {
    margin: 30px 0 20px;
  }

  .check-wrap {
    font-weight: bold;
  }

  &._03 {
    .modal-box {
      height: auto;
    }
    .b-text {
      color: #cd0000;
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 20px;
      @include mq {
        font-size: 2.4rem;
        margin-bottom: 10px;
      }
    }
    .modal-inner {
      border: 5px solid #cd0000;
      width: auto;
      border-radius: 30px;
      @include mq(pc){
        padding: 30px 34px;
      }
      p {
        font-size: 1.4rem;
        @include mq{
          font-size: 1.3rem;
        }
      }
      .red {
        color: #cd0000;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0;
        @include mq{
          font-size: 1.3rem;
          margin-bottom: 20px;
        }
        span{
          display: block;
          margin-top: 10px;
        }
      }
      .conf-table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        margin: 15px auto 15px;
        @include mq(pc){
          margin: 10px auto;
        }
      }
      th,
      td {
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 10px;
        font-size: 1.4rem;
        text-align: center;
        @include mq{
          padding: 5px 10px;
          font-size: 1.3rem;
        }
      }
      th {
        background: #eee;
        font-weight: bold;
      }
      td {
        text-align: left;
      }
      button {
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        height: 57px;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto 0;
        color: #fff;
        background: #035e87;
        width: 300px;
        &:hover {
          background: #024f72;
        }
        @include mq {
          width: 200px;
        }
      }
    }
  }
}

.js-modal {
  cursor: pointer;
}

.blc-btm-bnr {
  background: #eaeaea;

  a {
    &:hover {
      opacity: .7;
    }
  }

  .inner {
    padding: 80px 30px;
    width: 100%;

    @include mq {
      padding: 30px 15px;
    }
  }

  .bnr-list {
    margin-top: 19px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq {
      margin-top: 0;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    li {
      width: 25%;

      @include mq {
        width: calc(50% - 5px);
        margin-top: 10px;
      }
    }
  }
}

em._red {
  color: #ED1900;
}

a.text-link {
  text-decoration: underline;
  color: $main-color;

  &:hover {
    text-decoration: none;
  }
}

.modal-timetable {
  opacity: 0;
  pointer-events: none;
  transition: .5s ease opacity;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  .overlay {
    background: rgba(0, 0, 0, .9);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .btn-close {
    width: 25px;
    height: 25px;
    position: fixed;
    top: 10vh;
    right: 30px;
    transition: .3s ease opacity;

    &:before,
    &:after {
      content: '';
      width: 25px;
      height: 4px;
      background: $white;
      display: block;
      position: absolute;
      top: 10px;
      left: 0;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }

    &:hover {
      @include mq(no-tablet) {
        opacity: .8;
      }
    }
  }

  h3 {
    font-family: $font-zen;
    font-size: 2.8rem;
    @include ls(80);
    @include lh(28, 54);
    text-align: center;
    margin-bottom: 35px;

    @include mq {
      font-size: 2.2rem;
      margin-bottom: 25px;
    }
  }

  .top-fixed {
    width: calc(100% - 60px);
    background: $white;
    border-radius: 20px 20px 0 0;
    position: fixed;
    top: 15vh;
    left: 30px;
  }

  .modal-tab-list {
    display: flex;
    justify-content: space-between;

    li {
      font-size: 1.2rem;
      font-weight: bold;
      @include ls(80);
      text-align: center;
      width: 50%;
      height: 80px;
      opacity: .5;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      @include mq(no-tablet) {
        transition: .3s ease;
      }

      &.active {
        color: $white !important;
        background: #006FA2;
        opacity: 1;
      }

      &:hover {
        @include mq(no-tablet) {
          color: #006FA2;
          opacity: 1;
        }
      }

      .new {
        color: $white;
        font-family: Century Gothic, sans-serif;
        font-size: 1.2rem;
        font-weight: bold;
        @include ls(100);
        background: #CC2A0A;
        width: 38px;
        height: 20px;
        padding-top: 1px;
        margin-right: 9px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq {
          width: 35px;
          height: 14px;
          margin-right: 4px;
        }
      }

      em {
        font-size: 1.6rem;
        margin-top: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .modal-tour-conts {

    //display: none;
    &.active {
      display: block;
    }
  }

  .timetable-box {
    width: calc(100% - 60px);
    height: 75vh;
    background: #F4F5F7;
    padding: 120px 20px 20px;
    position: fixed;
    top: 15vh;
    left: 30px;
    z-index: 999;
    overflow-y: scroll;

    @include mq(pc) {
      padding-top: 150px;
    }

    @include mq(tablet) {
      border-radius: 20px;
    }

    h3 {
      margin-top: 23px;
    }

    .place-def {
      @include mq(pc) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      dt {
        background: $white;
        font-size: 1.6rem;
        font-weight: bold;
        width: 90px;
        height: 34px;
        border-radius: 17px;
        margin-right: 19px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq {
          color: #707070;
          font-size: 1.3rem;
          border-radius: 5px;
          width: 100%;
          height: 25px;
        }
      }

      dd {
        font-size: 1.8rem;
        font-weight: bold;

        @include mq {
          font-size: 1.5rem;
          text-align: center;
          margin-top: 9px;
        }

        img {
          width: 18px;
          height: auto;
          margin-left: 8px;
          display: inline-block;

          @include mq(pc) {
            vertical-align: middle;
            margin-top: -5px;
          }

          @include mq {
            width: 12px;
          }
        }
      }
    }

    .timetable-wrap {
      margin-top: 34px;

      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }

      @include mq {
        margin-top: 17px;
      }

      &._type2 {
        display: flex;
        justify-content: space-between;

        .box {
          width: calc(50% - 5px);

          +.box {
            margin-top: 0;
          }

          .def {
            width: 100%;
          }
        }
      }

      .box {
        width: calc(50% - 5px);
        overflow: hidden;

        @include mq(pc) {
          background: $white;
          border-radius: 10px;
          box-shadow: 0 10px 14px #E7E7E7;
        }

        @include mq {
          width: 100%;

          +.box {
            margin-top: 17px;
          }
        }
      }

      h4 {
        font-size: 2rem;
        font-weight: bold;
        @include ls(25);
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq {
          color: #707070;
          background: $white;
          font-size: 1.3rem;
          font-weight: bold;
          width: 100%;
          height: 25px;
          border-radius: 5px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .def-wrap {
        display: flex;
        justify-content: space-between;
      }

      .def {
        width: calc(50% - 5px);

        >dt {
          font-family: $font-zen;
          color: $white;
          font-size: 2rem;
          @include ls(50);
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq(pc) {
            @include mq(spc) {
              font-size: 2vw;
            }
          }

          @include mq {
            font-size: 1.4rem;
            height: 35px;
            border-radius: 10px 10px 0 0;
          }

          &.plan01 {
            background: #09704A;
          }

          &.plan02.plan03 {
            @include mq(pc) {
              >div {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                &:nth-of-type(1) {
                  background: #124394;
                }

                &:nth-of-type(2) {
                  background: #B3A000;
                }
              }
            }

            background:linear-gradient(90deg, #124394 0%, #124394 50%, #B3A000 50%, #B3A000 100%);
          }

          &.plan-new {
            background: $main-color02;
          }
        }
      }

      .indef {
        font-size: 1.8rem;

        @include mq(pc) {
          padding: 5px 24px 15px;
        }

        @include mq {
          background: $white;
        }

        >div {
          border-bottom: 1px solid #EAECEE;
          padding: 10px 0;

          @include mq(pc) {
            display: flex;
            justify-content: flex-start;
          }

          @include mq(spc) {
            display: block;
          }

          @include mq {
            padding: 10px 9px;
          }

          &:last-child {
            border-bottom: none;
          }

          dt {
            font-family: $font-readex;
            color: #838383;
            @include ls(50);

            @include mq(pc) {
              width: 130px;
              margin-right: 10px;
            }

            @include mq {
              color: #000;
              font-size: 1.4rem;
            }

            span {
              font-family: $font-sans_serif;
              font-size: 1.6rem;
              font-weight: bold;

              @include mq(pc) {
                margin-left: 6px;
              }

              @include mq {
                font-size: 1.3rem;
              }
            }
          }

          dd {
            font-weight: bold;
            @include ls(25);

            @include mq(spc) {
              font-size: 1.6rem;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}