$breakpoints: (
  "pc": "screen and (min-width: 769px)",
  "mpc": "screen and (max-width: 1280px) and (min-width: 769px)",
  "no-tablet": "screen and (min-width: 1281px)",
  "tablet": "screen and (max-width: 1280px)",
  "spc": "screen and (max-width: 979px)",
  "sspc": "screen and (max-width: 790px)",
  "lsp": "screen and (max-width: 768px)",
  "lmsp": "screen and (max-width: 560px)",
  "msp": "screen and (max-width: 414px)",
  "ssp": "screen and (max-width: 330px)"
) !default;

@mixin mq($breakpoint: lsp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

//$main-color: #BA1300;
$main-color: #0070a2;

$main-color02: #0070a2;

//$key-color: #6E2921;
$key-color: #035e87;

$key-color02: #017CB4;

//$key-color-hover: #4c1a14;
$key-color-hover: #024f72;

$text-color: #000;
$hover-color: #035e87;
$hover-color02: #e8e8e8;

//$super: #F05948;
//$premium: #09AECF;
//$standard: #6A6A6A;

$super: #b1310a;
$premium: #0c3060;
$standard: #6a6a6a;
$triton: #3079d0;



$normal-old: #474747;
$yusen-old: #285774;
$premium-old: #9e2556;



$red: #ED1900; //注意文など
$white: #fff;
$black01: #000;
$black02: #333;
$gray01: #F6F6F6;
$gray02: #eee;
$gray03: #898989;
$pink01 :#fe6666;
$bg-gray: #121212;
$color-yt: #cd201f;
$color-fb: #3b5998;
$color-ig: #262626;
$color-tw: #55acee;
$color-ln: #00b900;

$main-width: 1000px;
$pc-min-width: 1000px;
$pc-header-height: 65px;
$pc-header-height-rsv: 104px;
$sp-header-height: 50px;
$sp-header-height-rsv: 60px;

$font-sans_serif: "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;

$font-roboto: 'Roboto', sans-serif;
$font-mont: 'Montserrat', sans-serif;
$font-zen: 'Zen Kaku Gothic New', sans-serif;
$font-readex: 'Readex Pro', sans-serif;
$font-oswald: 'Oswald', sans-serif;
$font-awesome: "FontAwesome";

@mixin inner {
  width: $main-width + 40;
  margin: 0 auto;
  padding: 0 20px;
  @include mq {
    padding: 0;
    width: 100%;
    padding: 0 10px;
  }
}

@mixin lh($fontSize, $height) {
  line-height: $height / $fontSize;
}
@mixin ls($tracking) {
  letter-spacing: #{$tracking / 1000}em;
}

@mixin transition($prop: all, $dur: 0.5, $func: ease, $delay: 0) {
  transition: $prop #{$dur}s $func #{$delay}s;
}

@mixin link_color($color) {
  &,
  &:visited,
  &:hover {
    color: $color;
  }
}

@mixin arrow($width, $bold, $color, $deg) {
  border-style: solid;
  border-width: 0 #{$bold}px #{$bold}px 0;
  content: "";
  display: inline-block;
  border-color: $color;
  @if ($deg == "left") {
    transform: rotate(135deg);
  } @else if ($deg == "top") {
    transform: rotate(225deg);
  } @else if ($deg == "right") {
    transform: rotate(-45deg);
  } @else if ($deg == "bottom") {
    transform: rotate(45deg);
  }
  height: #{$width}px;
  width: #{$width}px;
}

@mixin object-fit($option) {
  width: 100%;
  height: 100%;
  object-fit: $option;
  font-family: 'object-fit: ' + $option + ';';
}

@mixin plan-list {
  @include mq(pc) {
    display: flex;
    justify-content: space-between;
  }
  li {
    border-radius: 10px;
    width: calc(100% / 3 - 13px);
    background: $white;
    box-shadow: 0 10px 14px #E7E7E7;
    position: relative;
    overflow: hidden;
    @include mq {
      width: 100%;
    }
    + li {
      @include mq {
        margin-top: 30px;
      }
    }
    &.plan01 {
      .img-wrap p {
        background: #09704A;
      }
      .def-text dt {
        color: #09704A;
      }
    }
    &.plan02 {
      .img-wrap p {
        background: #124394;
      }
      .def-text {
        dt {
          color: #124394;
        }
        .about {
          color: #124394;
          border-color: #124394;
        }
      }
    }
    &.plan03 {
      .img-wrap p {
        background: #b3a000;
      }
      .def-text {
        dt {
          color: #b3a000;
        }
        .about {
          color: #b3a000;
          border-color: #b3a000;
        }
      }
    }
    &.plan04 {
      .img-wrap p {
        background: #6A6A6A;
      }
      .def-text {
        dt {
          color: #6A6A6A;
        }
        .about {
          color: #6A6A6A;
          border-color: #6A6A6A;
        }
      }
    }
    &.plan05 {
      .img-wrap p {
        background: #0C3060;
      }
      .def-text {
        dt {
          color: #0C3060;
        }
        .about {
          color: #0C3060;
          border-color: #0C3060;
        }
      }
    }
    &.plan06 {
      .img-wrap p {
        background: #B03009;
      }
      .def-text {
        dt {
          color: #B03009;
        }
        .about {
          color: #B03009;
          border-color: #B03009;
        }
      }
    }
  }
  .img-wrap {
    position: relative;
    p {
      color: $white;
      font-family: $font-zen;
      font-size: 1vw;
      font-weight: bold;
      @include ls(50);
      width: 100%;
      padding: 4px 10px;
      position: absolute;
      left: 0;
      bottom: 0;
      @include mq {
        font-size: 1.4rem;
      }
    }
  }
  .conts {
    padding: 26px;
    padding-bottom: 210px;
  }
  .def-text {
    dt {
      font-size: 1.2vw;
      font-weight: bold;
      word-break: break-all;
      @include mq {
        font-size: 1.4rem;
      }
    }
    em {
      font-size: 1.7vw;
      white-space: nowrap;
      @include mq {
        font-size: 2.5rem;
      }
    }
    .about {
      font-size: .85vw;
      font-weight: bold;
      border: 1px solid #000;
      padding: 4px 9px 2px;
      margin-top: 8px;
      width: 100%;
      @include mq {
        font-size: 1.1rem;
      }
    }
    .text {
      font-size: 1.4rem;
      @include ls(25);
      @include lh(14,24);
      margin-top: 23px;
    }
  }
  .btm {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .def-fee {
    padding: 6px 0;
    border-top: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
    text-align: center;
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(100% / 3);
      padding: 0 5px;
      + div {
        border-left: 1px solid #DCDCDC;
      }
    }
    dt {
      font-weight: bold;
      word-break: break-all;
    }
    dd {
      font-family: $font-readex;
      font-size: 1.8rem;
      span {
        font-family: $font-sans_serif;
        margin-right: 3px;
      }
    }
  }
  .btn-box {
    margin-top: 0;
    padding: 30px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: calc(50% - 5px);
      margin-top: 0;
      a {
        font-size: 1.6rem;
        font-weight: bold;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &._dtl {
        a {
          border: 2px solid #DBDBDB;
          &:hover {
            background: #f1f1f1;
          }
        }
      }
      &._rsv {
        a {
          color: $white;
          background: #CC2A0A;
          &:before {
            content: '';
            width: 24px;
            height: 24px;
            margin-right: 6px;
            display: block;
            background: url(../../img/common/icon_ticket02.svg) no-repeat;
          }
          &:hover {
            background: #a31b00;
          }
        }
      }
    }
  }
}
