.mpg-login-box {
  background: $gray01;
  padding: 30px;
  form{
    margin: 20px auto;
    @include mq(pc) {
      width: calc(100% - 40px);
    }
    @include mq {
      width: 100%;
    }
    input[type="text"],
    input[type="password"] {
      display: block;
      width: 100%;
      font-size: 1.6rem;
      margin: 20px auto 10px;
      background: white;
      @include mq(pc) {
        padding: 2rem 20px;
      }
      @include mq {
        padding: 10px 15px;
      }
    }
    input[type="submit"],
    input[type="button"] {
      display: block;
      color: $white;
      font-size: 1.6rem;
      margin: 30px auto 0;
      @include mq(pc) {
        width: 400px;
        padding: 2rem 20px;
      }
      @include mq {
        width: 100%;
        padding: 10px 30px;
      }
    }
    .error {
      color: $red;
    }
    a {
      text-decoration: underline;
    }
  }
}

#p-mpg {
  &.qr {
    .qr-desc {
      font-size: 1.8rem;
      font-weight: bold;

      @include mq(pc) {
        margin-bottom: 15px;
        text-align: center;
      }
      @include mq {
        font-size: 1.4rem;
      }

      div {
        display: flex;
        justify-content: center;
        font-size: 2rem;
        font-weight: bold;

        @include mq {
          font-size: 1.8rem;
        }
      }

      dt {
        margin-right: 20px;
      }
    }

    .mpg-receipt-box {
      width: 270px;
      height: 270px;
      margin: 0 auto 15px;

      @include mq {
        width: 180px;
        height: 180px;
      }

      img {
        @include object-fit(cover);
      }
    }

    .qr-method {
      margin-top: 45px;

      h3 {
        text-align: center;
        font-size: 1.8rem;

        @include mq(pc) {
          margin-bottom: 15px;
        }
      }
    }

    .mpg-conf-form {
      width: 100%;
      th {
        &:first-child {
          @include mq(pc) {
            width: 300px;
          }
        }
      }
    }
    .mpg-secondary-button {
      @include transition;
      cursor: pointer;
      order: 1;
    }
    .mpg-primary-button {
      order: 2;
    }
  }
  &.print-receipt .wrapper{
    padding-top: 100px;
  }
  @media print {
    #msta_langArea {
      display: none !important;
    }
    #header,
    #footer {
      display: none;
    }
    .inner {
      width: 100%;
    }
    .qr-desc {
      margin-bottom: 15px;
      text-align: center;
    }
    #wrapper {
      padding-top: 30px;
    }
    .mpg-receipt {
      padding: 30px 0;
    }
    .btm-btn-box {
      display: none !important;
    }
    .prev,
    input
    a {
      display: none !important;
    }
    .page {
      padding-bottom: 30px;
    }
    .mpg-conf-submit{
      display: none !important;
    }
  }
}

.qr-note{
  >div{
    margin-top: 60px;
    @include mq{
      margin-top: 45px;
    }
  }
  .attract{
    color: $red;
    font-weight: bold;
  }
  &__step{
    .ttl02{
      @include mq{
        margin-bottom: 0;
      }
    }
    dl{
      @include mq(pc){
        display: flex;
        flex-wrap: wrap;
        position: relative;
        &:before,
        &:after{
          content: "";
          display: inline-block;
          height: 1px;
          background: #d6d6d6;
          position: absolute;
          top: 50%;
        }
        &:before{
          width: calc(100% - 60px);
          left: 30px;
          transform: translateY(-50%);
        }
        &:after{
          width: 73%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
      >div{
        padding: 30px;
        @include mq(pc){
          width: 50%;
        }
        @include mq{
          padding: 25px 15px 20px;
        }
        +div{
          @include mq{
            border-top: 1px solid #d6d6d6;
          }
        }
      }
    }
    dt{
      display: flex;
      align-items: flex-end;
      margin-bottom: 15px;
      font-weight: bold;
      @include mq{
        align-items: center;
        margin-bottom: 10px;
      }
      .step{
        font-family: $font-oswald;
        font-size: 2.8rem;
        font-weight: 500;
        margin-right: 20px;
        line-height: 1.25;
        @include mq{
          font-size: 2.2rem;
          line-height: 1;
          padding-bottom: 5px;
        }
        span{
          font-size: 4.2rem;
          @include mq{
            font-size: 3.2rem;
          }
        }
      }
      .jp{
        font-size: 2rem;
        font-weight: bold;
        @include mq{
          font-size: 1.6rem;
        }
      }
    }
    dd{
      img{
        width: 80%;
        display: block;
        margin: 0 auto;
      }
    }
  }
  &__place{
    img{
      @include mq(pc){
        width: 40%;
        display: block;
        margin: 0 auto;
      }
    }
  }
  &__flow{
    dl{
      font-size: 1.8rem;
      font-weight: bold;
      @include mq{
        font-size: 1.6rem;
      }
      >div{
        display: flex;
        @include mq{
          flex-wrap: wrap;
        }
        +div{
          @include mq{
            margin-top: 5px;
          }
        }
      }
    }
    >p{
      padding: 44px 0;
      font-size: 1.8rem;
      position: relative;
      @include mq{
        padding: 38px 0;
        font-size: 1.6rem;
      }
      &:before,
      &:after{
        content: "";
        display: inline-block;
        width: 20px;
        height: 18px;
        background: url(/img/common/icon_arrow01_gray.png) no-repeat center / contain;
        position: absolute;
        left: 40px;
        transform: rotate(90deg);
        @include mq{
          width: 16px;
          height: 14px;
          left: 20px;
        }
      }
      &:before{
        top: 12px;
        @include mq{
          top: 11px;
        }
      }
      &:after{
        bottom: 12px;
        @include mq{
          bottom: 11px;
        }
      }
    }
  }
  &__landing{
    p{
      &:not(:last-child){
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 15px;
        @include mq{
          font-size: 1.6rem;
        }
        span{
          display: block;
          font-size: 2.2rem;
          @include mq{
            font-size: 1.8rem;
            margin-top: 10px;
            line-height: 1.6;
          }
        }
      }
    }
    .attract2{
      color: #ED1900;
      font-weight: bold;
      font-size: 2.2rem;
      @include mq{
        font-size: 1.8rem;
      }
    }
  }
  &__list{
    li{
      padding-left: 15px;
      position: relative;
      line-height: 1.7;
      &:before{
        content: "・";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    >ul{
      >li{
        &:before{
          content: "＊";
        }
        +li{
          margin-top: 20px;
        }
      }
    }
    .last{
      &:before{
        content: "※";
        color: $red;
      }
      .attract{
        text-decoration: underline;
      }
    }
  }
}

.mpg-tab {
  width: 100%;
  border-bottom: 2px solid $key-color;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 40px;
  padding: 0 20px;
  display: flex;
  justify-content: space-around;

  &-history,
  &-info {
    background: $gray01;
    text-align: center;
    width: 100%;
    padding: 18px 0;
    margin: 0 10px;
    border-radius: 10px 10px 0 0;
    &.active {
      background: $key-color;
      color: $white;
    }
    &:hover {
      background: $key-color;
      color: $white;
    }
  }
}
.mpg-conf {
  @include mq(pc) {
    width: 700px;
    margin: 0 auto;
  }
  @include mq {
    padding: 0 0 30px;
  }

  &-form {

    @include mq(pc) {
    }
    @include mq {
      border-collapse: separate;
      border-spacing: 0 10px;
    }
    .reqire {
      display: inline-block;
      padding: 2.5px 10px;
      background: $red;
      color: $white;
      border-radius: 20px;
      font-size: 12px;
    }
    .optional{
      font-size: 12px;
    }
    tr {
      width: 260px;
      @include mq {
       /* table-layout: fixed;
        width: 100%;
        padding-bottom: 20px;*/
      }
    }
    th:first-child {

      //width: 70px;
      @include mq {
      }
    }
    th {
      width: 350px;
      @include mq(pc) {
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        background: #f6f6f6;
        padding: 0 0 0 10px;
        margin-left: 50px;
        text-align: left;
        div{
          display: inline-block;
          vertical-align: middle;
        }
      }
      @include mq {
        display: block;
        padding: 15px 0 15px 10px;
        text-align: left;
        width: 100%;
        background: #f6f6f6;
        border-top: 1px solid #d6d6d6;
        div{
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    td {
      width: 790px;
      padding: 20px;

      select,
      input {
        height: 48px;
        margin: 3px 0;
        padding: 0 5px;
        border: 1px solid $gray03;
        border-radius: 3px;
        @include mq(pc) {
        }
        @include mq {
          background: $white;

        }
      }
      select{
        margin: 5px 0;
        @include mq {
          height: 40px;
        }
      }
      .select-wrap{

      }

      input[type="radio"] {
        margin: auto 5px ;
      }
      .lead {
        font-weight: bold;
        margin-top: 5px;
      }
      .select-gender {
        /* ラジオボタン01 */
        input[type=radio] {
            display: none;
        }
        .radio {
            box-sizing: border-box;
            cursor: pointer;
            display: inline-block;
            padding: 5px 30px;
            position: relative;
            width: auto;
        }
        .radio::before {
            background: #fff;
            border: 1px solid #231815;
            border-radius: 50%;
            content: '';
            display: block;
            height: 16px;
            left: 5px;
            margin-top: -8px;
            position: absolute;
            top: 50%;
            width: 16px;
        }
        .radio::after {
            background: $key-color;
            border-radius: 50%;
            content: '';
            display: block;
            height: 10px;
            left: 9px;
            margin-top: -4px;
            opacity: 0;
            position: absolute;
            top: 50%;
            width: 10px;
        }
        input[type=radio]:checked + .radio::after {
            opacity: 1;
        }
        @include mq(pc) {

        }
        @include mq {
          display: flex;
          align-items: flex-start;
          flex-wrap: nowrap;
          flex-direction: column;
          padding: 0px 0 30px;
          border-bottom: none;
        }
      }
      @include mq(pc) {
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        .w-xs {
          width: 150px;
        }
        .w-sm {
          width: 250px;
        }
        .w-md {
          width: 300px;
        }
        .w-lg {
          width: 350px;
        }
        .w-xl {
         width: 100%;
        }
      }
      @include mq {
        padding: 10px 0 20px;
        width: 100%;
        display: block;
        //border-bottom: 1px solid #d6d6d6;
        .flex {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          label {
            flex: 1;
          }
        }
        .w-xs {
          width: 30%;
        }
        .w-sm {
          width: 38%;
        }
        .w-md {
          width: 40%;
        }
        .w-lg {
          width: 100%;
        }
        .w-xl {
         width: 100%;
        }
      }
      .caution,
      .error {
        font-size: 12px;
        color: $red;
      }
      .caution {
        margin-bottom: 15px;
      }
    }
    .input-flex select {
      border: unset;
    }
  }
  &-submit {
    display: flex;
    @include mq {
      margin: 15px auto;
      width: calc(100% - 60px);
      flex-direction: column-reverse;
    }
  }
}
.mpg-receipt{
  @include mq(pc) {
    width: 800px;
    margin: 0 auto;
    padding: 30px 0;
  }
  @include mq {
    padding: 30px 0;
  }

  &.lg {
    @include mq(pc) {
      width:1000px;
      margin: 0 auto;
    }

  }
  &-issued-date {
    width: 900px;
    margin: 0 auto;
    text-align: right;
    @include mq {
      width: 100%;
    }
    &-box{
      display: inline-block;
      margin-right: 0;
      text-align: left;
    }
  }

  &-form {
    width: 100%;
    tbody {
      tr {
        width: 100%;
        th {
          width: 160px;
          padding: 0 0 0 20px;
          padding: 0 0 0 20px;
          margin-left: 50px;
          text-align: left;
          @include mq {
            width: 20%;
            border-top: 2px solid $gray01;
            border-bottom: 2px solid  $gray01;
          }
          @include mq(pc) {
            border-top: 2px solid $key-color;
            border-bottom: 2px solid $key-color;
          }
        }
        td {
          width: 500px;
          border-top: 2px solid $gray01;
          border-bottom: 2px solid  $gray01;
          @include mq {
            width: 100%;
            padding: 20px;
            height: 40px;
          }
          @include mq(pc) {
            padding: 16px 0 12px;
          }
          select,
          input {
            margin: 2.5px;
          }
          input[type="text"] {
            @include mq(pc) {
              width: 400px;
            }
            @include mq {
              width: 100%;
            }
          }
          input[type="radio"] {
            margin: auto 5px ;
          }

          .select-title {
            /* ラジオボタン01 */
            input[type=radio] {
                display: none;
            }
            .radio {
                box-sizing: border-box;
                cursor: pointer;
                display: inline-block;
                padding: 5px 30px;
                position: relative;
                width: auto;
            }
            .radio::before {
                background: #fff;
                border: 1px solid #231815;
                border-radius: 50%;
                content: '';
                display: block;
                height: 16px;
                left: 5px;
                margin-top: -8px;
                position: absolute;
                top: 50%;
                width: 16px;
            }
            .radio::after {
                background: $key-color;
                border-radius: 50%;
                content: '';
                display: block;
                height: 10px;
                left: 9px;
                margin-top: -4px;
                opacity: 0;
                position: absolute;
                top: 50%;
                width: 10px;
            }
            input[type=radio]:checked + .radio::after {
                opacity: 1;
            }
            @include mq(pc) {

            }
            @include mq {
              display: flex;
              align-items: flex-start;
              flex-wrap: nowrap;
              flex-direction: column;
            }
          }
          .lead {
            font-weight: bold;
          }
          .caution,
          .error {
            font-size: 12px;
            color: $red;
          }
        }
      }
    }
  }
  &-conf {
    width: 100%;
    tr {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 1.5em;
    }
    th {
      width: 100px;
      padding: 0 30px 0 0px;
      table-layout: fixed;
      @include mq {
        width: 20%;
      }
    }
    td {
      table-layout: fixed;
      @include mq {
        width: 100%;
        padding: 20px;
      }
    }

  }
  &-ttl {
    border-bottom: 4px solid $gray02;
    padding-bottom: 15px;
  }
  &-preview {
    width: 900px;
    margin: 0 auto;
    @include mq {
      width: 100%;
    }
    table {
      width: 100%;
      padding: 5px 10px;
      margin: 20px 0;
    }
    tr:first-child {
      border-top: 2px solid #F6F6F6;
    }
    th {
      max-width: 160px;
      @include mq(pc) {
        border-top: 2px solid $key-color;
        border-bottom: 2px solid $key-color;
        padding: 12px 0 12px 20px;
        margin-left: 50px;
        text-align: left;
      }
      @include mq {
        font-size: 16px;
        display: block;
        width: 100%;
        padding: 20px 0px 0 0px;
        text-align: left;
      }
    }
    td {
      width: 740px;
      padding: 20px;
      border-bottom: 2px solid $gray01;
      @include mq {
        width: 100%;
        display: block;
        padding: 10px 0 20px 0;
      }
      select,
      input {
        height: 40px;
        margin: 2.5px;
        padding: 0 5px;
        border: 1px solid $gray03;
        border-radius: 3px;
        @include mq(pc) {
        }
        @include mq {
          background: $white;
        }
      }
    }
    .value {
      font-weight: bold;
    }

  }
  &-caution {
    margin-top: 20px;
    border: 1px solid #777;
    border-radius: 20px;
    padding: 20px;
    .lead {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  &-submit  {
    display: flex;
    @include mq {
      margin: 15px auto;
      width: calc(100% - 60px);
      flex-direction: column-reverse;
    }
  }
  &-print {

  @media print{
    display: none;
  }
}
}

.mpg-table01 {
  margin: 0 auto 30px;
  width: 900px;
  padding: 5px 10px;


  @include mq {
    width: 100%;
  }
  tr:first-child {
    //border-top: 2px solid #F6F6F6;
    border-top: 1px solid #D6D6D6;
  }
  th {
    max-width: 250px;
    @include mq(pc) {
      //border-top: 2px solid $key-color;
      //border-bottom: 2px solid $key-color;
      border-top: 1px solid #D6D6D6;
      border-bottom: 1px solid #D6D6D6;
      background: #f6f6f6;
      padding: 15px 0 15px 20px;
      margin-left: 50px;
      text-align: left;
    }
    @include mq {
      display: block;
      width: 100%;
      padding: 10px 0 10px 10px;
      text-align: left;
      background: #f6f6f6;
      font-size: 120%;
      max-width: 100%;
    }
  }
  td {
    width: 740px;
    padding: 20px;
    //border-bottom: 2px solid $gray01;
    border-bottom: 1px solid #D6D6D6;
    @include mq {
      width: 100%;
      display: block;
      padding: 12px 0 30px 10px;
    }
    .stat-active {
      padding: 5px 15px;
      background: $main-color;
      border-radius: 14px;
      color: $white;
    }
    select,
    input {
      height: 40px;
      margin: 2.5px;
      padding: 0 5px;
      border: 1px solid $gray03;
      border-radius: 3px;
      @include mq(pc) {
      }
      @include mq {
        background: $white;
      }
    }
  }
  .value {
    font-weight: bold;
  }

}
.mpg-button-box + .mpg-border {
  margin: 0 auto;
  padding-top: 70px;
  border-top: 3px solid $main-color;
  @include mq {
    width: 100%;
  }
}
.mpg-table02 {
  margin: 0 auto;
  width: 100%;
  margin: 0 auto;
  @include mq {
    width: 100%;
  }
  table {
    width: 100%;
    padding: 5px 10px;
    margin: 20px 0 30px 0;
  }
  tr:first-child th {
    border-top: 2px solid #e1e1e1;
  }
  tr:last-child th {
    border-bottom: 2px solid #e1e1e1;
  }
  th {
    @include mq(pc) {
      border-top: 2px solid $white;
      border-bottom: 2px solid $white;
      background: #e1e1e1;
      padding: 12px 0 12px 20px;
      margin-left: 50px;
      text-align: left;
      width: 360px;
    }
    @include mq {
      display: block;
      width: 100%;
      padding: 20px 0px 0 0px;
      text-align: left;
    }
  }
  td {
    padding: 20px;
    border-top: 2px solid #e1e1e1;
    border-bottom: 2px solid #e1e1e1;
    @include mq {
      width: 100%;
      display: block;
      padding: 10px 0 20px 0;
    }
    .stat-active {
      padding: 5px 15px;
      background: $main-color;
      border-radius: 14px;
      color: $white;
    }
    select,
    input {
      height: 40px;
      margin: 2.5px;
      padding: 0 5px;
      border: 1px solid $gray03;
      border-radius: 3px;
      @include mq(pc) {
      }
      @include mq {
        background: $white;
      }
    }
  }
  .value {
    font-weight: bold;
  }

}

.mpg-outline  {
  border: 1px solid $gray02;
}
.mpg-thirdly-button,
.mpg-secondary-button,
.mpg-primary-button {
  border-radius: 35px;
  text-align: center;
  display: block;
  font-size: 1.6rem;
  margin: 30px auto 0;
  &:hover {
    cursor: pointer;
  }
  @include mq(pc) {
    width: 350px;
    padding: 2rem 20px;
  }
  @include mq {
    width: 100%;
    padding: 10px 30px;
    margin: 20px auto 0;

  }
}
.mpg-primary-button {
  color: $white  !important;
  background: $key-color;
  transition-property: background-color, opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;


  &:hover {
    color: $white;
    background: $key-color-hover;
    transition-property: background-color, opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease;
  }
  a,input {
   color: $white;
  }
}

.reserve_list .mpg-primary-button {
  margin: 20px auto 0;
}

.mpg-secondary-button {
  color: $black01;
  background: $gray01;
  &:hover {
    background: $hover-color02;
  }
}

.mpg-thirdly-button {
  color: $key-color;
  border: 2px solid $key-color;
  &:hover {
    color: $white;
    background:  $key-color;

  }

  @include mq(pc) {
    width: 350px;
    padding: 1rem 30px;
  }
}
.mpg-conf-submit,
.mpg-button-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  >div {
    margin: 0 10px;
    @include mq {
      width: 100%;
      margin: 0;
    }
  }
  .mpg-thirdly-button,
  .mpg-secondary-button,
  .mpg-primary-button {
    width: 300px;
    @include mq {
      width: 100%;
    }
  }
}

.mpg-desc {
  font-size: 1.6rem;
  margin: 0 auto 15px;
  @include mq(pc) {
    width: 900px;
  }
}

#p-mpg {
  .blc {
    margin-top: 20px;
    + .blc {
      margin-top: 40px;
    }
  }
  .btm-btn-box {
    width: 100%;
    padding: 20px 0;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq {
      padding: 20px;
      margin-top: 10px;
      flex-direction: column;
    }
    .prev,
    .next {
      font-size: 1.6rem;
      font-weight: bold;
      @include ls(50);
      height: 57px;
      border-radius: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px;
      @include mq(pc) {
        width: 350px;
      }
      @include mq {
        width: 100%;
      }
    }
    .prev {
      background: #F6F6F6;
      position: relative;
      @include mq {
        order: 1;
      }
      &:hover {
        @include mq(pc) {
          background: $hover-color02;
        }
      }
      &:after {
        content: '';
        width: 6px;
        height: 10px;
        background: url(/img/common/icon_arrow01_white.svg) no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .next {
      background: $key-color;
      color: $white;
      &:hover {
        @include mq(pc) {
          background: $key-color-hover;
        }
      }
      @include mq {
        order: 2;
        margin-top: 14px;
      }
    }
  }
}

.recipt-hanko{
  p.left{
    float: left;
    width: 64%;
    @include mq {
      float: none;
      width: 100%;
    }
  }
  div.hanko{
    width: 35%;
    border: 2px solid #eee;
    padding: 15px 15px 5px 15px;

    box-sizing: border-box;
    float: right;
    @include mq {
      margin: 15px 0 0 0;
      float: none;
      width: 100%;
    }
    .name{
      font-size: 1.5rem;
      font-weight: bold;
    }
    .address{
      float: left;
    }
    .img{
      width: 60px;
      position: relative;
      top: -10px;
      float: right;

    }
  }

}