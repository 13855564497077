.about {
  &-top {
    margin-bottom: 40px;
    .inner {
      width: 1120px;
      color: $white;
      @include mq {
        width: auto;
        padding: 0 0 ;
      }
    }
    &-img {
      width: 100%;
      position: relative;
      @include mq(pc) {
        width: 1060px;
        margin-bottom: -138px;
      }
      img {
        @include mq {
          padding: 0 0 ;
        }
        width: 100%;
        height: auto;
      }

    }
    &-bg {
      background: #000;
    }
    &-lead {
      font-size: 18px;
      padding: 248px 60px 40px;
      @include mq {
        padding: 30px 20px;
      }
      h1 {
        font-weight: normal;
        font-size: 50px;
        @include mq {
          font-size: 30px;
        }
      }
      p {
        @include mq {
          font-size: 16px;
        }
      }
    }
    &-title {
      font-size: 20px;
      @include lh(22,36);
      text-align: left;
      margin: 0 auto 40px;
      padding: 0 60px;
      @include mq {
        font-size: 18px;
        padding: 0 20px;
        margin: 0px auto 40px;
      }
    }
    &-display {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 0 60px 110px;
      @include mq(pc) {
        display: flex;
        margin-bottom: 76px;
      }
      @include mq {
        padding: 0 20px 60px;
      }
        &-desc {
          font-size: 16px;
          line-height: 24px;
          @include mq(pc) {
            order: 1;
            margin-right: 40px;
          }
        }
        &-img{

          @include mq(pc) {
            order: 2;
          }
          @include mq {
            float: left;
            margin-right: 15px;
          }
          img{
          width: 296px;
          height: auto;
          @include mq {
            width: 173px;
            height: 173px;
          }
        }
      }
    }
  }
  &-block {
    padding: 70px 60px 80px;
    @include mq {
      padding: 30px 20px 40px;
    }
    &.gray {
      background-color: #F0F0F0;
    }
  }
  &-content {
    .inner {
      width: 1120px;
      @include mq {
        width: auto;
        padding: 0 0 ;
      }
    }
    #history,
    #best {
    /*  position: absolute;
      top: -100%;
      width: 0;*/
    }
    &-tab {
      position: relative;
      display: flex;
      justify-content: space-around;
      padding: 0 80px;
      margin: 0 0 40px;
      @include mq {
        justify-content: space-between;
        padding: 20px;
      }
      div {
        padding: 0 20px;
        width: 425px;
        @include mq {
          padding: 0;
          width: 50%;
          width: calc(50% - 10px);
        }
        a {
          text-align: center;
          width: 100%;
          i {
            font-size: 20px;
            @include mq(pc) {
              margin-right: 20px;
            }
          }
        }
        &.btn._04 > *,
        &.btn._03 > * {
          border-radius: 5px;
          font-size: 16px;
          min-width: auto;
          height: 80px;
          padding: 0;
          text-align: center;
          &:hover,
          &.active{
            background: $key-color;
            color: #fff;
            border-color: $key-color;
          }
          @include mq {
            height: 70px ;
            flex-direction: column;
            justify-content: space-evenly;
          }
        }
        &.btn._04 > *{
          border-color: $key-color;
          color: $key-color;
        }
      }
    }

    &-title {
      font-size: 28px;
      font-weight: bold;
      color: #066E9D;
      @include lh(22,36);
      text-align: center;
      margin: 0 auto 40px auto;
      @include mq {
        font-size: 20px;
        margin: 0 auto 20px;
      }
    }
    &-desc {
      font-size: 20px;
      @include lh(22,36);
      text-align: center;
      margin: 40px auto;
      @include mq {
        font-size: 16px;
        margin: 20px auto;
      }
    }
    &-caption {
      @include lh(22,36);
      text-align: center;
      padding: 40px 0;
      background: $white;
    }
    &-gallary {
      display: flex;
      >div:nth-child(1) {
        order: 2;
      }
      >div:nth-child(2) {
        order: 1;
      }
      @include mq {
        flex-direction: column;
      }
      &-desc {
        font-weight: bold;
        @include lh(22,36);
        margin: 20px auto 10px;
      }
      &-box {
        width: 580px;
        height: auto;
        z-index: 0;
        @include mq {
          order: 1;
          width: 100%;
          height: auto;
          flex-basis: auto;
        }
      }
      &-caption {
        flex-basis: 428px;
        width: 428px;
        padding: 0 40px 40px;
        z-index: 1;
        @include mq {
          flex-basis: unset;
          background: unset;
          order: 2;
          font-size: 14px;
          width: 100%;
          padding: 0;
        }
        &._pb40 {
          padding-bottom: 40px;
        }
      }
      dl {
        display: flex;
        flex-wrap: wrap;
        * {
          margin-bottom: 30px;
        }
        dt {
          display: block;
          width: 120px;
        }
        dd {
          width: calc(100% - 120px);
          display: block;

        }
      }
      .quote {
        padding: 20px 30px;
        background: #f8f8f8;
        @include mq {
          padding: 10px 20px;
          margin: 10px 0 20px;
        }
      }
      .right {
        text-align: right;
      }
      .p-mh {
        padding: 30px 0;
      }
    }

  }

}