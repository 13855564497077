.psw-box {
  background: $gray01;
  padding: 30px;
  line-height: 24px;
  form{
    @include mq(pc) {
      width: calc(100% - 40px);
      margin: 40px auto;
    }
    @include mq {
      width: 100%;
      margin: 20px auto;
    }
    input[type="text"], 
    input[type="password"] {
      display: block;
      width: 100%;
      font-size: 1.6rem;
      margin: 20px auto 10px;
      background: white;
      @include mq(pc) {
        padding: 2rem 30px;
      }
      @include mq {
        padding: 10px 15px;
      }
    } 
    input[type="button"] {
      display: block;
      background: $main-color;
      color: $white;
      font-size: 1.6rem;
      margin: 30px auto 0;
      @include mq(pc) {
        width: 400px;
        padding: 2rem 30px;
      }
      @include mq {
        width: 100%; 
        padding: 10px 30px;
      }
    }
    .error {
      color: $red;
    }
  }
  .gray-btn {
    text-align: center;
    margin: 20px auto;
    padding: 1.6rem 20px;
    background: #777;
    color: $white;
    @include mq(pc) {
      width: 400px;
      padding: 2rem 30px;
    }
    @include mq {
      width: 100%;
      padding: 10px 30px;
    }
  }
  .psw-info {
    margin: 20px;
  }
  .center {
    text-align: center;
  }
}