.faq-top {
  margin: 0 auto;
  padding: 20px 0 80px;
  max-width: 1000px;
  @include mq {
    margin: auto 10px auto 10px;
  }

  .faq-anchor{
    margin: 0 -40px 40px;
    @include mq {
      margin: 0 0 20px;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @include mq {
        display: block;
        padding: 0 10px;
      }
      li{
        width:49%;
        margin: 0 1% 10px 0;
        font-size: 1.4rem;
        border: 2px solid #e6e6e6;
        &:nth-child(2n){
          margin: 0 0 10px;
        }
        @include mq {
          width: 100%;
          margin: 0 0 5px;
          &:nth-child(2n){
            margin: 0 0 5px;
          }
        }
        a{
          padding: 14px 0 14px 33px;
          display: block;
          background: #fff;
          position: relative;
          font-weight: bold;
          line-height: 1.4;
          &:hover{
            text-decoration: none;
            background: #e6e6e6;
          }
          &:before{
            content: "Q.";
            font-family: "Roboto", sans-serif;
            font-size: 2rem;
            color: $main-color;
            font-weight: 500;
            position: absolute;
            left: 10px;
            top:7px;
            margin: 0 5px 0 0;
            display: inline-block;
          }
          @include mq {
            padding: 10px 0 10px 33px;
            &:before{
              top:3px;
            }
          }
        }
      }
    }
  }

  &-list {
    > dl {
      margin-left: 40px;
      > dt {
        position: relative;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 35px;
        &::before {
          position: absolute;
          content: "Q";
          font-size: 28px;
          width: 60px;
          height: 60px;
          text-align: center;
          border-radius: 30px;
          line-height: 57px;
          background: $main-color;
          font-family: "Roboto", sans-serif;
          font-weight: 500;
          left: -80px;
          color: white;
          font-weight: bold;

          top: -10px;
          @include mq {
            width: 30px;
            height: 30px;
            left: -40px;
            font-size: 18px;
            line-height: 30px;
            top: -3px;
          }
        }
        &:nth-child(n+2) {
          padding-top: 80px;
          &::before {
          top:75px;
          }
        }
        @include mq {
          font-size: 1.6rem;
        }
      }
      > dd{
        position: relative;
        background: #eff6f9;
        border-radius: 6px;
        padding: 30px;
        font-size: 16px;
        @include mq {
          padding: 30px 10px 30px 20px;
        }
        &::before {
          position: absolute;
          content: "A";
          font-size: 28px;
          width: 54px;
          height: 54px;
          top: 0;
          text-align: center;
          border-radius: 30px;
          line-height: 55px;
          background: #eff6f9;
          border: 1px solid $main-color;
          font-family: "Roboto", sans-serif;
          font-weight: 500;
          left: -80px;
          color: $main-color;
          font-weight: bold;
          @include mq {
            width: 24px;
            height: 24px;
            left: -40px;
            font-size: 18px;
            line-height: 25px;
          }
        }
        .btn._01 a {
          background: $white;
          border-color: $main-color;
          color: $main-color;
          padding: 9px 28px 8px 15px;
          &:hover{
            background: $main-color;
            color: #fff;
          }
        }
        .lead {
          font-weight: bold;
          margin-bottom: 24px;
        }
        .desc {
          margin-bottom: 24px;
        }
        > dl {
          dt {
            font-size: 1.6rem;
            font-weight: bold;
            margin-bottom: 15px;
          }
          dd {
            display: flex;
            position: relative;
            justify-content: space-between;
            padding-left: 1.5em;
            width: calc(100% - 1.5em);
            margin-bottom: 27px;
            @include mq {
              padding-left: 1.2em;
              width: 100%;
              flex-direction: column;
            }
            &::before {
              position: absolute;
              content: "";
              top: 0.6em;
              left: 0;
              width: 10px;
              height: 2px;
              background: $main-color;
            }
            figure {
              width: 200px;
              margin-left: 20px;
              @include mq {
                width: auto;
                margin-left: 0px;
              }
              img {
                width: 200px;
                @include mq {
                  width: 255px;
                }
              }
            }
          }
        }
      }
    }
  }

}
