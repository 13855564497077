.form_box01 {
  box-sizing: border-box;
  padding: 45px;
  background: #f7f7f7;
  @include mq {
    padding:0;
    background: #fff;
  }
}

h1.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
  @include mq {
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.reed_txt {
  margin-bottom: 20px;

  @include mq {
  .reed_txt {
    margin: 0 15px 15px;
    }
  }
}

.st03 {
  font-size: 20px;
 // color: #5065cc;
  margin-bottom: 15px;
  padding: 20px 0 5px;
  border-bottom: 1px solid #ccc;
  @include mq {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 20px 15px 3px 15px;
    border-bottom: 1px solid #ccc;
  }
}
.st02 {
  font-size: 24px;
  text-align: center;
  margin:50px 0 20px;
  padding-bottom: 5px;
  position: relative;
  @include mq {
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 3px;
    position: relative;
    }
  &:after{
      margin: auto;
      width: 50px;
      height: 2px;
      right: 0;
      left: 0;
      bottom: 0;
      background: #222;
      content: "";
      position: absolute;
  }
}