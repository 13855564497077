.sidebar {
  font-size: 1.6rem;
  font-weight: bold;

  @include mq(pc) {
    width: 290px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    position: fixed;
    top: 65px;
    bottom: 0;
    right: 0;
    z-index: 10;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @include mq(tablet) {
    width: 100%;
    position: relative;
    top: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &-inner {
    padding: 20px 20px 380px;

    @include mq(tablet) {
      padding: 30px 20px;

      @include mq {
        padding: 30px 15px;
      }
    }
  }

  img {
    width: auto;
  }

  .list01 {
    >li {
      +li {
        margin-top: 10px;
      }

      &:nth-child(1) {
        .tour-btn {
          background: #55b5c6;
        }

        img {
          @include mq(tablet) {
            width: 31px;
          }
        }

        .tour-conts {
          background: #e3f1f4;

          ol li a {
            &:hover {
              color: #55b5c6;
            }
          }
        }

        .ttl {
          color: #55b5c6;
        }

        .num {
          background: #55b5c6;
        }

        .btn-rsv {
          a {
            background: #55b5c6;

            &:hover {
              background: #449bab;
            }
          }
        }

        .btn-rsvd {
          a {
            &:hover {
              color: #55b5c6;
              border-color: #55b5c6;
            }
          }
        }
      }

      &:nth-child(2) {
        .tour-btn {
          background: #ff007e;
        }

        img {
          @include mq(tablet) {
            width: 33px;
          }
        }

        .tour-conts {
          background: #fdecf4;

          ol li a {
            &:hover {
              color: #ff007e;
            }
          }
        }

        .ttl {
          color: #ff007e;
        }

        .num {
          background: #ff007e;
        }

        .btn-rsv {
          a {
            background: #ff007e;

            &:hover {
              background: #d7006a;
            }
          }
        }

        .btn-rsvd {
          a {
            &:hover {
              color: #ff007e;
              border-color: #ff007e;
            }
          }
        }
      }
    }

    .tour-btn {
      color: $white;
      font-size: 1.4rem;
      @include ls(25);
      line-height: 21px;
      padding: 10px 42px 10px 74px;
      display: block;
      position: relative;
      cursor: pointer;

      @include mq(tablet) {
        padding: 15px 35px 15px 53px;
        white-space: nowrap;
      }

      &:hover {
        img {
          @include mq(pc) {
            top: 43%;
          }
        }
      }

      &.active {
        &:after {
          transform: rotate(180deg);
        }
      }

      &:before,
      &:after {
        content: '';
        width: 18px;
        height: 1px;
        background: $white;
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;

        @include mq {
          right: 10px;
        }
      }

      &:after {
        transform: rotate(90deg);
        transition: .3s ease;
      }

      img {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        transition: .3s ease;

        @include mq {
          left: 12px;
        }
      }

      em {
        color: #fff000;
        font-size: 1.7rem;

        @include mq(pc) {
          display: block;

          @include mq(tablet) {
            display: inline-block;
          }
        }
      }

      >span {
        font-size: 1.6rem;

        @include mq {
          font-size: 1.5rem;
        }
      }
    }

    .tour-conts {
      @include ls(25);
      padding: 15px;
      display: none;

      .text {
        font-size: 1.3rem;
        text-align: center;
      }

      .sub-note {
        font-size: 1.1rem;
        font-weight: normal;
        text-align: center;
        margin-top: 8px;
      }

      .ttl {
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
        margin-top: 3px;
      }

      ol {
        font-size: 1.6rem;
        margin-top: 10px;

        li {
          padding-left: 28px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          position: relative;

          +li {
            margin-top: 10px;
          }

          a {
            line-height: 1.3;
            transition: .3s ease;
          }
        }

        .num {
          color: $white;
          font-size: 1.3rem;
          font-weight: bold;
          padding-top: 2px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
        }

        .sub {
          font-size: 1.2rem;
          margin-top: 3px;
          display: inline-block;
        }
      }

      .btn-box {
        margin-top: 20px;

        >div {
          +div {
            text-align: center;
            margin-top: 5px;
          }
        }
      }

      .btn-rsv {
        position: relative;

        a {
          color: $white;
          font-size: 1.8rem;
          font-weight: bold;
          width: 100%;
          height: 65px;
          //padding-left: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          &:after {
            display: none;
            content: '';
            width: 26px;
            height: 26px;
            background: url(../../img/common/icon_calendar02.svg) no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 28px;
            transform: translateY(-50%);

            @include mq {
              left: 19vw;
            }
          }
        }

        span {
          font-size: 1.2rem;
          font-weight: normal;
          display: block;
        }
      }

      .btn-rsvd {
        a {
          font-size: 1.2rem;
          font-weight: normal;
          background: $white;
          height: 55px;
          border-width: 1px;
          border-style: solid;
          border-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: .3s ease;
        }
      }

      .search-box {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        form {
          display: flex;
        }

        input {
          border: 1px solid #999;
          border-radius: 5px;
          width: calc(100% - 41px);
          padding: 6px;
          background: $white;
          margin: 0 5px 0 0;
        }

        button {
          color: $white;
          font-size: 1.6rem;
          background: $black01;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .list02 {
    margin-top: 20px;

    //display: flex;
    @include mq {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
    }

    @include mq(mpc) {
      display: flex;
      justify-content: space-between;
    }

    li {

      @include mq(pc) {
        //width: 100%;
      }

      @include mq(mpc) {
        width: calc(50% - 10px);
        text-align: center;
      }

      @include mq {
        width: calc(50% - 10px);
        text-align: center;
      }

      +li {
        @include mq(pc) {
          margin-top: 10px;
        }

        @include mq(mpc) {
          margin-top: 0 !important;
        }
      }

      &:nth-child(1) {
        img {
          @include mq(pc) {
            //width: 35px;
            // left: 20px;
          }

          @include mq {
            // width: 25px;
          }
        }
      }

      &:nth-child(2) {
        img {
          @include mq(pc) {
            // width: 30px;
            // left: 22px;
          }

          @include mq {
            // width: 25px;
          }
        }
      }
    }

    a {
      height: auto;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      img {
        width: auto;
        max-width: 100%;
        height: auto;
      }

      &:hover {
        opacity: 0.5;

        @include mq(pc) {
          color: $white;
          background: #c01920;

          svg {
            path {
              fill: $white;
            }
          }
        }
      }

      @include mq {
        justify-content: center;
      }
    }

    svg {
      &._01 {
        width: 35px;
        height: 35px;

        @include mq {
          width: 25px;
          height: 25px;
        }
      }

      &._02 {
        width: 30px;
        height: 30px;

        @include mq {
          width: 20px;
          height: 20px;
        }
      }

      @include mq(pc) {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }

      @include mq {
        margin-right: 6px;
      }

      path {
        fill: #c01920;
        transition: .3s ease;
      }
    }
  }

  .list03 {
    margin-top: 20px;

    @include mq {
      display: none;
    }

    li {
      +li {
        margin-top: 5px;
      }

      &:nth-child(1) {
        img {
          width: 23px;
        }
      }

      &:nth-child(2) {
        img {
          width: 22px;
        }
      }

      a {
        font-size: 1.4rem;
        padding: 10px 0 10px 30px;
        display: block;
        position: relative;
        transition: .3s ease;

        &:hover {
          color: #c01920;
        }
      }

      img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .def {
    text-align: center;
    margin-top: 25px;

    >dt {
      color: $white;
      @include ls(5);
      background: #c01920;
      padding: 10px 5px;
      font-size: 1.5rem;

      @include mq {
        padding: 7px 12px 5px 12px;
      }
    }

    >dd {
      border: 1px solid #c01920;
      padding: 15px;

      @include mq {
        padding: 10px;
      }
    }

    .indef {
      @include mq {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      >dt {
        @include mq {
          width: 100%;
        }
      }

      >div {
        @include mq {
          width: calc(50% - 5px);
        }

        dt {
          color: $white;
          font-size: 1.5rem;
          background: #333;
          padding: 10px;
          margin-top: 10px;

          @include mq {
            font-size: 1.4rem;
            padding: 6px;
          }

          span {
            font-size: 1.2rem;

            @include mq {
              font-size: 1rem;
            }
          }
        }

        dd {
          padding: 13px 10px;

          @include mq {
            padding: 7px 7px 3px;
          }
        }
      }
    }
  }

  .list04 {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    @include mq(tablet) {
      justify-content: center;
    }

    @include mq {
      display: none;
    }

    li {
      @include mq(tablet) {
        margin: 0 5px;
      }
    }

    a {
      font-size: 1.2rem;
      @include ls(5);
      text-align: center;

      &:hover {
        color: #c01920;

        i {
          background: #970309;
        }
      }
    }

    i {
      font-size: 1.6rem;
      color: $white;
      width: 40px;
      height: 40px;
      margin: 0 auto 7px;
      border-radius: 50%;
      background: #c01920;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .3s ease;
    }
  }

  .list05 {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    @include mq {
      display: none;
    }

    li {
      margin: 0 5px;

      &.fb a {
        background: $color-fb;
      }

      &.ig a {
        background: $color-ig;
      }

      &.tw a {
        background: $color-tw;
      }

      a {
        color: $white;
        width: 36px;
        height: 36px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: .7;
        }
      }
    }
  }
}

.sidenav {
  background: $white;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include mq(no-tablet) {
    width: 280px;
    height: 100vh;
    padding-bottom: 30px;
    border-right: 1px solid #D4D4D4;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
  }

  @include mq(tablet) {
    width: 100%;
    padding: 30px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  h1 {
    padding: 20px 64px 18px;
    border-bottom: 3px solid #E6E6E6;
    height: 127px;

    @include mq(tablet) {
      display: none;
    }

    .logo-box {
      width: 62px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        display: block;
        transition: .3s ease opacity;

        &:hover {
          opacity: .8;
        }

        &:first-child {
          width: 62px;
        }

        /* &:last-child {
          width: 47px;
        }*/
      }
    }

    .text {
      height: 26px;
      margin-top: 10px;
    }
  }

  .list {
    &:nth-of-type(3) {
      margin-top: 30px;
      border-top: 3px solid #E6E6E6;

      @include mq(tablet) {
        border-top: 0;
      }
    }
    &.sidemenu_pdf {
      @include mq(tablet) {
        margin: 10px 0 0 0;
      }

      >li {
        >a {
          padding-left: 46px;

          @include mq(tablet) {
            padding-left: 48px;
          }

          &:before {
            content: '';
            width: 20px;
            height: 22px;
            display: block;
            background: url(../../img/common/icon_pdf.png) no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 18px;
            transform: translateY(-50%);
          }
        }
      }
    }

    >li {
      @include mq(no-tablet) {
        border-bottom: 2px solid #E6E6E6;
      }

      +li {
        @include mq(tablet) {
          margin-top: 10px;
        }
      }

      >a {
        font-size: 1.4rem;
        font-weight: bold;
        @include ls(25);
        padding: 16px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        transition: .3s ease color;

        @include mq(tablet) {
          padding: 18px 10px;
          border: 2px solid #CACACA;
          border-radius: 10px;
        }

        &:hover {
          @include mq(no-tablet) {
            color: #0070a2;
          }
        }
      }
    }

    .def {
      @include mq(no-tablet) {
        border-bottom: 1px solid #E6E6E6;
      }

      @include mq(tablet) {
        background: #F4F5F7;
        border-radius: 10px;
        padding: 20px;
      }

      dt {

        /* &.new {
          padding-left: 48px;
          @include mq(tablet) {
            padding-left: 34px;
          }
          img {
            width: 20px;
            position: absolute;
            top: calc(50% - 20px);
            left: 20px;
            @include mq(tablet) {
              left: 0;
            }
          }
        }*/
        a,
        span {
          display: block;
          font-size: 1.4rem;
          font-weight: bold;
          @include ls(50);
          position: relative;
          //cursor: pointer;
          transition: .3s ease color;

          &:hover {
            color: #0070a2;
          }

          @include mq(no-tablet) {
            padding: 19px 44px 19px 19px;

            &:hover {
              color: #0070a2;
            }
          }

          @include mq(tablet) {
            padding-right: 30px;
          }

          /*&:before,
          &:after {
            content: '';
            @include mq(no-tablet) {
              width: 14px;
              height: 2px;
              background: $main-color;
              display: block;
              position: absolute;
              top: 50%;
              right: 20px;
            }
          }*/
          &:before {
            @include mq(tablet) {
              background: $white;
              width: 23px;
              height: 23px;
              border-radius: 50%;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          &:after {
            @include mq(no-tablet) {
              transform: rotate(90deg);
              transition: .3s ease;
            }

            @include mq(tablet) {
              border-right: 2px solid #0070a2;
              border-bottom: 2px solid #0070a2;
              display: block;
              width: 6px;
              height: 6px;
              position: absolute;
              top: 49%;
              right: 8px;
              transform: translateY(-50%) rotate(45deg);
            }
          }

          &.active {
            &:after {
              @include mq(no-tablet) {
                transform: rotate(180deg);
              }

              @include mq(tablet) {
                top: 54%;
                transform: translateY(-50%) rotate(-135deg);
              }
            }
          }

          strong {
            font-size: 1.6rem;
          }

        }
      }

      dd {
        @include mq(no-tablet) {
          background: #F0F0F0;
          padding: 18px 20px;
        }

        >ul {
          @include mq(tablet) {
            margin-top: 20px;
          }
        }

        li {
          @include mq(no-tablet) {
            border-bottom: 1px solid #C2C2C2;
          }

          @include mq(tablet) {
            margin-bottom: 10px;
          }

          &:last-child {
            border-bottom: none;

            @include mq(tablet) {
              margin-bottom: 0;
            }
          }

          a {
            font-size: 1.5rem;
            font-weight: bold;
            padding: 12px 0 12px 25px;
            display: block;
            position: relative;
            transition: .3s ease color;

            &.plan01:hover {
              color: #0E733D;
            }

            &.plan02:hover {
              color: #2252A2;
            }

            &.plan03:hover {
              color: #B3A000;
            }

            &.plan04:hover {
              color: #6A6A6A;
            }

            &.plan05:hover {
              color: #0C3060;
            }

            &.plan06:hover {
              color: #B03009;
            }

            @include mq(tablet) {
              width: 100%;
              border-radius: 10px;
              border-width: 2px;
              border-style: solid;
              padding: 12px 40px 12px 18px;
              background: $white;

              &.plan01 {
                color: #0E733D;
                border-color: #0E733D;
              }

              &.plan02 {
                color: #2252A2;
                border-color: #2252A2;
              }

              &.plan03 {
                color: #B3A000;
                border-color: #B3A000;
              }

              &.plan04 {
                color: #6A6A6A;
                border-color: #6A6A6A;
              }

              &.plan05 {
                color: #0C3060;
                border-color: #0C3060;
              }

              &.plan06 {
                color: #B03009;
                border-color: #B03009;
              }
            }

            img {
              width: 15px;
              height: 15px;
              position: absolute;
              top: calc(50% - 7px);

              @include mq(no-tablet) {
                left: 0;
              }

              @include mq(tablet) {
                right: 15px;
              }
            }

            span {
              font-size: 1.3rem;
              margin-left: 2px;
            }
          }
        }
      }
    }
  }

  .btn {
    +.btn {
      margin-top: 5px;

      @include mq(tablet) {
        margin-top: 10px;
      }
    }

    &._timetable {
      font-size: 1.5rem;
      font-weight: bold;
      @include ls(50);
      border-radius: 5px;
      border: 2px solid #DBDBDB;
      background: $white;
      width: 100%;
      height: 40px;
      padding-top: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: .3s ease background;

      @include mq(tablet) {
        height: 50px;
        margin-top: 10px;
        padding: 0 18px;
        justify-content: flex-start;
        position: relative;

        &:before,
        &:after {
          content: '';
          width: 11px;
          height: 2px;
          background: $main-color;
          display: block;
          position: absolute;
          top: calc(50% - 2px);
          right: 15px;
        }

        &:after {
          transform: rotate(90deg);
        }
      }

      &:hover {
        background: #f1f1f1;
      }
    }

    &._rsv {
      a {
        color: $white;
        background: #CC2A0A;

        &:before {
          width: 23px;
          height: 23px;
          background: url(../../img/common/icon_ticket02.svg) no-repeat;
          background-size: contain;
        }

        &:hover {
          background: #a31b00;
        }
      }
    }

    &.charter{
      margin-top: 5px;

      @include mq(tablet) {
        margin-top: 10px;
      }
    }

    &._rsvd {
      a {
        color: #006FA2;
        border: 2px solid #006FA2;
        background: $white;

        &:before {
          width: 16px;
          height: 18px;
          background: url(../../img/common/icon_login_blue.png) no-repeat;
          background-size: contain;
        }

        &:hover {
          background: #e4f0f5;
        }
      }
    }

    &._login {
      margin-top: 15px;

      @include mq(no-tablet) {
        padding: 0 20px;
      }

      @include mq(tablet) {
        margin-top: 10px;
      }

      a {
        color: #006FA2;
        border: 2px solid #006FA2;
        background: $white;
        height: auto;
        padding: 6px 34px;
        line-height: 1.3;
        padding: 7px 30px;

        span {
          position: relative;

          &:before {
            content: '';
            display: block;
            width: 16px;
            height: 18px;
            background: url(../../img/common/icon_login_blue.png) no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: -26px;
            transform: translateY(-50%);
          }
        }

        small {
          display: block;
        }

        @include mq(tablet) {
          border-radius: 10px;
          height: 60px;
        }

        &:hover {
          background: #e4f0f5;
        }
      }
    }

    a {
      font-size: 1.5rem;
      font-weight: bold;
      @include ls(50);
      border-radius: 5px;
      width: 100%;
      height: 40px;
      padding-top: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq(tablet) {
        height: 50px;
      }

      &:before {
        content: '';
        display: block;
        margin: -1px 5px 0 0;
      }
    }
  }
}