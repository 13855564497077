.btn-spmenu {
  $this: &;
  @media screen and (min-width: 980px) {
    &:not(._pc) {
      display: none;
    }
  }
  font-family: $font-roboto;
  color: $white;
  width: $sp-header-height-rsv;
  height: $sp-header-height-rsv;
  background: $main-color;
  margin-left: auto;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  &.active {
    .bar-wrap {
      > span {
        &:nth-child(1) {
          width: 28px;
          top: 9px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: scale(0);
        }
        &:nth-child(3) {
          width: 28px;
          top: 9px;
          transform: rotate(-45deg);
        }
      }
    }
  }
  .bar-wrap {
    width: 28px;
    height: 19px;
    position: relative;
    #{$this}.active & {
      width: 27px;
      height: 21px;
    }
    > span {
      width: 28px;
      height: 1px;
      border-radius: 1.5px;
      background: $white;
      position: absolute;
      left: 0;
      @include transition;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 9px;
      }
      &:nth-child(3) {
        bottom: 0;
      }
    }
  }
  p {
    margin-top: 7px;
  }
}
