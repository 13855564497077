.ganshokun {
  &-top {
    width: 1110px;
    margin: 0 auto;
    @include mq {
      width: 100%;
    }
    &-mainimg {
      width: 100%;
      max-width: 1010px;
      margin: 80px auto;
    }
    &-hub {
      display: flex;
      width: 1010px;
      margin: 0 auto;
      @include mq {
        width: calc(100% - 20px);
        margin: 20px auto;
        flex-direction: column;
      }
      >a {
        position: relative;
        font-weight: bold;
        width: calc(100%/3);
        height: 366px;
        color: $white;
        padding: 38.4px 0 0 36.4px;
        @include mq {
          width: 100%;
          padding: 10px 0 0 10px;
          height: 70px;
        }
        .title {
          font-size: 3.6rem;
          @include mq {
            font-size: 1.6rem;
          }
        }
        .border {
          margin: 13px 0 23px 0;
          width: 44px;
          height: 1px;
          background: $white;
        }
        .desc {
          font-size: 1.6rem;
          @include mq {
            font-size: 1.2rem;
            display: none;
          }
        }
        .arrowbox {
          position: absolute;
          bottom: 15px;
          right: 15px;
          border: 2px solid $white;
          @include mq {
            bottom: 12px;
          }
          >.arrow {
            width: 20px;
            height: 20px;
            margin: 15px;
            @include mq {
              margin: 10px;
            }
          }
        }
        &:nth-child(1) {
          background: #2D79CF;
        }
        &:nth-child(2) {
          background: #0152AE;
        }
        &:nth-child(3) {
          background: #00418A;
        }
      }
    }
    .sec01 {
      margin-top: 74px;
      @include mq {
        margin-top: 50px;
      }
    }
    .sec02 {
      width: 1010px;
      margin: 74px auto 0;
      @include mq {
        width: 100%;
        margin-top: 50px;
      }
    }
    .sec03 {
      margin-top: 74px;
      @include mq {
        margin-top: 50px;
      }
    }
    &-caption {
      text-align: center;
      font-size: 1.6rem;
      margin-bottom: 55px;
      @include mq {
        margin: 0 10px 55px;
      }
    }
    &-plan {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 359px;
      width: 100%;
      color: $white;
      background: url(../../img/ganshokun_ch/sec01_bg.jpg);
      .ttl06 {
        margin-bottom: 35px;
        font-weight: bold;
      }
      .btn._05 {
        @include mq {
          padding: 0 15px;
        }
        a {
          background: $white;
          padding: 21px 50px;
          color: $text-color;
          @include mq {
            font-size: 1.4rem;
            min-width: unset;
            padding: 21px 0;
            width: 100%;
          }
        }
      }
    }
    &-scale-garally {
      display: flex;
      flex-wrap: wrap;
      margin: 30px auto 0;
      @include mq {
        flex-direction: column;
        margin: 10px auto 0;
      }
      .box {
        width: calc(50% - 20px);
        margin-bottom: 42px;
        @include mq {
          width: calc(100% - 20px);
          margin: 42px auto 0;
        }
        @include mq(pc) {
          &:nth-child(2n + 1) {
            margin-right: 20px;
          }
          &:nth-child(2n) {
            margin-left: 20px;
          }
        }
        .video {
          position: relative;
          &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
            /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
          }
          .notify {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2rem;
            font-weight: bold;
          }
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .caption {
          background: url(../../../img/ganshokun_ch/box-bg.png) center no-repeat;
          background-size: cover;
          padding: 22px 0 22px 30px;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    &-view {
      height: 410px;
      background: url(../../../img/ganshokun_ch/view_bg.png) bottom no-repeat;
      @include mq {
        height: 310px;
      }
      .view-content {
        width: 465px;
        min-height: 288px;
        margin: 0 25px;
      }
      &.slider img {
        width: 465px;
        height: 288px;
        object-fit: cover;
        @include mq {
          width: 240px;
          height: 180px;
        }
      }
      .view-title {
        color: $white;
        font-size: 1.8rem;
      }
      .slick-dots {
        position: absolute;
        bottom: 50px;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        li {
          position: relative;
          display: inline-block;
          height: 20px;
          width: 20px;
          padding: 0;
          cursor: pointer;
          button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
              outline: none;
              &:before {
                opacity: 1;
              }
            }
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              content: "\2022";
              width: 20px;
              height: 20px;
              font-family: "slick";
              font-size: 20px;
              line-height: 20px;
              text-align: center;
              color: $white;
            }
          }
          &.slick-active button:before {
            color: #0152AE;
            font-size: 32px;
          }
        }
      }
    }
    &-about {
      position: relative;
      background: url(../../img/ganshokun_ch/gansho_bg01.jpg) no-repeat;
      background-size: cover;
      background-position: center;
      height: 1569px;
      margin-bottom: 126px;
      @include mq {
        height: auto;
      }
      &::after {
        position: absolute;
        top: 90%;
        left: 12%;
        transform: translate(-50%, 0);
        width: 169px;
        height: 163px;
        content: "";
        background: url(../../../img/ganshokun_ch/ganshokun03.png) no-repeat;
        background-size: contain;
        background-position: center;
        @include mq {
          top: 100%;
          left: 10px;
          transform: translate(0, -100%);
          width: 69px;
          height: 66.5px;
        }
      }
      &-title {
        padding-top: 95px;
        text-align: center;
        color: $white;
        display: flex;
        align-items: baseline;
        justify-content: center;
        font-size: 5rem;
        font-weight: bold;
        gap: 13.5px;
        @include mq {
          padding-top: 55px;
          font-size: 2rem;
        }
        img {
          width: 364px;
          height: 132px;
          top: 95px;
          @include mq {
            width: 250px;
            height: auto;
          }
        }
      }
      &-text {
        display: block;
        width: 255px;
        height: 303px;
        margin: 40px auto 0;
        @include mq {
          width: 185px;
          height: auto;
        }
      }
      &-figure {
        display: block;
        width: 488px;
        height: 202px;
        margin: 40px auto 0;
        @include mq {
          width: 240px;
          height: auto;
          margin: 80px auto 0;
        }
      }
      &-fish {
        position: absolute;
        &._01 {
          width: 217px;
          height: 114px;
          top: 514px;
          left: 130px;
          @include mq {
            width: 110px;
            height: auto;
            top: 244px;
            left: 280px;
          }
        }
        &._02 {
          width: 197px;
          height: 127px;
          top: 324px;
          left: 826px;
          @include mq {
            width: 127px;
            height: auto;
            top: 465px;
            left: -24px;
          }
        }
      }
      &-secret {
        position: absolute;
        background: #00BFFF;
        font-size: 2rem;
        width: 190px;
        height: 190px;
        top: 528px;
        right: 145px;
        border-radius: 50%;
        text-align: center;
        @include mq {
          width: 100px;
          height: 100px;
          font-size: 1.2rem;
          top: 458px;
          right: 15px;
        }
        div {
          color: $white;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          font-weight: Bold;
        }
      }
      .ttl05 {
        position: relative;
        color: $white;
        padding-top: 74px;
        font-size: 3rem;
        @include mq {
          font-size: 1.8rem;
        }
        &::after {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translate(-50%, 0);
          width: 100%;
          height: 30px;
          content: "";
          background: url(../../../img/ganshokun_ch/gansho_arrow01.png) no-repeat;
          background-position: center;
          @include mq {
            background-size: contain;
            width: 90%;
            height: 30px;
          }
        }
      }
      &-garally {
        display: flex;
        flex-wrap: wrap;
        margin: 30px 50px;
        @include mq {
          margin: 30px 20px;
          flex-direction: column;
        }
        .box {
          width: calc(50% - 20px);
          margin: 40px 0;
          @include mq {
            width: 100%;
            margin: 10px 0;
          }
          @include mq(pc) {
            &:nth-child(2n + 1) {
              margin-right: 20px;
            }
            &:nth-child(2n) {
              margin-left: 20px;
            }
          }
          .video {
            position: relative;
            &:before {
              content: "";
              display: block;
              padding-top: 56.25%;
              /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
            }
            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
          .caption {
            display: flex;
            div {
              font-size: 2rem;
              font-weight: bold;
              @include mq {
                font-size: 1.4rem;
              }
            }
            div:first-child {
              background: #333;
              color: $white;
              width: 25%;
              padding: 22px 0 22px 0;
              text-align: center;
              @include mq {
                padding: 12px 0 12px 0;
              }
            }
            div:nth-child(2) {
              padding: 22px 0 22px 30px;
              background: #d3d3d3;
              width: 75%;
              @include mq {
                padding: 12px 0 12px 10px;
              }
            }
          }
        }
      }
      .btn._05 {
        padding: 0 15px;
        @include mq {
          padding: 0 15px 80px;
        }
        a {
          background: $white;
          padding: 21px 50px;
          color: $text-color;
          @include mq {
            font-size: 1.4rem;
            min-width: unset;
            padding: 21px 0;
            width: 100%;
          }
        }
      }
    }
    &-meet {
      position: relative;
      background: url(../../img/ganshokun_ch/gansho_bg02.jpg) no-repeat;
      background-size: cover;
      height: 686px;
      margin-bottom: 80px;
      @include mq {
        height: auto;
      }
      &::after {
        position: absolute;
        top: 100%;
        right: 30px;
        transform: translate(0, -50%);
        width: 169px;
        height: 163px;
        content: "";
        background: url(../../../img/ganshokun_ch/ganshokun04.png) no-repeat;
        background-size: contain;
        background-position: center;
        @include mq {
          width: 80px;
          height: 77px;
          transform: translate(0, -100%);
        }
      }
      &-balloon {
        width: 510px;
        height: auto;
        @include mq {
          width: 100%;
        }
      }
      &-title {
        top: -75px;
        left: 50%;
        transform: translate(-50%, 0);
        position: absolute;
        text-align: center;
        width: 100%;
        font-size: 50px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        @include mq {
          top: -30px;
        }
        >img {
          width: 364px;
          height: auto;
          @include mq {
            width: 170px;
          }
        }
      }
      &-desc {
        padding: 95px 0 35px;
        text-align: center;
        font-size: 2rem;
        @include mq {
          padding: 60px 0 35px;
          font-size: 1.4rem;
        }
      }
      &-garally {
        display: flex;
        gap: 50px;
        justify-content: center;
        flex-direction: row;
        @include mq {
          gap: 20px;
          flex-direction: column;
        }
        .video-box {
          @include mq(pc) {
            img {
              margin-left: -20px;
            }
          }
          .title {
            margin-top: 5px;
            text-align: center;
            font-size: 2rem;
            font-weight: bold;
          }
        }
        .video {
          position: relative;
          width: 506px;
          @include mq {
            width: calc(100% - 20px);
            margin: 0 auto;
          }
          &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
            /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
          }
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .index {
          width: 431px;
          padding: 37px 10px 37px 32px;
          border-radius: 30px;
          border: 2px solid $white;
          @include mq {
            width: calc(100% - 20px);
            padding: 20px 10px 20px 17px;
            border-radius: 20px;
            margin: 0 10px 40px;
          }
          ul {
            height: 349px;
            overflow-x: hidden;
            overflow-y: scroll;
            @include mq {
              height: 120px;
            }
          }
          li {
            margin-bottom: 14px;
            font-size: 1.4rem;
            @include mq {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    &-keyword {
      position: relative;
      background-color: #FFF5F5;
      text-align: center;
      padding: 75px 50px 80px;
      margin-bottom: 92px;
      @include mq {
        margin-bottom: 22px;
        padding: 25px 50px 100px;
      }
      &-title {
        font-size: 30px;
      }
      &-img {
        margin-top: 30px;
        width: 465px;
        height: 465px;
        @include mq {
          margin-top: 50px;
          width: 200px;
          height: auto;
        }
      }
      &-wordlist {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 20px;
        font-weight: bold;
        @include mq {
          gap: 10px;
        }
        &._l {
          top: 190px;
          left: 50px;
          align-items: flex-start;
          @include mq {
            top: 90px;
            left: 20px;
          }
        }
        &._r {
          top: 270px;
          right: 50px;
          align-items: flex-end;
          @include mq {
            top: 270px;
            right: 20px;
          }
        }
        div {
          background-color: #333333;
          color: $white;
          padding: 0px 10px;
          @include mq(pc) {
            &:hover {
              padding: 5px 15px;
              transition-duration: 100ms;
            }
            &._sm {
              font-size: 2rem;
              &:hover {
                font-size: 2.2rem;
                margin: -5px -10px -8px -5px;
              }
            }
            &._md {
              font-size: 2.8rem;
              &:hover {
                font-size: 3.2rem;
                margin: -5px -10px -11px -5px;
              }
            }
            &._lg { 
              font-size: 3.8rem;
              &:hover {
                margin: -5px -10px -11px -5px;
                font-size: 4.2rem;
              }
            }
          }
          @include mq {
            &._sm {
              font-size: 1.2rem;
            }
            &._md {
              font-size: 1.4rem;
            }
            &._lg {
              font-size: 1.8rem;
            }
          }
        }
      }
      .hide {
        display: none;
      }
    }
    &-tips {
      &-title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 50px;
        span {
          color: $red;
        }
      }
      .box {
        width: 486px;
        margin: 0 auto 100px;
        @include mq {
          width: calc(100% - 20px);
          margin: 0 auto;
        }
        .video {
          position: relative;
          &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
            /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
          }
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .caption {
          background: url(../../img/ganshokun_ch/box-bg.png) center no-repeat;
          background-size: cover;
          padding: 22px 0 22px 30px;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    &-sns {
      position: relative;
      background: url(../../img/ganshokun_ch/SNS-bg.png) center no-repeat;
      background-size: cover;
      height: 396px;
      @include mq {
        height: 230px;
        margin-top: 30px;
      }
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(0, -100%);
        width: 169px;
        height: 163px;
        content: "";
        background: url(../../../img/ganshokun_ch/ganshokun06.png) no-repeat;
        background-size: contain;
        background-position: center;
        @include mq {
          width: 70px;
          height: 67px;
        }
      }
      &-title {
        position: relative;
        padding: 76px 0 50px;
        text-align: center;
        font-size: 3rem;
        @include mq {
          font-size: 1.4rem;
          padding: 30px 0 0;
          margin-bottom: 30px;
        }
        &::after {
          position: absolute;
          top: 130px;
          left: 50%;
          transform: translate(-50%, 0);
          width: 100%;
          height: 30px;
          content: "";
          background: url(../../img/ganshokun_ch/gansho_arrow02.png) no-repeat;
          background-position: center;
          @include mq {
            background-size: contain;
            top: 100%;
            width: 280px;
          }
        }
      }
      &-box {
        width: 450px;
        background: $white;
        display: flex;
        margin: 0 auto;
        padding: 40px 0;
        justify-content: space-around;
        @include mq {
          width: 270px;
          padding: 20px 0;
        }
        img {
          height: 75px;
          width: auto;
          @include mq {
            height: 45px;
          }
        }
      }
    }
  }
}
#p-ganshokun_ch  {
  
  .modaal-content-container {
      display: flex;
      padding: 30px;
      justify-content: space-between;
      @include mq {
        padding: 10px;
        flex-direction: column;
      }
      .mdlimg {
        width: 45%;
        @include mq {
          width: 100%;
        }
        img {
          width: 100%;
          height: auto;
          max-height: 100%;
          max-width: 100%;
          &:nth-child(3){
            width: 230px;
          }
        }
      }
      >div {
        line-height: 1.5;
        font-size: 1.6rem;
        padding: 10px;
        width: 50%;
        @include mq {
          width: 100%;
        }
        .gansho_tag {
          border-radius: 2px;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          padding: 8px 10px;
          display: inline-block;
          background: $black02;
          @include mq {
            font-size: 1.6rem;
          }
        }
        .mdltext {
          margin-top: 15px;
          .btn_link {
            width: 250px;
            font-size: 12px;
            text-align: center;
            margin-top: 15px;
            display: block;
            @include mq {
              width: 100%;
              margin-top: 30px;
            }
            a {
              font-weight: bold;
              margin: 0 5px;
              padding: 10px 20px;
              border: 1px solid #000;
              display: inline-block;
              background: #fff;
            }
          }
          .img_link {
            width: 250px;
            margin-top: 15px;
            display: block;
            a {
              display: inline-block;
            }
          }
        }

      }
    }
}