.museum {
  &-main {
    position: relative;
    margin: 60px auto 0;
    width: 670px;
    height: 444px;
    @include mq {
      width: calc(100% - 20px);
      margin:  0 auto;
      height: auto;
    }
    img {
      width: 100%;
      height: auto;
    }
    .show_detail {
      position: absolute;
      width: 190px;
      height: 190px;
      top: 0;
      right: 0;
      transform: translate(10%, -20%);
      @include mq {
        width: 30%;
        height: auto;
        right: 10px;
      }
    }
  }
  &-top {
    width: 1010px;
    margin: 0 auto;
    @include mq {
      width: 100%;
    }
    &-desc {
      text-align: center;
      @include mq {
        margin: auto 10px;
      }
    }
    &-floor {
      counter-reset: item;
      padding-bottom: 30px;
      border-bottom: 3px solid #e1e1e1;
      &-title {
        position: relative;
        padding: 0 0 14px 37px;
        margin: 40px 0;
        font-size: 2rem;
        font-weight: bold;
        border-bottom: 3px solid #e1e1e1;
        @include mq {
          padding: 0 0 4px 20px;
          margin: 40px 10px;
        }
        &::before {
          position: absolute;
          content: "";
          width: 14px;
          height: 14px;
          background: #e1e1e1;
          border-radius: 50%;
          left: 0;
          top: 15px;
          transform: translate(0, -50%);
        }
      }
      &-detail {
        width: 951px;
        margin: 30px auto 0;
        @include mq {
          width: calc(100% - 20px);
        }
        &-map {
          width: 951px;
          height: 637px;
          position: relative;
          @include mq {
            width: 100%;
            height: auto;
          }
          a {
            display: block;
            position: absolute;
            height: 60px;
            width: 60px;
            border-radius: 50%;
            content: "";
            @include mq(pc) {
              &.map1 {
                &._1 {
                  top: 85px;
                  left: 600px;
                }
                &._2 {
                  top: 182px;
                  left: 765px;
                }
                &._3 {
                  top: 227px;
                  left: 636px;
                }
                &._4 {
                  top: 448px;
                  left: 400px;
                }
                &._5 {
                  top: 277px;
                  left: 100px;
                }
                &._6 {
                  top: 225px;
                  left: 245px;
                }
                &._7 {
                  top: 284px;
                  left: 576px;
                }
              }
              &.map2 {
                &._1 {
                  top: 183px;
                  left: 435px;
                }
                &._2 {
                  top: 77px;
                  left: 623px;
                }
                &._3 {
                  top: 153px;
                  left: 822px;
                }
                &._4 {
                  top: 232px;
                  left: 360px;
                }
                &._5 {
                  top: 256px;
                  left: 280px;
                }
                &._6 {
                  top: 150px;
                  left: 232px;
                }
                &._7 {
                  top: 355px;
                  left: 461px;
                }
                &._8 {
                  top: 460px;
                  left: 487px;
                }
                &._9 {
                  top: 432px;
                  left: 351px;
                }
                &._10 {
                  top: 348px;
                  left: 236px;
                }
                &._11 {
                  top: 273px;
                  left: 166px;
                }
                &._12 {
                  top: 308px;
                  left: 64px;
                }
                &._13 {
                  top: 402px;
                  left: 170px;
                }
                &._14 {
                  top: 526px;
                  left: 323px;
                }
              }
              &.map3 {
                &._1 {
                  top: 196px;
                  left: 623px;
                }
                &._2 {
                  top: 154px;
                  left: 359px;
                }
                &._3 {
                  top: 40px;
                  left: 485px;
                }
                &._4 {
                  top: 287px;
                  left: 358px;
                }
                &._5 {
                  top: 177px;
                  left: 178px;
                }
                &._6 {
                  top: 322px;
                  left: 758px;
                }
                &._7 {
                  top: 289px;
                  left: 226px;
                }
                &._8 {
                  top: 186px;
                  left: 57px;
                }
                &._9 {
                  top: 356px;
                  left: 200px;
                }
              }
            }
          }
        }
        .popup {
          @include mq() {
            margin: 15px 0;
            h3 {
              margin-bottom: 5px;
            }
            .txt {
              margin-top: 10px;
            }
          }
          @include mq(pc) {
            display: none;
          }
        }
        &-areablock {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          li {
            position: relative;
            width: 50%;
            padding: 21.5px 0 16.5px 55px;
            @include mq {
              padding: 10px 0 10px 35px;
            }
          }
          li:before {
            content: "";
            position: absolute;
            width: 40px;
            height: 40px;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            border-radius: 20px;
            background-color: $black02;
            @include mq {
              width: 30px;
              height: 30px;
            }
          }
          li::after {
            position: absolute;
            left: 20px;
            top: 50%;
            font-size: 2rem;
            transform: translate(-50%, -50%);
            counter-increment: item;
            content: counter(item);
            color: $white;
            font-weight: bold;
            @include mq {
              left: 16px;
              font-size: 1.6rem;
            }
          }
        }
      }
      &-route {
        width: 951px;
        margin: 80px auto 0;
        @include mq {
          width: calc(100% - 20px);
          margin: 20px auto 0;
        }
        .logo {
          font-family: '游ゴシック体', 'Yu Gothic', YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
          font-size: 22px;
          font-weight: normal;
          letter-spacing: 3px;
          @include mq(pc) {
            float: left;
          }
          @include mq() {
            display: block;
            text-align: center;
          }
          span {
            font-size: 12px;
            display: block;
          }
        }
        &-btn._03 {
          margin-top: 20px;
          @include mq(pc) {
            justify-content: flex-end;
            margin-top: 40px;
          }
          a {
            font-size: 2rem;
            background-color: $black02;
            color: $white;
            @include mq(pc) {
              width: 425px;
              height: 70px;
            }
            &:hover {
              background-color: $black01;
            }
          }
        }
        &-detail {
          dl {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 15px 15px;
            font-size: 1.6rem;
            margin: 30px 0;
            @include mq {
              gap: 10px 5px;
              margin: 30px 0 10px;
            }
            dt {
              display: inline-block;
              text-align: center;
              background-color: #e1e1e1;
              min-width: 72px;
              padding: 7px 20px 5px 21px;
            }
            dd {
              display: inline-block;
              &._fw {
                width: calc(100% - 88px);
              }
              @include mq {
                width: calc(100% - 120px);
              }
            }
          }
          &-gmap {
            height: 554px;
            @include mq {
              height: 554px;
            }
          }
        }
      }
    }
  }
  &-holo {
    width: 1010px;
    margin: 0 auto;
    @include mq {
      width: 100%;
      margin: 0 auto;
    }
    .video {
      position: relative;
      &:before {
        content: "";
        display: block;
        padding-top: 56.25%;
        /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-topic {
      margin-top: 55px;
      @include mq {
        width: calc(100% - 20px);
        margin: 30px auto 0;
      }
      &._border {
        border-bottom: 1px solid #333;
      }
      .desc {
        letter-spacing: 0.75px;
        line-height: 24px;
        text-align: left;
        margin: 20px auto 22px;
        text-align: center;
      }
      .underline {
        background: linear-gradient(transparent 60%, #FFC000 60%);
      }
      .red {
        color: $main-color02;
      }
      .blue {
        color: #0049AD;
      }
      .ttl06 {
        font-weight: bold;
      }
      .ttl05 {
        position: relative;
        margin-bottom: 10px;
        @include mq(pc) {
          content: "";
        }
        &.sideline::before,
        &.sideline::after {
          width: 165px;
          height: 5px;
          top: 50%;
          transform: translate(0, -50%);
          display: inline-block;
          background-color: black;
          position: absolute;
        }
        &.red {
          &.sideline::before,
          &.sideline::after {
            background-color: $main-color02;
          }
        }
        &.sideline::before {
          left: 0;
        }
        &.sideline::after {
          right: 0;
        }
      }
      .btn._05 {
        a {
          background-color: $black01;
          @include mq {
            min-width: unset;
            font-size: 1.6rem;
          }
        }
      }
      &-image {
        width: 700px;
        height: auto;
        margin: 0 auto;
      }
      &-subtitle {
        padding: 26.7px 0;
        margin: 0 auto;
        text-align: center;
        letter-spacing: 0.75px;
        font-size: 2rem;
        font-weight: bold;
        background: url(../../img/museum/gold_bg.jpg) center no-repeat;
        @include mq {
          font-size: 1.4rem;
        }
      }
      &-about {
        width: 700px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        margin: 30px auto 0;
        gap: 10px 7px;
        letter-spacing: 0.75px;
        line-height: 24px;
        @include mq {
          gap: 7px 7px;
          width: 100%;
        }
        img:nth-child(1) {
          width: 100%;
          height: auto;
        }
        img:nth-child(n + 2) {
          width: 346px;
          height: auto;
          @include mq {
            width: calc(50% - 4px);
          }
        }
      }
      &-exp {
        width: 700px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        margin: 30px auto 0;
        gap: 30px 20px;
        letter-spacing: 0.75px;
        line-height: 24px;
        @include mq {
          justify-content: center;
          gap: 7px 7px;
          width: 100%;
        }
        div:nth-child(1) {
          width: 100%;
        }
        div:nth-child(2) {
          width: 370px;
        }
        div:nth-child(3) {
          width: 310px;
        }
      }
      &-sst {
        margin-top: 62px;
        @include mq {
          overflow: hidden;
        }
        img {
          @include mq {
            height: 55px;
            width: auto;
          }
        }
      }
      &-prize {
        margin-bottom: 19px;
        position: relative;
        display: flex;
        align-items: center;
        &._01 {
          border: 3px solid #53C3EC;
          height: 165px;
        }
        &._02 {
          border: 3px solid #5390EC;
          height: 244px;
        }
        .prize {
          &-icon {
            width: 155px;
            @include mq {
              width: 60px;
            }
            img {
              width: 115px;
              height: 115px;
              margin: 0 20px;
              @include mq {
                width: 45px;
                height: 45px;
              }
            }
          }
          &-title {
            margin: 0 20px;
            dt {
              color: #257FED;
              font-weight: bold;
              font-size: 2rem;
              letter-spacing: 0.75px;
              @include mq {
                font-size: 1.6rem;
              }
            }
            dd {
              font-size: 1.6rem;
              @include mq {
                font-size: 1.2rem;
              }
              span {
                font-weight: bold;
              }
              .btn._01 {
                @include mq {
                  margin-top: 5px;
                }
                * {
                  border: 2px solid #5390EC;
                  justify-content: flex-start;
                  color: $black01;
                  &:hover {
                    background-color: #e4eeff;
                  }
                }
              }
            }
          }
          &-image {
            position: absolute;
            right: 200px;
            transform: translate(50%, 0);
            @include mq {
              display: none;
            }
          }
        }
      }
      &-link {}
      &-tel {
        margin-top: 22px;
        text-align: center;
        span {
          font-weight: bold;
          font-size: 3.8rem;
        }
      }
      &-list {
        letter-spacing: 0.75px;
        margin-bottom: 35px;
        ul {
          margin-bottom: 15px;
        }
        li {
          font-style: 1.6rem;
          position: relative;
          margin: 4px 0;
          padding-left: 20px;
          &::before {
            position: absolute;
            content: "";
            background-color: $black01;
            width: 10px;
            height: 10px;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            border-radius: 5px;
          }
        }
      }
      &-plan {
        &-title {
          background: url(../../img/museum/st02_bg.jpg) center no-repeat;
          padding: 111px 0 104px;
          margin: 0 auto;
          text-align: center;
          font-size: 2.8rem;
          font-weight: bold;
          color: $white;
          @include mq {
            font-size: 1.8rem;
          }
        }
        &-desc {
          margin: 25px auto 48px;
        }
        &-date {
          margin: 21px 0 11px;
        }
        &-table {
          @include mq {
            overflow: scroll;
          }
          .table01 {
            margin: 0 auto;
            width: 700px;
            th {
              color: #fff;
              background: #0049ad;
            }
            td {
              background: #eee;
            }
            th, td {
              padding: 10px 15px;
              border: 3px solid #fff;
              position: relative;
              text-align: center;
            }
            .total {
              color: #f00;
              span {
                font-size: 14px;
              }
            }
            .plus::after {
              position: absolute;
              content: "";
              width: 26px;
              height: 26px;
              right: 0;
              top: 50%;
              transform: translate(50%, -50%);
              z-index: 1;
              background: url(../../img/museum/iocn_plus.png);
            }
            .equal::after {
              position: absolute;
              content: "";
              width: 26px;
              height: 26px;
              right: 0;
              top: 50%;
              transform: translate(50%, -50%);
              z-index: 1;
              background: url(../../img/museum/icon_equal.png);
            }
          }
        }
        .caution {
          width: 700px;
          margin: 34px auto 0;
          color: $red;
          @include mq {
            width: 100%;
          }
        }
      }
      &-schedule {
        margin-bottom: 40px;
        .plan_att {
          text-align: center;
          max-width: 600px;
          margin: 0 auto;

          dl {
            margin: 30px auto 0;
            padding: 10px 40px 10px;
            border: 4px solid #f00;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            width: 100%;
            max-width: 600px;
            justify-content: center;
            @include mq {
              padding: 10px 10px 10px;
              display: flex;
              flex-direction: column;
            }
          }
          dt {
            font-size: 16px;
            margin: 0;
            padding: 10px 20px 10px 0;
            position: relative;
            @include mq {
              padding:10px 0 0px 0;
              position: relative;
            }
            i {
              padding-right: 5px;
            }
            &.am {
              font-weight: bold;
              color: #59b7ff;
            }
            &.pm {
              font-weight: bold;
              color: #ffa200;
            }
            &:first-child {
              @include mq(pc) {
                border-bottom: 1px dotted #ccc;
              }
            }
          }
          dd {
            font-size: 16px;
            font-weight: bold;
            color: #f00;
            margin: 0;
            width: 70%;
            padding: 10px 0 10px 0px;
            border-bottom: 1px dotted #ccc;
            @include mq {
              width: unset;
              font-size: 15px;
              padding: 10px 0 10px 0;
            }
            i {
              font-size: 14px;
              color: #666;
              padding-right: 20px;
              @include mq {
                padding-right: 9px;
              }
            }
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
#p-museum {
  .modaal-container {
    width: 800px;
    text-align: center;
    padding: 10px 15px 10px 15px;
    border: 1px solid #536376;
    border-radius: 4px;
    width: 632px;
    height: auto;
    z-index: 10;
    z-index: 10001;
    top: 50%;
    left: 50%;
    background: #eef2f7;
    background: white;
    position: fixed;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    @include mq() {
      width: calc(100% - 20px);
    }
    .modaal-close {
      display: none;
    }
    .modaal-content-container {
      padding: 10px;
    }
    h3 {
      line-height: 1.4;
      text-align: left;
      text-indent: -2em;
      margin-bottom: 15px;
      padding-left: 2em;
      padding-right: 40px;
      font-size: 1.8rem;
      div {
        display: inline;
        font-size: 18px;
        font-weight: bold;
        color: white;
        margin-right: 0.5em;
        padding: 5px 10px;
        background: black;
      }
    }
    .photo {
      text-align: center;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .txt {
      text-align: left;
      margin-bottom: 25px;
      overflow: hidden;
      font-size: 1.6rem;
    }
  }
}