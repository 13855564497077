

/*

#p-ticket {
  .page {
    padding: 0 0 0;
  }
  .wrapper {
    padding-top: 0;
  }
  .GC {
    @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
    .page {
      padding-bottom: 0;
    }
    .ticket {
      border: 1.12px solid $super;
      display: flex;
      width: 244px;
      height: 156px;
      page-break-after: always;
      &-title {
        font-family: 'Arial Narrow', Arial, sans-serif;
        color: $white;
        font-size: 1.34rem;
        letter-spacing: 0.11em;
        padding: 0 7.84px;
        text-align: center;
        background-color: $super;
        border-left: 2px solid $white;
        text-orientation: sideways;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
      }
      &-desc {
        border-left: 2px solid $super;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        >* {
          margin: 2.24px;
        }
        &-date {
          display: flex;
          height: 35.8px;
          align-items: center;
          justify-content: space-around;
          .date {
            &_1 {
              font-size: 1.68rem;
              font-family: 'Oswald', sans-serif;
              letter-spacing: 0.12em;
              display: flex;
              align-items: center;
              .dow {
                font-size: 0.25rem;
              }
            }
            &_2 {
              font-size: 1.34rem;
              font-weight: bold;
              color: $white;
              background-color: $super;
              padding: 0 10px;
              font-family: 'Arial', Arial, sans-serif;
            }
          }
        }
        &-ref {
          display: flex;
          height: 49.7px;
          border-top: 1.12px solid $super;
          border-bottom: 1.12px solid $super;
          .lead {
            margin: 2.24px 0;
            font-weight: bold;
            font-size: 0.67rem;
            width: 26.8px;
            writing-mode: vertical-lr;
            color: $super;
            letter-spacing: 0.05rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1.12px solid;
          }
          .caps {
            height: 43px;
            margin: auto 0 auto 1.12px;
            padding-left: 11.2px;
            border-left: 1.12px solid $super;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .Number {
              font-size: 1.8rem;
              line-height: 1.8rem;
              font-family: 'Oswald', sans-serif;
              letter-spacing: 0.12em;
            }
            .option {
              font-size: 0.67rem;
              color: $super;
              font-weight: bold;
            }
          }
        }
        &-seat {
          display: flex;
          height: 49.7px;
          .lead {
            margin: 2.24px 0;
            font-weight: bold;
            font-size: 0.67rem;
            width: 26.8px;
            height: 45px;
            writing-mode: vertical-lr;
            color: $super;
            letter-spacing: 0.05rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1.12px solid;
          }
          .caps {
            margin: auto 0 auto 1.12px;
            padding-left: 11.2px;
            border-left: 1.12px solid $super;
            position: relative;
            fill: $black01;
            height: 45px;
            .pos {
              font-family: 'Arial', Arial, sans-serif;
              font-size: 3.8rem;
              line-height: 4.48rem;
              font-weight: bold;
              color: $super;
            }
            .stamp {
              position: absolute;
              top: 0;
              right: -70px;
              width: 54px;
              height: auto;
              svg {
                width: 100%;
                height: 40px;
              }
            }
          }
        }
      }
    }
    .jp {
      .ticket {
        &-desc {
          &-ref {
            .lead {}
          }
          &-seat {
            .lead {}
          }
        }
      }
    }
    .en {
      .ticket {
        &-desc {
          &-ref {
            .lead {
              .text {
                text-orientation: sideways;
                transform: rotate(180deg) scale(0.7);
                font-size: 1rem;
                line-height: 1rem;
              }
            }
          }
          &-seat {
            .lead {
              .text {
                text-orientation: sideways;
                transform: rotate(180deg) scale(0.7);
                font-size: 1rem;
                line-height: 1rem;
              }
            }
          }
        }
      }
    }
    .super {
      border: 1.12px solid $super;
      .ticket {
        border: 1.12px solid $super;
        &-title {
          color: $white;
          background-color: $super;
          border-left: 0.12px solid $white;
        }
        &-desc {
          border-left: 0.12px solid $super;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $super;
              }
            }
          }
          &-ref {
            border-top: 1.12px solid $super;
            border-bottom: 1.12px solid $super;
            .lead {
              color: $super;
              // border-left: 1.12px solid $super;
            }
            .caps {
              border-left: 1.12px solid $super;
              .option {
                color: $super;
              }
              .option {
                &_02 {
                  border-color: $super;
                  color: $super;
                }
                &_03 {
                  color: $super;
                }
              }
            }
          }
          &-seat {
            .lead {
              color: $super;
              // border-left: 1.12px solid $super;
            }
            .caps {
              border-left: 1.12px solid $super;
              .stamp {
                fill: $super;
              }
            }
          }
        }
      }
    }
    .standard {
      border: 1.12px solid $standard;
      .ticket {
        border: 1.12px solid $standard;
        &-title {
          color: $white;
          background-color: $standard;
          border-left: 0.12px solid $white;
        }
        &-desc {
          border-left: 0.12px solid $standard;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $standard;
              }
            }
          }
          &-ref {
            border-top: 1.12px solid $standard;
            border-bottom: 1.12px solid $standard;
            .lead {
              color: $standard;
              border-color: 1.12px solid $super;
            }
            .caps {
              border-left: 1.12px solid $standard;
              .option {
                color: $standard;
              }
              .option {
                &_02 {
                  border-color: $standard;
                  color: $standard;
                }
                &_03 {
                  color: $standard;
                }
              }
            }
          }
          &-seat {
            .lead {
              color: $standard;
              border-color: 1.12px solid $super;
            }
            .caps {
              border-left: 1.12px solid $standard;
              .pos {
                color: $standard;
              }
              .stamp {
                fill: $standard;
              }
            }
          }
        }
      }
    }
    .premium {
      border: 1.12px solid $premium;
      .ticket {
        border: 1.12px solid $premium;
        &-title {
          color: $white;
          background-color: $premium;
          border-left: 0.12px solid $white;
        }
        &-desc {
          border-left: 0.12px solid $premium;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $premium;
              }
            }
          }
          &-ref {
            border-top: 1.12px solid $premium;
            border-bottom: 1.12px solid $premium;
            .lead {
              color: $premium;
              border-color: 1.12px solid $premium;
            }
            .caps {
              border-left: 1.12px solid $premium;
              .option {
                color: $premium;
              }
              .option {
                &_02 {
                  border-color: $premium;
                  color: $premium;
                }
                &_03 {
                  color: $premium;
                }
              }
            }
          }
          &-seat {
            .lead {
              color: $premium;
              border-color: 1.12px solid $premium;
            }
            .caps {
              border-left: 1.12px solid $premium;
              .pos {
                color: $premium;
              }
              .stamp {
                fill: $premium;
              }
            }
          }
        }
      }
    }
  }
  &._large .GC {
    .ticket {
      border: 1.7px solid $super;
      display: flex;
      width: 366px;
      height: 234px;
      page-break-after: always;
      &-title {
        font-family: 'Arial Narrow', Arial, sans-serif;
        color: $white;
        font-size: 2rem;
        letter-spacing: 0.16em;
        padding: 0 11.7px;
        text-align: center;
        background-color: $super;
        border-left: 3px solid $white;
        text-orientation: sideways;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
      }
      &-desc {
        border-left: 3px solid $super;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        >* {
          margin: 3.36px;
        }
        &-date {
          display: flex;
          height: 53.7px;
          align-items: center;
          justify-content: space-around;
          .date {
            &_1 {
              font-size: 2.52rem;
              font-family: 'Oswald', sans-serif;
              letter-spacing: 0.27em;
              display: flex;
              align-items: center;
              .dow {
                font-size: 0.37rem;
              }
            }
            &_2 {
              font-size: 3rem;
              font-weight: bold;
              color: $white;
              background-color: $super;
              padding: 0 10px;
              font-family: 'Arial', Arial, sans-serif;
            }
          }
        }
        &-ref {
          display: flex;
          height: 74.5px;
          border-top: 1.68px solid $super;
          border-bottom: 1.68px solid $super;
          .lead {
            margin: 3.36px 0;
            font-weight: bold;
            font-size: 1.2rem;
            width: 40px;
            writing-mode: vertical-lr;
            color: $super;
            letter-spacing: 0.07rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1.68px solid;
          }
          .caps {
            height: 64.5px;
            margin: auto 0 auto 1.7px;
            padding-left: 16.8px;
            border-left: 16.8px solid $super;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .Number {
              font-size: 2.7rem;
              line-height: 2.7rem;
              font-family: 'Oswald', sans-serif;
              letter-spacing: 0.18em;
            }
            .option {
              font-size: 1rem;
              color: $super;
              font-weight: bold;
            }
          }
        }
        &-seat {
          display: flex;
          height: 74.5px;
          .lead {
            margin: 3.36px 0;
            font-weight: bold;
            font-size: 1.2rem;
            width: 40px;
            height: 67.5px;
            writing-mode: vertical-lr;
            color: $super;
            letter-spacing: 0.07rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1.68px solid;
          }
          .caps {
            margin: auto 0 auto 1.68px;
            padding-left: 16.8px;
            border-left: 1.68px solid $super;
            position: relative;
            fill: $black01;
            height: 67.5px;
            .pos {
              font-family: 'Arial', Arial, sans-serif;
              font-size: 5.7rem;
              line-height: 6.72rem;
              font-weight: bold;
              color: $super;
            }
            .stamp {
              position: absolute;
              top: 0;
              right: -105px;
              width: 81px;
              height: auto;
              svg {
                width: 100%;
                height: 60px;
              }
            }
          }
        }
      }
    }
    .jp {
      .ticket {
        &-desc {
          &-ref {
            .lead {}
          }
          &-seat {
            .lead {}
          }
        }
      }
    }
    .en {
      .ticket {
        &-desc {
          &-ref {
            .lead {
              .text {
                text-orientation: sideways;
                transform: rotate(180deg) scale(0.7);
                font-size: 1.5rem;
                line-height: 1.5rem;
              }
            }
          }
          &-seat {
            .lead {
              .text {
                text-orientation: sideways;
                transform: rotate(180deg) scale(0.7);
                font-size: 1.5rem;
                line-height: 1.5rem;
              }
            }
          }
        }
      }
    }
    .super {
      border: 1.12px solid $super;
      .ticket {
        border: 1.12px solid $super;
        &-title {
          color: $white;
          background-color: $super;
          border-left: 0.12px solid $white;
        }
        &-desc {
          border-left: 0.12px solid $super;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $super;
              }
            }
          }
          &-ref {
            border-top: 1.12px solid $super;
            border-bottom: 1.12px solid $super;
            .lead {
              color: $super;
              // border-left: 1.12px solid $super;
            }
            .caps {
              border-left: 1.12px solid $super;
              .option {
                color: $super;
              }
            }
          }
          &-seat {
            .lead {
              color: $super;
              // border-left: 1.12px solid $super;
            }
            .caps {
              border-left: 1.12px solid $super;
              .stamp {
                fill: $super;
              }
            }
          }
        }
      }
    }
    .standard {
      border: 1.12px solid $standard;
      .ticket {
        border: 1.12px solid $standard;
        &-title {
          color: $white;
          background-color: $standard;
          border-left: 0.12px solid $white;
        }
        &-desc {
          border-left: 0.12px solid $standard;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $standard;
              }
            }
          }
          &-ref {
            border-top: 1.12px solid $standard;
            border-bottom: 1.12px solid $standard;
            .lead {
              color: $standard;
              border-color: 1.12px solid $super;
            }
            .caps {
              border-left: 1.12px solid $standard;
              .option {
                color: $standard;
              }
            }
          }
          &-seat {
            .lead {
              color: $standard;
              border-color: 1.12px solid $super;
            }
            .caps {
              border-left: 1.12px solid $standard;
              .pos {
                color: $standard;
              }
              .stamp {
                fill: $standard;
              }
            }
          }
        }
      }
    }
    .premium {
      border: 1.12px solid $premium;
      .ticket {
        border: 1.12px solid $premium;
        &-title {
          color: $white;
          background-color: $premium;
          border-left: 0.12px solid $white;
        }
        &-desc {
          border-left: 0.12px solid $premium;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $premium;
              }
            }
          }
          &-ref {
            border-top: 1.12px solid $premium;
            border-bottom: 1.12px solid $premium;
            .lead {
              color: $premium;
              border-color: 1.12px solid $premium;
            }
            .caps {
              border-left: 1.12px solid $premium;
              .option {
                color: $premium;
              }
            }
          }
          &-seat {
            .lead {
              color: $premium;
              border-color: 1.12px solid $premium;
            }
            .caps {
              border-left: 1.12px solid $premium;
              .pos {
                color: $premium;
              }
              .stamp {
                fill: $premium;
              }
            }
          }
        }
      }
    }
  }
  &._medium .GC {
    .ticket {
      border: 1.49px solid $super;
      display: flex;
      width: 323px;
      height: 206px;
      page-break-after: always;
      &-title {
        font-family: 'Arial Narrow', Arial, sans-serif;
        color: $white;
        font-size: 1.7rem;
        letter-spacing: 0.16em;
        padding: 0 11.7px;
        text-align: center;
        background-color: $super;
        border-left: 3px solid $white;
        text-orientation: sideways;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
      }
      &-desc {
        border-left: 3px solid $super;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        >* {
          margin: 3.36px;
        }
        &-date {
          display: flex;
          height: 46px;
          align-items: center;
          justify-content: space-around;
          .date {
            &_1 {
              font-size: 1.7rem;
              font-family: 'Oswald', sans-serif;
              letter-spacing: 0.1em;
              display: flex;
              align-items: center;
              .dow {
                font-size: 0.37rem;
              }
            }
            &_2 {
              font-size: 1.7rem;
              font-weight: bold;
              color: $white;
              background-color: $super;
              padding: 0 10px;
              font-family: 'Arial', Arial, sans-serif;
            }
          }
        }
        &-ref {
          display: flex;
          height: 75px;
          border-top: 1.68px solid $super;
          border-bottom: 1.68px solid $super;
          .lead {
            margin: 2.95px 0;
            font-weight: bold;
            font-size: 1.2rem;
            width: 35px;
            writing-mode: vertical-lr;
            color: $super;
            letter-spacing: 0.07rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1.68px solid;
          }
          .caps {
            height: 67px;
            margin: auto 0 auto 1.49px;
            padding-left: 16.8px;
            border-left: 16.8px solid $super;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .Number {
              font-size: 2.7rem;
              line-height: 2.7rem;
              font-family: 'Oswald', sans-serif;
              font-weight: bold;
              letter-spacing: 0.18em;
            }
            .option {
              font-size: 1rem;
              color: $super;
              font-weight: bold;
            }
            .option {
              &_02 {
                font-size: 8px;
                border: 1px solid;
                padding: 1px 3px;
              }
              &_03 {
                font-size: 14px;
                display: flex;
                div {
                  font-family: 'Oswald', sans-serif;
                  &:nth-child(1) {
                    letter-spacing: 0.1rem;
                    font-weight: bold;
                  }
                  &:nth-child(2) {
                    margin: 0 0.5rem;
                  }
                  &:nth-child(3) {
                    font-size: 8px;
                    span {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        &-seat {
          display: flex;
          height: 55px;
          align-items: center;
          .lead {
            margin: 3.36px 0;
            font-weight: bold;
            font-size: 1.2rem;
            width: 35px;
            height: 55px;
            writing-mode: vertical-lr;
            color: $super;
            letter-spacing: 0.07rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1.68px solid;
          }
          .caps {
            margin: auto 0 auto 1.68px;
            padding-left: 16.8px;
            border-left: 1.68px solid $super;
            position: relative;
            fill: $black01;
            height: 55px;
            .pos {
              font-family: 'Arial', Arial, sans-serif;
              font-size: 5rem;
              line-height: 5rem;
              font-weight: bold;
              color: $super;
            }
            .stamp {
              position: absolute;
              top: 0;
              right: -90px;
              width: 71px;
              height: auto;
              svg {
                width: 100%;
                height: 52px;
              }
            }
          }
        }
      }
    }
    .jp {
      .ticket {
        &-desc {
          &-date {
            .date {
              &_1 {
                font-size: 1.7rem;
              }
            }
          }
          &-ref {
            .lead {}
          }
          &-seat {
            .lead {}
          }
        }
      }
    }
    .en {
      .ticket {
        &-desc {
          &-date {
            .date {
              &_1 {
                font-size: 1.7rem;
              }
            }
          }
          &-ref {
            .lead {
              .text {
                text-orientation: sideways;
                transform: rotate(180deg) scale(0.7);
                font-size: 1.3rem;
                line-height: 1.3rem;
              }
            }
          }
          &-seat {
            .lead {
              .text {
                text-orientation: sideways;
                transform: rotate(180deg) scale(0.7);
                font-size: 1.3rem;
                line-height: 1.3rem;
              }
            }
          }
        }
      }
    }
    .super {
      border: 1px solid $super;
      .ticket {
        border: 1px solid $super;
        &-title {
          color: $white;
          background-color: $super;
          border-left: 0.1px solid $white;
        }
        &-desc {
          border-left: 0.1px solid $super;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $super;
              }
            }
          }
          &-ref {
            border-top: 1px solid $super;
            border-bottom: 1px solid $super;
            .lead {
              color: $super;
              // border-left: 1.12px solid $super;
            }
            .caps {
              border-left: 1px solid $super;
              .option {
                color: $super;
              }
            }
          }
          &-seat {
            .lead {
              color: $super;
              // border-left: 1.12px solid $super;
            }
            .caps {
              border-left: 1px solid $super;
              .stamp {
                fill: $super;
              }
            }
          }
        }
      }
    }
    .standard {
      border: 1px solid $standard;
      .ticket {
        border: 1px solid $standard;
        &-title {
          color: $white;
          background-color: $standard;
          border-left: 0.1px solid $white;
        }
        &-desc {
          border-left: 0.1px solid $standard;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $standard;
              }
            }
          }
          &-ref {
            border-top: 1px solid $standard;
            border-bottom: 1px solid $standard;
            .lead {
              color: $standard;
              border-color: 1px solid $super;
            }
            .caps {
              border-left: 1px solid $standard;
              .option {
                color: $standard;
              }
            }
          }
          &-seat {
            .lead {
              color: $standard;
              border-color: 1px solid $super;
            }
            .caps {
              border-left: 1px solid $standard;
              .pos {
                color: $standard;
              }
              .stamp {
                fill: $standard;
              }
            }
          }
        }
      }
    }
    .premium {
      border: 1px solid $premium;
      .ticket {
        border: 1px solid $premium;
        &-title {
          color: $white;
          background-color: $premium;
          border-left: 0.1px solid $white;
        }
        &-desc {
          border-left: 0.1px solid $premium;
          &-date {
            .date {
              &_2 {
                color: $white;
                background-color: $premium;
              }
            }
          }
          &-ref {
            border-top: 1px solid $premium;
            border-bottom: 1px solid $premium;
            .lead {
              color: $premium;
              border-color: 1px solid $premium;
            }
            .caps {
              border-left: 1px solid $premium;
              .option {
                color: $premium;
              }
            }
          }
          &-seat {
            .lead {
              color: $premium;
              border-color: 1px solid $premium;
            }
            .caps {
              border-left: 1px solid $premium;
              .pos {
                color: $premium;
              }
              .stamp {
                fill: $premium;
              }
            }
          }
        }
      }
    }
  }
  .GDM {
    .wrapper {
      padding-top: 0;
    }
    .page {
      padding-bottom: 0;
    }
    .ticket {
      width: 323px;
      height: 206px;
      border: 1px solid #999;
      page-break-after: always;
      &-mainimg {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #999;
        img {
          width: auto;
          height: 32px;
        }
      }
      &-infomation {
        height: 75px;
        display: flex;
        padding: 12px 10px;
        border-bottom: 1px solid #999;
        &-date {
          flex: 1;
          border-right: 1px solid #999;
          dt {
            font-size: 1.3rem;
            line-height: 1.4rem;
            font-weight: bold;
          }
          dd {
            &.caption {
              font-size: 0.9rem;
              line-height: 0.9rem;
            }
            &.desc {
              font-family: 'Arial Narrow', Arial, sans-serif;
              font-weight: bold;
              align-items: center;
              margin-top: 2px;
              display: grid;
              grid-template-columns: 105px 1fr;
              grid-template-rows: 1.4rem 1.8rem;
              gap: 0px 0px;
              grid-template-areas:
                  "a a"
                  "c c";
              width: 100%;
              height: 100%;
              .dat {
                font-size: 1.5rem;
                grid-area: a;
              }
              .dow {
                font-size: 1.2rem;
              }
              .time {
                font-size: 1.8rem;
                grid-area: c;
              }
            }
          }
        }
        &-number {
          width: 129px;
          padding-left: 10px;
          dt {
            font-size: 1.3rem;
            line-height: 1.3rem;
            font-weight: bold;
          }
          dd {
            font-size: 2rem;
            line-height: 2rem;
            font-family: 'Arial', Arial, sans-serif;
            font-weight: bold;
          }
        }
      }
      &-username {
        height: 70px;
        display: flex;
        align-items: center;
        gap: 5px 0;
        flex-direction: column;
        justify-content: center;
        dt {
          font-size: 1.3rem;
          line-height: 1.3rem;
          font-weight: bold;
          flex-shrink: 0;
        }
        dd {
          font-size: 18px;
          line-height: 1.3rem;
          font-family: 'Arial', Arial, sans-serif;
          font-weight: bold;
          text-align: center;
          word-break: break-all;
          //white-space: nowrap;

          &.fs {
            &-18 {
              font-size: 18px;
            }
            &-12 {
              font-size: 12px;
            }
          }
        }
      }
    }
    .jp {
      &.ticket {
        &-infomation {
          &-date {
            dd.desc {
              gap: 0 0px;
              .dat {
                font-size: 1.5rem;
              }
            }

          }
          &-number {
            font-size: 2.4rem;
          }
        }
      }
    }
    .en {
      &.ticket {
        &-infomation {
          &-date {
            dd.desc {
              gap: 0 5px;
              .dat {
                font-size: 1.8rem;
              }
            }

          }
          &-number {
            font-size: 3rem;
            line-height: 3rem;
          }
        }
      }
    }
  }
  .ATTENTION {
    .ticket {
      width: 323px;
      height: 206px;
      border: 1px solid #ccc;
      page-break-after: always;
      display: flex;
      gap: 2px 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: #E61212;
        line-height: 16px;
        margin: 0 0 5px;
      }
      .subtitle {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #E61212;
      }
      .number {
        text-align: center;
        font-weight: bold;
        font-size: 14px;
      }
      .stamp {
        display: flex;
        justify-content: center;
        gap: 0 9px;
        width: 100%;
        p {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: bold;
          color: #DEDEDE;
          border: 2px solid #DEDEDE;
          border-radius: 50%;
          &.active {
            color: #000;
            border-color: #000;
          }
        }
      }
    }
  }
}

*/