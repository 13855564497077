.privacy-top {
  &-block {
    width: 1010px;
    margin: 80px auto;
    @include mq {
      width: calc(100% - 20px);
    }
    >ul {
      margin-top: 30px;
      >li {
        position: relative;
        margin-top: 15px;
        padding: 7px 0 17px 20px;
        border-bottom: solid 1px #c0c0c0;
        &::before {
          position: absolute;
          left: 0;
          content: "■";
          color: $black02;
        }
        >ul {
          margin: 30px 0 15px;
        }
      }
    }
    &-box {
      border: solid 1px #c0c0c0;
      margin-top: 20px;
      padding: 10px;
    }
  }

}