@import "../mixin/mixin";

test,html, body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 10px;
  @include mq(ssp) {
    font-size: 8px;
  }
}

body {
  font-family: $font-sans_serif;
  color: $text-color;
}

img {
  width: 100%;
}

/*  link
-----------------------------------------------------------------------------*/
a {
  transition-property: background-color, opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}

em {
  font-style: normal;
}

a,
a:visited,
a:hover {
  color: $text-color;
  text-decoration: none;
}

a img {
  border: 0;
}

.clearfix:after {
  height: 0;
  display: block;
  clear: both;
  content: " ";
}

@media screen and (min-width: 1281px) {
  .sp,
  .mpc {
    display: none !important;
  }
}

@include mq(mpc) {
  .sp {
    display: none !important;
  }
}

@include mq {
  .pc,
  .mpc {
    display: none !important;
  }
}

/*  reset
-----------------------------------------------------------------------------*/
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  box-sizing: border-box;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul, ol {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

// フォーム部品の完全リセット用
// input {
//   -webkit-appearance: none;
// }
// select {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }
// select::-ms-expand {
//   display: none;
// }

input,
button,
select,
textarea {
  font-family: $font-sans_serif;
}

button,
input[type="submit"] {
  cursor: pointer;
  transition: .3s ease;
}

input::placeholder,
textarea::placeholder {
  color: #D6DADF;
}

/* IE */
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #D6DADF;
}

/* Edge */
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #D6DADF;
}

img {
  vertical-align: top;
}

main {
  // IE11
  display: block;
}

[v-cloak] {

  display: none;
}


.mt0{margin-top: 0 !important;}
.mt10{margin-top: 10px !important;}
.mt15{margin-top: 15px !important;}
.mt20{margin-top: 20px !important;}
.mt30{margin-top: 30px !important;}
.mt40{margin-top: 40px !important;}
.mt50{margin-top: 50px !important;}


.mb0{margin-bottom: 0 !important;}
.mb10{margin-bottom: 10px !important;}
.mb15{margin-bottom: 15px !important;}
.mb20{margin-bottom: 20px !important;}
.mb25{margin-bottom: 25px !important;}
.mb30{margin-bottom: 30px !important;}
.mb40{margin-bottom: 40px !important;}
.mb50{margin-bottom: 50px !important;}

.ml20 {margin-left: 20px;}