html[lang="ja"] {
  .br {
    display: block !important;
  }
  #p-joriku,
  #p-notes {
    .sec-prep .btn02 {
      .lang_jp {
        display: block;
      }
      .lang_en {
        display: none;
      }
      .lang_cn {
        display: none;
      }
      .lang_tw {
        display: none;
      }
    } 
  }
}
html:not([lang="ja"]) {

}  



html:not([lang="jp"]) {
  .br {
    display: none !important;
  }
  #p-experience .sec-flow .img-comment {
    display: none;
  }
}

html[lang="en"] {
  #p-rsv._01 .calendar-box table tbody .day .note{
    @include mq{
      margin-top: -5px;
    }
  }
  #p-rsv._01 .calendar-box table tbody .def dd > span{
    width: auto;
    padding: 0 5px;
    font-size:1,2rem;
  }
  #p-rsv._01 .calendar-box table tbody .def > div.disable{
    @include mq(sspc){
      min-height: 102px;
    }
  }
  #p-rsv._01 .calendar-box table tbody .def > div.disable dd{
    @include mq(pc){
      font-size: 2rem;
    }
    @include mq{
      word-break: break-all;
    }
  }
  #p-rsv._01 .calendar-box table tbody .def .landing{
    @include mq(pc){
      padding: 3px 8px;
    }
    @include mq{
      transform: scale(.8);
    }
  }
  #p-rsv._01 .calendar-box table tbody .def .landing:before{
    content: none;
  }
  #p-rsv._01 .ticket-info-box .ticket-def .item dt span{
    width: auto;
    padding: 0 5px;
  }

  /*
  #p-rsv._01 .ticket-info-box .ticket-def .item dt{
    font-size: 1.2rem;
    font-weight: bold;
    background: #B8B8B8;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    padding: 0 5px;
  }
  #p-rsv._01 .ticket-info-box .ticket-def ._01 .item dt{
    padding: 0;
  }

  #p-rsv._01 .ticket-info-box .ticket-def ._03 .item dt{
    height: auto;
    background: none;
    color: #222;
    padding: 0;
  }*/

  .global-menu .logo a img:nth-child(2) {
    display: none;
  }
  #top2 .sec-mainv .mainv-news dt {
    @include mq(pc) {
      font-size: 1.3rem;
      position: relative;
      padding-left: 30px;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  #top2 .sec-mainv .tour-today .def > dt {
    font-size: 1.2rem;
    word-break: break-all;
    padding: 10px;
  }
  #top2 .sec-mainv .tour-today .indef > dt {
    font-size: 1.4rem;
    line-height: 1.1;
  }
  #top2 .sec-tour .plan-box .plan-list .def-fee dt {
    font-size: 1.4rem;
  }
  #top2 .sec-tour .fee-box table thead th span {
    font-size: 1.1rem;
  }
  #top2 .sec-tour .fee-box table thead th div {
    font-size: 1.6rem;
  }
  #top2 .sec-tour .timetable-box .place-def dt {
    width: 110px;
  }
  #top2 .sec-tour .timetable-box .timetable-wrap .def > dt {
    @include mq(pc) {
      @include mq(tablet) {
        font-size: 1.6vw;
      }
    }
  }
  .modal-timetable .timetable-box .place-def dt {
    @include mq(pc) {
      width: 130px;
    }
  }
  .sidenav .list .def dt {
    word-break: break-all;
  }
  .btn a {
    font-size: 1.2rem !important;
    text-align: center;
    padding: 10px;
    word-break: break-all;
  }


  @include mq {
    font-size: 8px;
  }
  .btn-box._rsv .btn._rsvd a {
    padding: 0 30px;
    @include mq(pc) {
      font-size: 1.7rem;
    }
  }
  #top .sec-mainv .mainv-landing dt {
    font-size: 1.1rem;
    padding-left: 3px;
    padding-right: 3px;
  }
  #top .sec-tour .insec-tour-about .blc-fee .fee-note dt {
    font-size: 1rem;
  }
  #top .sec-tour .insec-tour-about .blc-fee .btn a {
    font-size: 1.4rem;
  }
  #top .sec-tour .insec-tour-about .tour-list .img-wrap dl {
    @include mq(pc) {
      width: 180px;
    }
  }
  #top .sec-reason .balloon {
    font-size: 1.7rem;
    @include mq {
      font-size: 1.1rem;
    }
  }
  #top .sec-tour .insec-tour-about .tour-list .btn a {
    width: 260px;
  }
  .sidebar .list01 .tour-conts .btn-rsv a {
    font-size: 1.2rem;
  }
  .sidebar .list01 .tour-conts .btn-rsvd a {
    line-height: 1.3;
  }
  .header .logo a .jp {
    font-size: 1.6rem;
  }
  .header .h-nav a {
    font-size: 1.2rem;
    text-align: center;
  }
  .header .rsv-btn a {
    line-height: 1.35;
    text-align: center;
  }
  @include mq(mpc) {
  .header .rsv-btn a {
      font-size: 1rem;
    }
    .header .logo .text strong {
      font-size: 1.3vw;
      white-space: nowrap;
    }
  }
  #p-rsv._01 .calendar-box .month-area .month {
    width: auto;
    padding: 0 13px;
  }
  #p-rsv._01 .port-box .port-list label {
    font-size: 1.7rem;
    text-align: center;
    white-space: pre-wrap;
  }
  #p-rsv._03 .gray-bdr-box01 .seat-def > div {
    @include mq {
      width: 100%;
    }
  }
  #p-rsv._03 .gray-bdr-box01 .seat-def > div:not(:last-child) {
    @include mq {
      margin-right: 0;
    }
  }
  #p-rsv._03 .gray-bdr-box01 .seat-def dt {
    font-size: 1.1rem;
    padding: 0 10px;
    width: auto;
    @include mq {
      font-size: 1rem;
      height: auto;
      background: transparent;
      text-align: left;
    }
  }
  #p-rsv._03 .seat-type-def {
    @include mq {
      flex-wrap: wrap;
    }
  }
  #p-rsv._03 .seat-type-def > div:nth-of-type(2) dt {
    font-size: 1rem;
    @include mq(pc) {
      padding: 3px;
    }
    @include mq {
      font-size: 1.1rem;
    }
  }
  #p-rsv._03 .seat-type-def > div + div {
    @include mq {
      margin-top: 10px;
    }
  }
  #p-rsv._03 .seat-type-def > div dt {
    @include mq {
      width: 115px;
    }
  }
  #p-rsv._03 .seat-type-def > div dd {
    font-size: 1.3rem;
    @include mq {
      font-size: 1.2rem;
      width: calc(100% - 121px);
    }
  }

  .form-def02 {
    @include mq {
      font-size: 1.3rem;
    }
  }
  .required,
  .optional {
    width: auto;
    padding: 1px 5px;
  }
  #p-rsv._04-1 .note-box {
    text-align: left;
  }
  #p-rsv._04-2 .price-tbl tbody th dt {
    width: auto;
    height: auto;
    background: transparent;
    margin-right: 15px;
    @include mq {
      font-size: 1rem;
      margin: 0 0 0 8px;
    }
  }
  #p-rsv._04-2 .sum-box {
    @include mq {
      padding: 10px;
    }
  }
  .cmn-tab-list._p-joriku-old li:nth-child(3) span {
    font-size: 1rem;
    width: 100px;
    @include mq {
      width: 80px;
      transform: scale(.8);
    }
  }
  #p-joriku .sec-fee .box .def > div dd {
    white-space: nowrap;
  }
  #p-joriku .sec-fee .blc._01 .box:nth-of-type(2) .def > div dd em {
    @include mq {
      margin-right: 2px;
    }
  }
  #p-joriku,
  #p-notes {
    .sec-prep .btn02 {
      .lang_jp {
        display: none;
      }
      .lang_en {
        display: block;
      }
      .lang_cn {
        display: none;
      }
      .lang_tw {
        display: none;
      }
    } 
  }
}

//簡体
html[lang="zh-cn"] {
  .zh-cn {
    display: inherit;
  }
  #p-joriku,
  #p-notes {
    .sec-prep .btn02 {
      .lang_jp {
        display: none;
      }
      .lang_en {
        display: none;
      }
      .lang_cn {
        display: block;
      }
      .lang_tw {
        display: none;
      }
    } 
  }

}
//繁体
html[lang="zh-tw"] {
  .zh-tw {
    display: inherit;
  }
  
  #p-joriku,
  #p-notes {
    .sec-prep .btn02 {
      .lang_jp {
        display: none;
      }
      .lang_en {
        display: none;
      }
      .lang_cn {
        display: none;
      }
      .lang_tw {
        display: block;
      }
    } 
  }

}

html[lang="ja"]{
  .jp_only{
    display: block;
  }
  .foreign_only{
    display: none !important;
  }
  .btn-box._rsv{
    display: flex;
    @include mq {
      display: block;
    }
  }
  .slick-track{
  　.jp_only {
      display: block;
      }
    .en_only{
      display: none !important;
    }
    .zh-tw_only{
      display: none !important;
    }
    .zh-cn_only{
      display: none !important;
    }
  }
}

html[lang="en"] {
  .slick-track{
    　.jp_only {
        display: none !important;
        }
      .en_only{
        display: block;
      }
      .zh-tw_only{
        display: none !important;
      }
      .zh-cn_only{
        display: none !important;
      }
    }
}

html[lang="zh-tw"] {
  .slick-track{
    　.jp_only {
         display: none !important;
        }
      .en_only{
        display: none !important;
      }
      .zh-tw_only{
        display: block;
      }
      .zh-cn_only{
        display: none !important;
      }
    }
}

html[lang="zh-cn"] {
  .slick-track{
    　.jp_only {
         display: none !important;
        }
      .en_only{
        display: none !important;
      }
      .zh-tw_only{
        display: none !important;
      }
      .zh-cn_only{
        display: block;
      }
    }
}


html[lang="en"] ,
html[lang="zh-tw"] ,
html[lang="zh-cn"] {
  .jp_only{
    display: none !important;
  }
  .foreign_only{
    display: block;
  }
  .btn-box._rsv{
    display: flex;
    @include mq {
      display: block;
    }
  }
}


html[lang="ja"] {
  .sec-cmn-info-dtl,
  .breadcrumbs,
  .info-list {
    .jp_only{
      display: block;
    }
    .en_only,
    .zh-cn_only,
    .zh-tw_only{
      display: none;
    }
  }
  .breadcrumbs{
    .jp_only,
    .en_only,
    .zh-cn_only,
    .zh-tw_only{
      &:after{
        display: none;
      }
    }
  }
}


html[lang="en"] {
  .sec-cmn-info-dtl,
  .breadcrumbs,
  .info-list {
    .en_only{
      display: block;
    }
    .jp_only,
    .zh-cn_only,
    .zh-tw_only{
      display: none;
    }
  }
  .breadcrumbs{
    .jp_only,
    .en_only,
    .zh-cn_only,
    .zh-tw_only{
      &:after{
        display: none;
      }
    }
  }
}

html[lang="zh-cn"] {
  .sec-cmn-info-dtl,
  .breadcrumbs,
  .info-list {
    .zh-cn_only{
      display: block;
    }
    .jp_only,
    .en_only,
    .zh-tw_only{
      display: none;
    }
  }
  .breadcrumbs{
    .jp_only,
    .en_only,
    .zh-cn_only,
    .zh-tw_only{
      &:after{
        display: none;
      }
    }
  }
}

html[lang="zh-tw"] {
  .sec-cmn-info-dtl,
  .breadcrumbs,
  .info-list {
    .zh-tw_only{
      display: block;
    }
    .jp_only,
    .zh-cn_only,
    .en_only{
      display: none;
    }
  }
  .breadcrumbs{
    .jp_only,
    .en_only,
    .zh-cn_only,
    .zh-tw_only{
      &:after{
        display: none;
      }
    }
  }
}