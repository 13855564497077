.ttl05 {
  &.company {
    margin-top: 50px;
  }

  @include mq {
    font-size: 2.2rem;
  }
}

.company-list {
  width: 860px;
  margin: 0 auto 40px;

  @include mq {
    padding: 0 10px;
    width: 100%;
  }

  dt {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;

    &:first-child {
      border-top: 1px solid #D6D6D6;
      padding: 30px 0 0 0;

      @include mq {
        padding: 20px 0 0 0;
      }
    }

    @include mq {
      font-size: 1.6rem;
    }
  }

  dd {
    border-bottom: 1px solid #D6D6D6;
    padding: 0 0 30px;
    margin: 0 0 30px;

    @include mq {
      padding: 0 0 20px;
      margin: 0 0 20px;
    }

    a {
      font-size: 1.4rem;
      color: $main-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}