#p-err {
  section {
    min-height: 40vh;
    display: flex;
    align-items: center;
    
  }
  .err-box {
    height: 100%;
    margin:  auto 0;
    text-align: center;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $red;
    @include mq {
      margin-top: 80px;
      flex-direction: column;
    }

    img {
      width: 250px;
      height: auto;

      @include mq {
        width: 100px;
      }
    }
    > div {
      text-align: left;
      margin-left: 30px;
      @include mq {
        text-align: center;
        margin-left: 0;
        margin-top: 30px;
      }
      > .stats {
        font-size: 3.2rem;
        font-weight: bold;
        margin-bottom: 16px;
        @include mq {
          margin-bottom: 0;
        }
      }
      > .stats {
        font-size: 2.4rem;
      }
    }
  }
} 
#p-maintenance {
  .maintenance-box {
    height: 100%;
    margin:  auto 0;
    text-align: center;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include mq {
      margin-top: 50px;
    }
    img {
      width: 150px;
      height: auto;
    }
    > div {
      margin-top: 30px;
    }
  }
}