.educational_travel {
  .sec-travel {
    @include ls(50);
    .col-cmn-inner {
      padding: 100px 60px;
      @include mq {
        padding: 80px 15px;
      }
      &._b0 {
        padding-bottom: 0;
      }
    }
    .bg {
      background: #f0f0f0;
      &-bl {
        background: #eff6f9;
      }
      &-white {
        background: $white;
        margin-left: 60px;
        padding: 50px;
        @include mq {
          margin-left: 0;
          padding: 30px 20px 40px;
        }
      }
    }
    .h4_ttl {
      border-bottom: 1px solid $main-color;
      font-family: $font-zen;
      font-size: 3rem;
      @include lh(30, 39);
      color: $text-color;
      margin-bottom: 50px;
      padding-bottom: 30px;
      @include mq {
        font-size: 2.2rem;
        @include lh(22, 33);
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        padding-bottom: 15px;
      }
      span {
        font-family: $font-sans_serif;
        color: $main-color;
        padding-right: 10px;
      }
    }
    .h5_ttl {
      font-size: 2.6rem;
      margin-bottom: 20px;
      @include mq {
        font-size: 2rem;
        margin-bottom: 15px;
      }
    }
    .txt {
      @include mq {
        font-size: 1.4rem;
        @include lh(14, 21);
      }
    }
    .txt18 {
      font-size: 1.8rem;
      @include lh(18, 23);
    }
    .txt_lead {
      font-size: 2.6rem;
      @include lh(26, 34);
      margin-bottom: 60px;
      @include mq {
        font-size: 2rem;
        @include lh(20, 24);
        margin-bottom: 40px;
      }
    }
    .btn {
      &._pdf {
        a {
          border-radius: 5px;
          font-size: 1.5rem;
          @include lh(15, 19);
          margin-top: 50px;
          height: 60px;
          @include mq {
            font-size: 1.4rem;
            margin-top: 30px;
            width: 100%;
          }
          &:before {
            content: url(../../img/educational_travel/icon-pdf.png);
            height: 20px;
            @include mq {
              left: 20px;
            }
          }
          &:hover {
            &:before {
              content: url(../../img/educational_travel/icon-pdf-white.png);
            }
          }
        }
      }
      &._arrow {
        a {
          &::before {
            content: url(../../img/educational_travel/icon-arrow.png);
            left: auto;
            right: 20px;
            @include mq {
              left: auto;
            }
          }
          &:hover {
            &::before {
              content: url(../../img/educational_travel/icon-arrow-white.png);
            }
          }
        }
      }
      &._map {
        color: $text-color;
        background: $white;
        border: 1px solid $main-color;
        border-radius: 5px;
        margin: 20px 0;
        width: 560px;
        @include mq {
          width: 100%;
        }
        a {
          width: 100%;
          height: 60px;
          font-size: 1.5rem;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            content: "";
            display: block;
            margin: -1px 7px 0 0;
            width: 15px;
            height: 16px;
            background: url(../../img/common/icon_pdf_aqua.png) no-repeat;
            background-size: contain;
          }
          &:hover {
            background: $main-color;
            color: $white;
          }
        }
      }
    }
    &-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 25px 0;
      @include mq {
        gap: 20px 0;
      }
      li {
        background: $white;
        border-radius: 5px;
        overflow: hidden;
        width: calc(100% / 3 - 15px);
        @include mq {
          width: 100%;
        }
        div {
          font-weight: bold;
          padding: 20px 30px 30px;
          @include mq {
            padding: 20px;
          }
          .num {
            color: $main-color;
            font-size: 2rem;
            @include lh(20, 26);
            @include mq {
              font-size: 1.4rem;
              @include lh(14, 18);
            }
          }
          .ttl {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 2.8rem;
            @include lh(28, 39);
            margin: 30px 0;
            @include mq {
              font-size: 2.2rem;
              @include lh(22, 29);
              margin: 15px 0;
            }
            .ttl_s {
              color: $standard;
              display: block;
              font-size: 1.8rem;
              @include lh(18, 23);
              @include mq {
                font-size: 1.4rem;
                @include lh(14, 18);
                margin-top: 5px;
              }
            }
            .txt18 {
              display: inline-block;
              @include lh(18, 23);
              padding: 0;
              &._br {
                display: block;
              }
            }
          }
          .btn_red {
            a {
              background: #cc2a0a;
              border: 2px solid #cc2a0a;
              border-radius: 5px;
              color: $white;
              display: block;
              font-size: 1.6rem;
              @include lh(16, 21);
              padding: 12px;
              width: 100%;
              &:hover {
                background: $white;
                color: #cc2a0a;
              }
            }
          }
        }
      }
    }
    .p-box {
      margin-top: 50px;
      @include mq {
        margin-top: 40px;
      }
      .btn:not(._map) {
        display: block;
        a {
          margin-top: 15px !important;
        }
      }
      .ttl01 {
        font-size: 2rem;
        font-weight: bold;
        @include lh(20, 26);
        margin-bottom: 15px;
        text-align: left;
        @include mq {
          font-size: 1.4rem;
          @include lh(14, 21);
        }
        span {
          color: $main-color;
        }
      }
      .ttl_bl {
        display: flex;
        align-items: center;
        color: $main-color;
        font-size: 2.6rem;
        @include lh(26, 34);
        margin-bottom: 20px;
        @include mq {
          font-size: 2rem;
          @include lh(20, 26);
          margin-bottom: 15px;
        }
        img {
          width: auto;
          vertical-align: middle;
          margin-right: 15px;
          @include mq {
            height: 22px;
            margin-right: 8px;
          }
        }
      }
      .txt18 {
        font-weight: bold;
        margin: 30px 0 15px;
      }
      dl {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        div {
          @include mq(pc) {
            display: flex;
            border-bottom: 1px solid #dbdbdb;
            width: 100%;
          }
        }
        dt {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 2rem;
          font-weight: bold;
          @include lh(20, 30);
          letter-spacing: 0;
          padding: 28px 20px 22px;
          max-width: 600px;
          width: 60%;
          @include mq {
            font-size: 1.6rem;
            padding: 15px;
            max-width: unset;
            width: 100%;
          }
        }
        dd {
          padding: 28px 20px 22px;          
          width: 60%;
          @include mq {
            font-size: 1.4rem;
            @include lh(14, 21);
            padding: 15px;
            width: 100%;
          }
          .txt-price {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 1.4rem;
            font-weight: bold;
            text-align: right;
            @include lh(14, 18);
            span {
              color: $main-color;
              font-size: 2.2rem;
              @include lh(22, 29);
              margin-right: 8px;
              @include mq {
                font-size: 1.8rem;
              }
            }
          }
        }
        &.bg-01 {
          dt {
            background: #65b2d6;
          }
        }
        &.bg-02 {
          dt {
            background: #0070a2;
          }
        }
        &.bg-price {
          box-shadow: none;
          dt {
            font-size: 1.8rem;
            @include lh(18, 23);
            max-width: 100%;
            padding: 15px 20px;
            width: 100%;
            text-align: left;
          }
          dd {
            padding: 0;
            width: 100%;
            div {
              border-bottom: 1px solid #dbdbdb;
              align-items: center;
              justify-content: space-between;
              font-weight: bold;
              padding: 15px 10px 15px 20px;              
            }
          }
        }
      }
      .tbl-price {
        dt {          
          @include mq(pc) {
            justify-content: flex-start;
          }
        }
        dd {
          padding: 28px 10px 22px 20px;
          @include mq {
            padding: 15px;
          }
        }
      }
    }
    .p-img {
      max-width: 620px;
      width: 40%;
      @include mq {
        max-width: unset;
        width: 100%;
      }
    }
    .col {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      dl {
        dt {
          max-width: 280px;
          width: 38.5%;
          text-align: center;
          @include mq {
            max-width: unset;
            width: 100%;
          }
        }
        dd {
          width: 62%;
          @include mq {
            width: 100%;
          }
        }
      }
      &-btns {
        justify-content: flex-start;
        gap: 20px;
        @include mq {
          flex-direction: column;
          gap: 10px;
        }
      }
    }
    .col2 {
      @include mq {
        gap: 20px;
      }
      li {
        width: calc(50% - 18px);
        @include mq {
          width: 100%;
        }
      }
    }
    .list {
      &-01 {
        margin-top: 15px;
        @include mq {
          margin-top: 20px;
        }
        li {
          margin-bottom: 10px;
          position: relative;
          padding-left: 15px;
          @include mq {
            font-size: 1.4rem;
            @include lh(14, 21);
          }
          &::before {
            content: "";
            background: #65b2d6;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            position: absolute;
            left: 0;
            top: 6px;
            @include mq {
              top: 4px;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &-02 {
        justify-content: flex-start;
        gap: 12px 0;
        li {
          border: 2px solid #dbdbdb;
          border-radius: 5px;
          font-weight: bold;
          margin-right: 60px;
          padding: 12px 14px;
          position: relative;
          @include mq {
            font-size: 1.4rem;
            margin-right: 45px;
            padding: 7px 8px 6px;
          }
          &::after {
            content: "";
            background: #dbdbdb;
            height: 2px;
            width: 60px;
            position: absolute;
            right: -62px;
            top: 50%;
            @include mq {
              width: 45px;
              right: -47px;
            }
          }
          span {
            color: $standard;
          }
          .txt_po {
            font-size: 1.4rem;
            @include lh(14, 18);
            position: absolute;
            right: -62px;
            top: 2px;
            width: 60px;
            height: 100%;
            text-align: center;
            @include mq {
              font-size: 1.2rem;
              right: -47px;
              top: 0;
              width: 45px;
            }
          }
          &.w30 {
            margin-right: 30px;
            @include mq {
              margin-right: 0;
            }
            &::after {
              width: 30px;
              right: -32px;
              @include mq {
                display: none;
              }
            }
          }
          &.sp20 {
            @include mq {
              margin-left: 20px;
              margin-right: 0;
              &::after {
                content: "";
                background: #dbdbdb;
                display: block;
                height: 2px;
                width: 20px;
                position: absolute;
                left: -22px;
                top: 50%;
              }
            }
          }
          &._l20 {
            @include mq {
              margin-left: 45px;
              &::after {
                width: 45px;
                left: -47px;
              }
              .txt_po {
                right: auto;
                left: -47px;
              }
            }
          }
          &:last-child {
            margin-right: 0;
            &::after {
              display: none;
            }
          }
        }
      }
      &-03 {
        justify-content: flex-start;
        gap: 20px;
        margin-top: 50px;
        @include mq {
          gap: 10px;
          margin-top: 40px;
        }
        li {
          width: calc(100% / 3 - 15px);
          @include mq {
            width: 100%;
          }
        }
      }
    }
  }
  #sec {
    &-00 {
      & > .txt18 {
        @include lh(18, 27);
        padding: 100px 0;
        @include mq {
          font-size: 1.6rem;
          @include lh(16, 24);
          padding: 30px 15px 80px;
        }
      }
    }
    &-02,
    &-03,
    &-05 {
      .p-box {
        dl {
          @include mq {
            dt {
              text-align: center;
            }
            dd {
              .txt-price {
                justify-content: center;
              }
            }
          }          
        }
      }
    }
    &-02 {
      .p-box {
        .btn {
          &._pdf:not(._arrow) {
            a {
              @include mq {
                margin-top: 0 !important;
              }
            }
          }
        }
      }
    }   
    &-03 {
      .p-box {        
        .list {
          &-02 {
            li {
              &.sp20 {
                &::before {
                  @include mq {
                    width: 20px;
                    right: -22px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &-05 {
      .p-box {
        .h5_ttl {
          font-size: 2.2rem;
          margin-bottom: 15px;
          @include mq {
            font-size: 1.8rem;
            margin-bottom: 10px;
          }
        }
        .ttl01 {
          font-size: 1.8rem;
          margin-bottom: 30px;
          @include mq {
            font-size: 1.4rem;
          }
        }
        .txt18 {
          margin: 20px 0 30px;
          @include mq {
            font-size: 1.4rem;
            @include lh(14, 21);
          }
        }
        &:last-child {
          margin-top: 80px;
          @include mq {
            margin-top: 60px;
          }
        }
      }
    }
    &-06 {
      .list {
        &-02 {
          margin-bottom: 30px;
        }
      }
      .ml20 {
        margin-left: 0;
      }
    }
    &-07 {
      .p-box {
        margin-top: 0;
        .ttl_bl {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          font-size: 3rem;
          @include lh(30, 44);
          margin-bottom: 25px;
          @include mq {
            font-size: 2.2rem;
            @include lh(22,32);
            margin-bottom: 20px;
            gap: 5px;
          }
          img {
            margin-right: 0;
            @include mq {
              height: 24px;
            }
          }
        }
        .btn {
          &._arrow {
            display: flex;
            a {
              background: $main-color;
              border-radius: 50px;
              color: $white;
              font-size: 1.6rem;
              @include lh(16, 24);
              height: 60px;
              margin-top: 30px !important;
              @include mq {
                font-size: 1.4rem;
                margin-top: 15px !important;
              }
              &::before {
                content: url(../../img/educational_travel/icon-arrow-white.png);
                font-size: 1.6rem;
                right: 15px;
              }
              &:hover {
                background: $white;
                color: $main-color;
                &::before {
                  content: url(../../img/educational_travel/icon-arrow.png);
                }
              }
            }
          }
        }
      }
    }
  }
}
