.access-top {
  .inner {
    width: 1010px;
    @include mq {
      width: 100%;
    }
  }
  &-route {
    text-align: center;
    margin: 0;
    &:nth-child(n + 2) {
     // margin-top: 96px;
	margin-bottom:60px;
    }
    &-title {
      padding-top:50px;
      margin-bottom: 30px;
      color: #006FA2;
      font-family: $font-mont;
      font-weight: 500;
      font-size: 4rem;
      letter-spacing: 0.075em;
      line-height: 1;
      @include mq {
        font-size: 3rem;
        padding-top:25px;
        margin-bottom: 10px;
      }

      span{
        display: block;
        margin-top: 10px;
        font-family:$font-sans_serif;
        font-size: 1.6rem;
        color: #333;
        @include mq {
          font-size: 1.3rem;
        }
      }
      /*&::after ,
      &::before {
        position: absolute;
        content: "";
        width: 180px;
        border-bottom: 5px solid #000;
        right: 0;
        top: 50%;
        z-index: -1;
        transform: translate(0, -50%);
        @include mq {
          width: 30px;
        }
      }
      &::after {
        right: 0;
        transform: translate(50%, -50%);
      }
      &::before {
        left: 0;
        transform: translate(-50%, -50%);
      }*/
    }
    &-desc {
      margin-top: 30px;
      font-size: 2.2rem;
      @include mq {
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
      span {
        text-decoration: underline;
      }
    }
    &-detail {
      &._05 {
        margin: 30px 0 60px;
        a {
          min-width: auto;
          width: 350px;
          padding: 13px 30px 13px 15px;
          @include mq {
            width: 100%;
          }
        }
      }
    }
    &-park {
      margin: 30px auto 0;
      //width: 80%;
      height: auto;
      @include mq {
        width: 100%;
      }
    }
    &-schedule {
      margin: 30px auto 0;
     // width: 80%;
      height: auto;
      @include mq {
        width: 100%;
      }
    }
    &-map {
      width: 1010px;
      height: 945px;
      background: #000;
      margin-bottom: 100px;
      @include mq {
        width: 100%;
        height: 300px;
        margin-bottom: 40px;
      }
    }
    &-lead {
      font-weight: bold;
      display: inline-block;
      margin: 20px auto;
      position: relative;
      font-size: 2.8rem;
      padding: 0 27px;
      line-height: 1.5;
      @include mq {
        font-size: 2rem;
        margin: 20px auto;
        height: 36px;
        line-height: 33px;
      }
      span{
        font-family: $font-mont;
        font-weight: 500;
        font-size: 1.2rem;
        letter-spacing: 0.075em;
        display: block;
        margin-top: 8px;
        color: $main-color;

      }
      span.place{
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
      span.address{
        font-size: 2.0rem;
        margin-top: 30px;
        @include mq {
          font-size: 1.6rem;
        }
        br{
          display: none;
          @include mq {
            display: block;
          }
        }
      }
      
      /*&::after ,
      &::before {
        position: absolute;
        content: "";
        width: 11px;
        height:48px;
        top: -3px;
        @include mq {
          height: 36px;
        }
      }
      &::after {
        right: 0;
        background: url(/img/access/border-af.png);
        right: -10px;
        background-size: contain;
        background-repeat: no-repeat;
      }
      &::before {
        left: 0;
        background: url(/img/access/border-bf.png);
        left: -8px;
        background-size: contain;
        background-repeat: no-repeat;
        @include mq {
          left: -6px;
        }
      }*/
    }
    &-border {
      border-bottom: 1px solid #9FA0A0;
    }
    &-warn {
      font-size: 1.8rem;
      color: $red;
      margin: 20px 0;
      @include mq {
        font-size: 1.4rem;
      }
    }

  }



}