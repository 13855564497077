.concierge-top {
  margin: 0 auto;
  padding:0;
  max-width: 1000px;
  @include mq {
    padding: 30px 15px;
  }
  &-about {
    margin-bottom: 70px;
    &-title {
      font-size: 3rem;
      text-align: center;
      padding-top: 55px;
      position: relative;
      margin-bottom: 35px;
      &::before {
        transform: translate(-50%, 0);
        width: 60px;
        height: 44px;
        top: 0;
        left: 50%;
        background: url(../../../img/concierge/icon_plan_normal.png) no-repeat top center;
        content: " ";
        position: absolute;
      }
      @include mq {
        font-size: 2rem;
      }
    }
    &-tips {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 60px;
      justify-content: space-between;
      @include mq {
        margin: 15px 0;
        display: block;
      }
      > p {
        width: 421px;
        font-size: 1.8rem;
        line-height: 1.9em;
        letter-spacing: 1px;
        @include mq(pc) {
          order: 2;
        }
        @include mq {
          font-size: 1.3rem;
          width: 100%;
        }
      }
      > img {
        width: 549px;
        @include mq(pc) {
          order: 1;
        }
        @include mq {
          width: 100%;
        }
      }
    }
    &-exaple {
      margin-top: 37px;
      background: url(../../../img/concierge/bg_check.png) center center;
      padding: 40px;
      font-size: 16px;
      @include mq {
        margin-top: 60px;
        padding: 20px;
      }
      &-title {
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 33px;
        @include mq {
          font-size: 2rem;
          margin: 0 0 10px;
        }
        img {
          height: auto;
          width: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }
      .check-list {
        display: flex;
        flex-wrap: wrap;
        margin: -3px;
        padding: 38.5px 47px;
        justify-content: space-between;
        background: #fff;
        border-radius: 10px;
        @include mq {
          padding: 20px;
          font-size: 1.6rem;
        }
        li {
          width: calc(50% - 10px);
          font-weight: bold;
          position: relative;
          margin: 3px;
          padding: 8px 8px 8px 38px;
          text-indent: 0;
          @include mq {
            width: 100%;
            font-size: 1.4rem;
            letter-spacing: 1px;
          }
          &::before {
            position: absolute;
            background: url(../../img/concierge/icon_check.png) center no-repeat;
            content: "";
            width: 28px;
            height: 19px;
            margin-right: 8px;
            left: 0;
          }
        }
      }
    }
  }
  &-bragging {
    &-title {
      font-size: 3rem;
      text-align: center;
      padding-top: 55px;
      position: relative;
      margin-bottom: 35px;
      &::before {
        transform: translate(-50%, 0);
        width: 60px;
        height: 44px;
        top: 0;
        left: 50%;
        background: url(../../../img/concierge/icon_plan_normal.png) no-repeat top center;
        content: " ";
        position: absolute;
      }
      @include mq {
        font-size: 2rem;
      }
    }
    &-ribbon {
      position: relative;
      padding: 37px 0;
      margin-bottom: 60px;
      @include mq {
        background: #f4f5f8;
        margin: 0 -15px 15px;
        padding: 15px;
      }
      &-bg {
        position: relative;
        background: #f4f5f8;
        height: 320px;
        width: 0px;
        margin: 0 -200%;
        padding: 50px 200% 50px calc(251% + 40px);
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 1.5;
        @include mq {
          width: auto;
          padding: 15px;
          font-size: 16px;
          margin: 0;
          height: auto;
        }
        > div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          width: 1000px;
          align-items: center;
          position: relative;
          margin-bottom: 80px;
          @include mq {
            top: 0;
            left: 0;
            display: block;
            position: relative;
            transform: translate(0);
            width: 100%;
            margin: 0;
          }
          img {
            width: 130px;
            height: auto;
            @include mq {
              margin: 0 auto;
              text-align: center;
              display: block;
            }
          }
          h4 {
            width: 144px;
            height: 48px;
            padding: 7px 42px 11px;
            font-size: 2rem;
            margin-left: 30px;
            font-weight: bold;
            border: 2px solid $black02;
            @include mq {
              padding: 7px 0 11px;
              text-align: center;
              margin: 20px auto 30px;

            }
          }
          .caption {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-15%, -50%);
            width: 567px;
            z-index: 1;
            @include mq {
              display: block;
              position: relative;
              transform: translate(0);
              width: 100%;
            }
          }
        }

      }
    }
    &-staff {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      >li {
        background-size: contain;
        width: calc(50% - 20px);
        display: flex;
        padding-top: 23%;
        margin-bottom: 25px;
        &.staff01 {
          background: url(../../../img/concierge/staff01.png) top center no-repeat;
        }
        &.staff02 {
          background: url(../../../img/concierge/staff02.png) top center no-repeat;
        }
        &.staff03 {
          background: url(../../../img/concierge/staff03.png) top center no-repeat;
        }
        &.staff04 {
          background: url(../../../img/concierge/staff04.png) top center no-repeat;
        }
        @include mq {
          width: 100%;
          padding-top: 240px;
          background-size: contain;
        }
        .txt_box {
          padding: 40px;
          text-align: center;
          border-radius: 5px;
          width: calc(100% - 40px);
          margin: 0 auto;
          box-shadow: 5px 5px 10px #0000000d;
          background: #fff;
          position: relative;
          z-index: 10;
          @include mq {
            padding: 15px 15px 30px;
            width: calc(100% - 20px);
          }
          strong {

            @include mq {
              font-size: 18px;
              margin-bottom: 10px;
              display: block;
            }
          }

          > .name {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 5px;
            @include mq {
              font-size: 16px;
            }
            span {
              color: #bebebe;
              font-size: 14px;
              margin-top: -1px;
              display: block;
              letter-spacing: -0.001em;
              @include mq {
                font-size: 12px;
              }
            }
          }
          > .birth {
            color: #fff;
            font-weight: bold;
            font-size: 12px;
            background: #000;
            border-radius: 5px;
            width: 225px;
            padding: 7px;
            margin: 0 auto 20px;
            @include mq {
              margin: 0 auto 15px;
            }
          }
          > .desc {
            text-align: left;
            font-size: 1.4rem;
            @include mq {

            }
          }
        }
      }
    }
    &-exaple {
      position: relative;
      padding: 37px 0;
      //margin-bottom: 95px;
      @include mq {
        background: #f4f5f8;
        margin: 0 -15px 15px;
        padding: 15px;
      }
      &-bg {
        position: relative;
        background: #f4f5f8;
        height: 320px;
        width: 0px;
        margin: 70px -200%;
        padding: 50px 200% 50px calc(251% + 40px);
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 1.5;
        @include mq {
          padding: 15px;
          font-size: 16px;
          margin: 0;
          width: auto;
        }
        > div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          width: 1000px;
          align-items: center;
          position: relative;
          margin-bottom: 80px;
          @include mq {
            display: block;
            width: 100%;
            position: relative;
          }
          img {
            width: 600px;
            height: 400px;
            @include mq {
              width: 315px;
              height: 210px;
              margin: 0 auto;
            }
          }
          .caption {
            position: absolute;
            background-color: $white;
            padding: 36.5px 27px 22px 30px;
            right: 0;
            top: 50%;
            transform: translate(0%, -50%);
            flex-basis: 428px;
            width: 428px;
            height: 160px;
            z-index: 1;
            @include mq {
              position: relative;
              width: 100%;
              height: auto;
              padding: 20px 0 30px;
              background-color: unset;
              transform: translate(0);
            }
          }
        }

      }
    }

  }
  &-detail {
    background: url(../../../img/concierge/pic_bg.jpg) no-repeat;
    background-size: cover;
    position: relative;
    height: 376px;
    margin-top: 70px;
    @include mq {
      background-size: cover;
      margin: 30px -15px 0;
      padding: 20px;
      height: 100%;
    }
    &-box {
      background: rgba(255, 255, 255, 0.8);
      padding: 36px 50px;
      display: inline-block;
      @include mq {
        background: rgba(255, 255, 255, 0.8);
        display: inline-block;
        padding: 20px 30px;
        width: 100%;
        text-align: center;
      }
      @include mq(pc) {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
      }
      img {
        @include mq {
          width: 180px;
        }
      }
      &-btn {
        margin: 20px auto 0;
        position: relative;
        a {
          padding: 0 10px 0 4px;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 56px;
          text-align: center;
          display: block;
          border-radius: 5px;
          border: 2px solid #e60012;
          background: #e60012;
          color: #fff;
          letter-spacing: -0.01em;
          @include mq {
            font-size: 16px;
            line-height: 48px;
          }
          &:hover  {
            background: #fff;
            color: #e60012;
            img {
              transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transform: rotate(-10deg);
            }
          }
          i {
            margin-left: 16px;
            font-weight: bold;
            vertical-align: 1px;
            transition: none;
          }
        }
        img {
          position: absolute;
          width: 67px;
          left: -27px;
          bottom: -13px;
          @include mq {
            width: 50px;
            left: -13px;
          }
        }
      }
    }

  }
}