.contact-top {
  margin-top: 80px;
  width: 800px;
  margin: 100px auto;
  @include mq {
    width: calc(100% - 20px);
    margin: 50px auto 0;
  }
  &-form {
    .block {
      &:last-child {
        margin-bottom: 80px;
      }
      .ttl06 {
        text-align: left;
        font-weight: bold;
      }
      &.btnbox {
        display: flex;
        justify-content: space-around;
        @include mq {
          flex-direction: column-reverse;
          gap: 20px 0;
        }
        a {
          min-width: 350px;
        }
      }
      .btn._05{
        @include mq {
          padding: 0 15px;
        }
        &._prev a {
          background-color: $gray02;
          color: $black02;

        }
        &._submit a {
          background-color: #333333;

        }
        a {
          padding: 21px 50px;
          @include mq {
            font-size: 1.4rem;
            min-width: unset;
            padding: 21px 0;
            width: 100%;
          }
        }
      } 
      .desc {
        text-align: center;
        margin-bottom: 40px;
      }
      dl {
        display: flex;
        flex-wrap: wrap;
        gap: 41px 0;
        padding-bottom: 60px;
        margin-bottom: 51px;
        border-bottom: 1px solid #DDDDDD;
        @include mq {
          gap: 0 0;
        }
        dt {
          width: 189px;
          height: 60px;
          line-height: 20px;
          padding: 6px 0;
          top: 50%;
          font-size: 2rem;
          margin: 10px 0 0;
          @include mq {
            font-size: 1.6rem;
            height: 45px;
          }
          div {
            position: relative;
            display: inline-block;
            @include mq {
              padding-left: 10px;
            }
            &.reqire {
              padding-left: 85px;
              @include mq {
                padding-left: 75px;
              }
            }
            &.reqire::before {
              position: absolute;
              background-color: $red;
              content: "必須";
              padding: 5px 10px 4px;
              color: $white;
              font-weight: bold;
              left: 0;
              top: 50%;
              transform: translate(0, -50%);
              @include mq {
                font-size: 1.6rem;
              }
            }
          }
        }
        dd {
          font-size: 1.8rem;
          width: 611px;
          input[type="text"],
          textarea {
            background-color: #EFEFEF;
            border: none;
            border-radius: 5px;
          }
          div {
            padding: 12px 14px;
          }
        }
      }
      &._conf {
          
        dl {
          gap: 0 0;
          border-bottom: unset;
          padding-bottom: 0px;
        }
      }
    }
    
    &-policy {
      width: 712px;
      height: 317px;
      margin: 0 auto 37px;
      overflow-y: scroll;
      @include mq {
        width: 100%;
      }
    }
    &-accept {
      text-align: center;
      padding-bottom: 50px;
      margin-bottom: 50px;
      border-bottom: 1px solid #DDDDDD;
    }
    &-pass {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 80px;
      @include mq {
        flex-direction: column;
      }
      &-disp {
        width: 320px;
      }
      &-input {
        width: 280px;
        font-size: 1.8rem;
        letter-spacing: 0.25;
        @include mq {
          width: 320px;
          font-size: 1.6rem;
        }
        .title {
          margin-bottom: 14px;
          @include mq {
            margin: 14px 0;
          }
        }
        input {
          background-color: #EFEFEF;
          border: none;
          border-radius: 5px;
          height: 40px;
        }
      }
    }
  }
}
