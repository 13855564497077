// Base
@import 'base/base';
@import 'base/font-awesome';
@import url('https://code.jquery.com/ui/1.11.4/themes/smoothness/jquery-ui.css');
// Mixin
@import 'mixin/mixin';
@import 'mixin/z-index';
@import 'mixin/animation';

// Plubin
@import 'plugin/slick';
@import 'plugin/modaal';
@import 'plugin/MonthPicker.min';
@import "plugin/owl.carousel";
@import "plugin/animate";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Readex+Pro:wght@500&family=Noto+Serif+JP:wght@300;500&family=Roboto:wght@400;500;700;900&family=Zen+Kaku+Gothic+New:wght@500;700&family=Oswald:wght@500;600&display=swap');



// Layout
//----------------------------------------------
// ----------
// common
// ----------
@import 'layout/sidebar';

//-----------
// header
//-----------
@import 'layout/header';
@import 'layout/header_rsv';
@import 'layout/btn-spmenu';

//-----------
// footer
//-----------
@import 'layout/footer';

// ------------------------------
// TOP
// ------------------------------
@import 'page/top2';

// ------------------------------
// 下層 共通
// ------------------------------
@import 'page/parts';
@import 'page/reserve';
@import 'page/mypage';
@import 'page/password';
@import 'page/about';
@import 'page/access';
@import 'page/concierge';
@import 'page/faq';
@import 'page/plan_joriku';
@import 'page/ganshokun_ch';
@import 'page/museum';
@import 'page/term';
@import 'page/contact';
@import 'page/tour';
@import 'page/privacy';
@import 'page/error';
@import 'page/ticket';
@import 'page/holo_important';
@import 'page/lang';
@import 'page/company';
@import 'page/educational_travel';