// 共通・index
#p-notes,
#p-joriku {
  .sec-tour {
    padding-bottom: 80px;

    @include mq {
      padding-bottom: 50px;
    }

    .insec-tour-about {
      margin-top: 60px;

      @include mq {
        margin-top: 50px;
      }
    }

    .ttl-bdr {
      font-size: 3rem;
      @include ls(25);
      margin-bottom: 55px;
      display: flex;
      align-items: center;

      @include mq {
        font-size: 1.6rem;
        margin-bottom: 25px;
      }

      em {
        color: $main-color02;
        line-height: 1em;
        border-bottom: 3px solid currentColor;
        padding-bottom: 3px;

        @include mq {
          margin-top: 4px;
        }

        span {
          font-size: 3.6rem;

          @include mq {
            font-size: 1.9rem;
          }
        }
      }

      &:before,
      &:after {
        content: '';
        flex-grow: 1;
        height: 5px;
        background: $main-color02;
        display: block;
      }

      &:before {
        margin-right: 20px;

        @include mq {
          margin-right: 10px;
        }
      }

      &:after {
        margin-left: 20px;

        @include mq {
          margin-left: 10px;
        }
      }
    }

    .plan-list {
      @include plan-list;
    }
  }
  
  .sec-refund {
    padding: 80px 0 0;

    @include mq {
      padding: 40px 0 0;
    }
  }
  .sec-refund2 {
    padding: 80px 0 0;

    @include mq {
      padding: 40px 0 0;
    }
  }

  .sec-ship {
    background: #eff6f9;
    padding: 80px 0;

    @include mq {
      padding: 50px 0;
    }

    .insec-ship {
      +.insec-ship {
        margin-top: 100px;

        @include mq {
          margin-top: 60px;
        }
      }
    }

    .box {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }

      @include mq(pc) {
        padding-right: 50px;
      }
    }

    .img-wrap {
      @include mq(pc) {
        margin-right: 50px;
        width: calc(55% - 50px);
      }

      img {
        @include object-fit(cover);
      }
    }

    .text-wrap {
      @include mq(pc) {
        width: 45%;
      }

      @include mq {
        padding: 15px;
      }

      h3 {
        font-size: 4.6rem;
        font-weight: bold;
        @include ls(75);
        margin: -10px 0 40px;

        @include mq {
          font-size: 3.4rem;
          margin-bottom: 20px;
        }

        span {
          font-size: 1.8rem;
          @include ls(75);
          display: block;

          @include mq {
            margin-top: -5px;
          }
        }
      }
    }

    .def {
      font-size: 1.4rem;
      font-weight: bold;
      @include ls(75);
      margin-top: 25px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include mq {
        margin-top: 15px;
      }
    }

    .img-seat {
      margin-top: 20px;

      @include mq {
        margin-top: 10px;
      }
    }

    .btn {
      margin-top: 50px;

      @include mq {
        padding: 0 15px;
        margin-top: 30px;
      }
    }
  }

  .sec-prep {
    margin-top: 50px;

    .bg {
      background: url(../../img/common/bg_paper.jpg) repeat;
      background-size: 30px 30px;
      padding: 40px;

      @include mq {
        padding: 20px 15px;
      }
    }

    .box {
      +.box {
        margin-top: 40px;
      }

      h3 {
        color: $white;
        font-size: 2rem;
        @include ls(75);
        text-align: center;
        border-radius: 10px 10px 0 0;
        background: $main-color;
        padding: 20px;

        @include mq {
          font-size: 1.4rem;
        }
      }

      .text-wrap {
        background: $white;
        padding: 40px;

        @include mq {
          padding: 20px 15px;
        }
      }

      .list {
        @include mq(pc) {
          display: flex;
          justify-content: space-between;
        }

        li {
          @include mq(pc) {
            width: calc(50% - 15px);
          }

          +li {
            @include mq {
              margin-top: 30px;
            }
          }
        }
      }

      .text {
        font-size: 1.2rem;
        @include ls(75);
        margin-top: 25px;

        @include mq {
          margin-top: 15px;
        }
      }
    }
  }

  // 共通
  .sec-fee {
    padding: 100px 0 20px;

    @include mq {
      padding-top: 60px;
    }

    .plus {
      color: $main-color;
      font-size: 3.8rem;
      text-align: center;
      margin: 30px 0;

      @include mq {
        margin: 15px 0;
      }
    }

    h3 {
      font-size: 2rem;
      color: $white;
      text-align: center;
      background: $main-color;
      padding: 20px;
      border-radius: 10px 10px 0 0;

      &.bk {
        background: #333;
      }

      @include mq {
        font-size: 1.6rem;
      }

      &:nth-of-type(2) {
        background: #333;
      }

      span {
        font-size: 1.5rem;

        @include mq {
          font-size: 1.2rem;
          padding: 15px;
        }
      }

      &._sp {
        background: $super;
      }

      &._pr {
        background: $premium;
        margin-top: 60px;
      }
    }

    .blc {
      border: 3px solid #e1e1e1;
      border-top: 0;
      border-radius: 0 0 10px 10px;
      padding: 40px;

      @include mq {
        padding: 20px 15px;
      }

      &._01 {
        .box {
          &:nth-of-type(2) {
            h4 {
              border-bottom-color: $main-color;

              &:before {
                background: $main-color;
              }
            }

            .def {
              >dt {
                background: $main-color;
              }

              >div {
                dd {
                  em {
                    color: $main-color;
                  }
                }
              }
            }
          }
        }
      }

      &._02 {
        .box {
          h4 {
            border-bottom-color: #333;

            &:before {
              background: #333;
            }
          }

          .def {

            .bk,
            >dt {
              background: #333;
            }

            >div {
              dd {
                em {
                  color: #333;
                }
              }
            }
          }
        }
      }

      &._03 {
        padding-bottom: 40px;

        .text {
          @include ls(75);
          text-align: center;

          @include mq(pc) {
            font-size: 1.8rem;
          }

          em {
            color: $main-color;
            font-weight: bold;
          }
        }

        .table-wrap {
          margin-top: 30px;

          @include mq {
            margin: 20px -5px 0;
          }
        }

        table {
          font-weight: bold;
          width: 100%;

          @include mq {
            font-size: 1.2rem;
          }

          th,
          td {
            text-align: center;
          }

          thead {
            th {
              font-size: 1.6rem;
              @include ls(75);
              background: #e1e1e1;
              padding: 12px;

              @include mq {
                font-size: 1rem;
                padding: 5px;
              }

              &:not(:last-child) {
                border-right: 3px solid $white;
              }

              &:first-child {
                @include mq(pc) {
                  width: 115px;
                }
              }

              &:nth-child(3) {
                color: $white;
                background: $main-color;
              }
            }
          }

          tbody {

            th,
            td {
              @include ls(25);
              padding: 20px 30px;
              border-bottom: 1px solid #e1e1e1;

              @include mq {
                padding: 8px;
              }
            }

            th {
              text-align: left;
            }

            td {
              &:nth-child(2) {
                @include ls(75);
              }

              &:nth-child(3) {
                background: #eff6f9;

                em {
                  color: $main-color;
                }
              }

              em {
                font-family: $font-roboto;
                font-size: 2.2rem;
                margin-right: 7px;

                @include mq {
                  font-size: 1.5rem;
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }

      &._sp {
        .note-text {
          color: $super;
        }

        .def-box {
          dd {
            em {
              color: $super;
            }
          }

          &:nth-of-type(2) {
            h4 {
              border-bottom-color: $super;

              &:before {
                background: $super;
              }
            }

            dd {
              em {
                color: $super;
              }
            }
          }
        }
      }

      &._pr {
        .note-text {
          color: $premium;
        }

        .def-box {
          dd {
            em {
              color: $premium;
            }
          }

          &:nth-of-type(2) {
            h4 {
              border-bottom-color: $premium;

              &:before {
                background: $premium;
              }
            }

            dd {
              em {
                color: $premium;
              }
            }
          }
        }
      }
    }

    .note-text {
      color: $main-color;
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      margin-top: 0;

      @include mq {
        font-size: 1.5rem;
      }
    }

    .box {

      //margin-top: 30px;
      @include mq {
        //margin-top: 20px;
      }

      h4 {
        font-size: 2rem;
        @include ls(75);
        border-bottom: 3px solid #e1e1e1;
        padding: 13px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        @include mq {
          font-size: 1.6rem;
        }

        /* &:before {
          content: '';
          margin-right: 20px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: #e1e1e1;
          display: block;
          @include mq {
            margin-right: 15px;
          }
        }*/
        span {
          font-size: 1.2rem;
          margin-left: 15px;

          @include mq {
            font-size: 1.1rem;
            margin-left: 32px;
          }
        }
      }

      .def-wrap {
        @include mq(pc) {
          display: flex;
          justify-content: space-between;
        }
        &._col-1 {
          display: block;
          .def ,
          .def-box {
            @include mq(pc) {
              width: 100%;
            }
          }
        }
      }

      .def-box {
        @include mq(pc) {
          width: calc(50% - 15px);
        }


        &._02 {
          h4 {
            border-bottom-color: $main-color;

            &:before {
              background: $main-color;
            }
          }

          .def {
            >dt {
              background: $main-color;
            }

            >div {
              dd {
                em {
                  color: $main-color;
                }
              }
            }
          }
        }

        .def {
          @include mq(pc) {
            width: 100%;
          }

          +p {
            font-size: 1.4rem;
            @include ls(25);
            text-align: right;
            margin-top: 10px;

            @include mq {
              font-size: 1.1rem;
            }
          }
        }
      }

      .def {
        font-weight: bold;

        @include mq(pc) {
          width: calc(50% - 15px);
        }

        +.def {
          @include mq {
            margin-top: 30px;
          }
        }

        >dt {
          color: $white;
          font-size: 1.8rem;
          @include ls(25);
          padding: 12px 20px;
          background: #65b2d6;
          margin-top: 20px;

          @include mq {
            font-size: 1.5rem;
          }

          span {
            font-size: 1.5rem;

            @include mq {
              font-size: 1.2rem;
            }
          }
        }

        >div {
          padding: 16px 20px;
          border-bottom: 1px solid #e1e1e1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include mq {
            padding: 12px 20px;
          }

          dt {
            font-size: 1.8rem;

            @include mq {
              font-size: 1.4rem;
              font-size: 1.4rem;
            }
          }

          dd {
            font-size: 1.4rem;

            em {
              color: #65b2d6;
              font-size: 2.2rem;
              @include ls(25);
              margin-right: 8px;

              @include mq(pc) {
                vertical-align: -2px;
              }

              @include mq {
                font-size: 1.8rem;
              }

              &._black {
                color: $black01 !important;
              }
            }
          }
        }
      }
    }
  }
}

#p-joriku._normal {
  .sec-about {
    .note {
      color: $main-color;
      font-size: 1.4rem;
      @include ls(75);
      margin-top: -30px;
      margin-bottom: 40px;
      text-align: center;

      @include mq {
        margin-top: -15px;
      }
    }
  }

  .sec-fee {
    padding-bottom: 0;
  }

  .btm-bnr {
    margin-top: 80px;

    a {
      &:hover {
        opacity: .7;
      }
    }
  }
}

#p-joriku._yusen {
  .sec-about {
    .note-text {
      margin-top: 0;
    }

    .def {
      @include ls(75);
      margin-top: 40px;
      padding: 40px;
      background: url(../../img/common/bg_paper02.jpg) repeat;
      background-size: 30px 30px;

      @include mq {
        padding: 20px;
        margin-top: 20px;
      }

      dt {
        font-weight: bold;
      }

      dd {
        margin-top: 10px;
      }
    }
  }

  .sec-flow {
    padding-top: 75px;

    @include mq {
      padding-top: 60px;
    }

    .list {
      counter-reset: number 0;
      position: relative;

      &:before {
        content: '';
        width: 5px;
        height: 100%;
        background: #ade8f2;
        position: absolute;
        top: 0;
        left: 51px;

        @include mq {
          width: 3px;
          left: 23px;
        }
      }


      >li {
        @include ls(75);
        position: relative;

        @include mq(pc) {
          padding-right: 50px;
        }

        +li {
          margin-top: 80px;

          @include mq {
            margin-top: 50px;
          }
        }

      }

      h3 {
        color: #55b5c6;
        font-size: 2.6rem;
        @include ls(75);
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        @include mq {
          font-size: 1.5rem;
        }

        span {
          width: calc(100% - 132px);

          @include mq {
            width: calc(100% - 65px);
          }
        }

        &:before {
          font-size: 3.6rem;
          font-weight: bold;
          counter-increment: number 1;
          content: "0"counter(number) " ";
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: $white;
          border: 5px solid #ade8f2;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          margin-right: 32px;

          @include mq {
            font-size: 2rem;
            width: 50px;
            height: 50px;
            border-width: 3px;
            margin-right: 15px;
          }
        }
      }

      h3.no {
        color: #333;
        font-size: 2.6rem;
        @include ls(75);
        margin-bottom: 20px;
        display: block;

        &:before {
          display: none !important;
        }

        @include mq {
          font-size: 1.5rem;
        }
      }

      p {
        &:not(.note-text):not(.note) {
          margin-bottom: 30px;
        }
      }

      .li-conts {
        padding-left: 130px;

        @include mq {
          padding-left: 65px;
        }

        >* {
          &:not(:last-child) {
            margin-bottom: 60px;

            @include mq {
              margin-bottom: 40px;
            }
          }
        }

        >div:first-of-type {
          @include mq(pc) {
            margin-right: -50px;
          }
        }
      }

      .def {
        dt {
          font-size: 1.8rem;
          font-weight: bold;

          @include mq {
            font-size: 1.5rem;
          }
        }

        dd {
          margin-top: 10px;
        }

        table {
          width: 350px;

          @include mq {
            width: 100%;
          }

          th,
          td {
            border: 1px solid #ccc;
            padding: 10px 20px;
          }

          th {
            font-weight: normal;
            background: #eee;

            @include mq(pc) {
              width: 130px;
            }
          }
        }
      }

      .flex-box {
        display: flex;

        @include mq(pc) {
          justify-content: space-between;
        }

        @include mq {
          flex-direction: column;
        }

        +.flex-box {
          @include mq(pc) {
            margin-top: 40px;
          }
        }

        &._01 {
          .text-wrap {
            @include mq(pc) {
              padding-right: 50px;
              width: 70%;
            }

            @include mq {
              order: 2;
            }
          }

          .img-wrap {
            @include mq(pc) {
              max-width: 530px;
              width: 60%;
            }

            @include mq {
              margin-bottom: 15px;
              order: 1;
            }

            span {
              margin-top: 6px;
              display: block;
            }
          }
        }

        &._02 {
          .item {
            @include mq(pc) {
              width: calc(50% - 10px);
            }

            +.item {
              @include mq {
                margin-top: 30px;
              }
            }
          }

          img {
            margin-bottom: 20px;

            @include mq {
              margin-bottom: 12px;
            }
          }
        }

        &.align-top {
          align-items: flex-start;
        }
      }

      .mb0 {
        margin-bottom: 0px !important;
      }

      .mb20 {
        margin-bottom: 20px;
      }

      .mb30 {
        margin-bottom: 30px;
      }

      .mb40 {
        margin-bottom: 40px;
      }

      .note {
        color: $red;
        margin-top: 20px;

        @include mq {
          margin-top: 10px;
        }
      }

      .note-text {
        margin-top: 20px;
      }

      .ttl-bdr {
        font-size: 2.2rem;
        font-weight: normal;
        text-align: center;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq {
          font-size: 1.5rem;
          margin-bottom: 20px;
        }

        &:before,
        &:after {
          content: '';
          width: 50px;
          height: 1px;
          background: $text-color;
          display: block;

          @include mq {
            width: 40px;
          }
        }

        &:before {
          margin-right: 15px;
        }

        &:after {
          margin-left: 15px;
        }
      }

      .img-list {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        li {
          width: calc(50% - 10px);
        }
      }

      .holo-box {
        background: url(../../img/common/bg_paper.jpg) repeat;
        background-size: 30px 30px;
        border: 7px solid #eee;
        padding: 45px;

        @include mq {
          padding: 20px 15px;
          border-width: 4px;
        }

        .note {
          text-align: center;
          margin-bottom: 40px;

          @include mq {
            font-size: 1.2rem;
            margin-bottom: 25px;
          }
        }

        .def {
          margin-top: 15px;
        }

        .btn {
          margin-top: 50px;

          @include mq {
            margin-top: 20px;
          }

          a {
            &:after {
              @include mq {
                right: 15px;
              }
            }
          }
        }

        .img-list {
          margin-top: 60px;
          padding-right: 0;

          @include mq {
            margin-top: 25px;
          }
        }
      }
    }
  }
}

#p-joriku._premium {
  .sec-special {
    padding-top: 100px;

    .spe-list {
      li {
        padding: 80px 0;

        @include mq {
          padding: 40px 0;
        }

        &:nth-child(odd) {
          background: #eff6f9;
        }

        &:nth-child(even) {
          @include mq(pc) {
            .text-wrap {
              order: 2;
            }

            .img-wrap {
              order: 1;
            }
          }
        }

        >img {
          @include mq(pc) {
            margin-top: 35px;
          }
        }
      }

      h3 {
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;

        @include mq {
          font-size: 1.7rem;
          margin-bottom: 20px;
        }

        img {
          width: 90px;
          height: auto;
          margin: 0 auto 20px;
          display: block;

          @include mq {
            width: 67px;
            margin-bottom: 15px;
          }
        }

        em {
          color: #9e2556;
          font-size: 3rem;

          @include mq {
            font-size: 2.4rem;
          }
        }

        span {
          font-size: 1.8rem;
          display: block;

          @include mq {
            font-size: 1.4rem;
          }
        }
      }

      .note {
        color: #fe0000;
        font-size: 1.2rem;
        margin-top: 20px;

        @include mq {
          margin-top: 15px;
        }
      }

      .flex-box {
        display: flex;
        justify-content: space-between;

        @include mq {
          flex-direction: column;
        }

        .text-wrap {
          @include ls(75);
          @include lh(16, 24);
          padding: 0 50px;

          @include mq(pc) {
            min-width: 450px;
            width: 40%;
          }

          @include mq {
            padding: 0 15px;
          }
          p{
            font-family: "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
            font-weight: bold;
          }
        }

        .img-wrap {
          @include mq(pc) {
            width: 60%;
          }

          @include mq {
            margin-top: 20px;
          }

          img {
            @include object-fit(contain);
          }

          &._auto {
            img {
              width: 90%;
              max-width: 410px;
              margin: 0 auto;
              display: block;

              @include mq {
                width: 60%;
              }

              &.illust {
                width: 30%;

                @include mq {
                  width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}

#p-joriku._yusen {
  .sec-special {
    /*padding-top: 100px;*/

    .spe-list {
      li {
        padding: 80px 0;

        @include mq {
          padding: 40px 0;
        }

        &:nth-child(odd) {
          background: #eff6f9;
        }

        &:nth-child(even) {
          @include mq(pc) {
            .text-wrap {
              order: 2;
            }

            .img-wrap {
              order: 1;
            }
          }
        }

        >img {
          @include mq(pc) {
            margin-top: 35px;
          }
        }
      }

      h3 {
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;

        @include mq {
          font-size: 1.7rem;
          margin-bottom: 20px;
        }

        img {
          width: 90px;
          height: auto;
          margin: 0 auto 20px;
          display: block;

          @include mq {
            width: 67px;
            margin-bottom: 15px;
          }
        }

        em {
          color: #9e2556;
          font-size: 3rem;

          @include mq {
            font-size: 2.4rem;
          }
        }

        span {
          font-size: 1.8rem;
          display: block;

          @include mq {
            font-size: 1.4rem;
          }
        }
      }

      .note {
        color: #fe0000;
        font-size: 1.2rem;
        margin-top: 20px;

        @include mq {
          margin-top: 15px;
        }
      }

      .flex-box {
        display: flex;
        justify-content: space-between;

        @include mq {
          flex-direction: column;
        }

        .text-wrap {
          @include ls(75);
          @include lh(16, 24);
          padding: 0 50px;

          @include mq(pc) {
            min-width: 450px;
            width: 40%;
          }

          @include mq {
            padding: 0 15px;
          }
          p{
            font-family: "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
            font-weight: bold;
          }
        }

        .img-wrap {
          @include mq(pc) {
            width: 60%;
          }

          @include mq {
            margin-top: 20px;
          }

          img {
            @include object-fit(contain);
          }

          &._auto {
            img {
              width: 90%;
              max-width: 410px;
              margin: 0 auto;
              display: block;

              @include mq {
                width: 60%;
              }

              &.illust {
                width: 30%;

                @include mq {
                  width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}

#p-experience {
  .sec-movie {
    .movie {
      height: 484px;

      @include mq {
        height: 50vw;
      }
    }
  }

  .sec-plan {
    margin-top: calc(80px + 6.3vw);
    margin-bottom: calc(50px + 6.3vw);
    position: relative;

    @include mq {
      padding: 30px 0 40px;
      margin-top: calc(30px + 8.3vw);
      margin-bottom: calc(30px + 8.3vw);
    }

    >img {
      width: 100%;
      height: auto;
    }

    .inner-wrap {
      padding: 50px 0;
      background: #eff6f9;
    }

    .ttl05 {
      em {
        color: $main-color;
        font-size: 3.4rem;
        padding-bottom: 4px;
        background: url(../../img/plan_joriku/experience/bdr_wave.png) no-repeat;
        background-size: contain;
        background-position: left bottom;

        @include mq {
          font-size: 2rem;
        }
      }
    }

    .list {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }

      li {
        @include mq(pc) {
          width: calc(100% / 3 - 6px);
        }

        +li {
          @include mq {
            margin-top: 50px;
          }
        }
      }

      &._old {
        .sttl {
          @include mq(pc) {
            height: 84px;
          }
        }

        li {
          &:nth-child(1) {
            .text-box {
              background: $normal-old;
            }

            .btn {
              a {
                border-color: $normal-old;
              }
            }
          }

          &:nth-child(2) {
            .text-box {
              background: $yusen-old;
            }

            .btn {
              a {
                border-color: $yusen-old;
              }
            }
          }

          &:nth-child(3) {
            .text-box {
              background: $premium-old;
            }

            .btn {
              a {
                border-color: $premium-old;
              }
            }
          }
        }
      }

      &._new {
        li {
          .sttl {
            flex-direction: row;
          }

          h3 {
            strong {
              @include mq(pc) {
                @media screen and (max-width: 1600px) {
                  font-size: 1.9vw;
                }
              }
            }
          }

          &:nth-child(1) {
            .text-box {
              background: $super;
            }

            .btn {
              a {
                border-color: $super;
              }
            }
          }

          &:nth-child(2) {
            .text-box {
              background: $premium;
            }

            .btn {
              a {
                border-color: $premium;
              }
            }
          }

          &:nth-child(3) {
            .text-box {
              background: $standard;
            }

            .btn {
              a {
                border-color: $standard;
              }
            }
          }
        }
      }

      .img-wrap {
        width: 100%;
        height: 190px;

        img {
          @include object-fit(cover);
        }
      }

      .text-box {
        color: $white;
        text-align: center;
        padding: 30px 30px 40px;
        position: relative;
        height: calc(100% - 190px);

        @include mq(tablet) {
          padding: 15px 15px 35px;
        }
      }

      .sttl {
        font-size: 1.5rem;
        font-weight: bold;
        @include lh(15, 22);
        border: 1px solid $white;
        padding: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mq(pc) {
          height: 72px;
        }

        @media screen and (max-width: 1400px) and (min-width: 769px) {
          font-size: 1.3rem;
        }

        @include mq(tablet) {
          font-size: 1.2rem;
        }

        @include mq {
          padding: 7px;
        }

        strong {
          font-size: 1.8rem;
          color: #fff000;

          @include mq(tablet) {
            font-size: 1.4rem;
          }
        }

        em {
          color: #fff000;
        }
      }

      h3 {
        font-weight: bold;
        margin-top: 15px;

        >span {
          &:nth-child(1) {
            font-size: 1.6rem;

            @include mq {
              font-size: 1.2rem;
            }
          }

          &:nth-child(2) {
            font-size: 1.8rem;
            display: block;

            @include mq(spc) {
              font-size: 1.6rem;
            }

            @include mq {
              font-size: 1.4rem;
            }
          }
        }

        strong {
          font-size: 3.6rem;

          @include mq(tablet) {
            font-size: 2.4rem;

            @include mq(spc) {
              font-size: 2rem;
            }
          }
        }
      }

      .btn {
        position: absolute;
        bottom: -23px;
        left: 50%;
        transform: translateX(-50%);

        a {
          border-width: 2px;
          border-style: solid;
          font-size: 1.4rem;
          font-weight: bold;
          width: 200px;
          height: 46px;
          border-radius: 23px;
          background: $white;
          border: 2px solid #474747;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          &:hover {
            transform: scale(1.05);

            &:after {
              right: 17px;
            }
          }

          &:after {
            content: '';
            width: 12px;
            height: 10px;
            background: url(../../img/common/icon_arrow02_black.svg) no-repeat;
            background-size: contain;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            transition: .3s ease all;
          }
        }
      }
    }

    .btn-box._rsv {
      @include mq(pc) {
        margin-top: 83px;
      }
    }
  }

  .sec-flow {
    margin-top: 75px;

    @include mq(pc) {
      @include mq(spc) {
        font-size: 1.4rem;
      }
    }

    .list {
      position: relative;

      &:before {
        content: '';
        width: 5px;
        height: 100%;
        background: #a8d6ec;
        position: absolute;
        top: 0;
        left: 51px;

        @include mq {
          width: 3px;
          left: 23px;
        }
      }

      >li {
        @include ls(75);
        position: relative;

        +li {
          margin-top: 80px;

          @include mq {
            margin-top: 50px;
          }
        }

        &.last {
          margin: -100px 0 200px !important;

          @include mq {
            margin: -50px 0 120px !important;
          }
        }
      }

      h3.ttl {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 20px;

        @include mq(spc) {
          font-size: 2rem;
        }

        @include mq {
          font-size: 1.5rem;
          margin-bottom: 8px;
        }
      }

      .num-box {
        background: $white;
        padding-bottom: 20px;
        position: absolute;
        top: 0;
        left: 0;

        @include mq {
          padding-bottom: 10px;
        }

        .circle {
          font-weight: bold;
          color: $main-color;
          font-family: $font-roboto;
          font-size: 1.2rem;
          @include ls(35);
          line-height: 1.1;
          text-align: center;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 5px solid #a8d6ec;
          padding-top: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          @include mq {
            font-size: 1rem;
            width: 50px;
            height: 50px;
            border-width: 3px;
          }

          div {
            font-size: 3rem;
            @include ls(75);
            margin-right: -3px;

            @include mq {
              font-size: 1.6rem;
              margin-right: -1px;
            }
          }
        }

        .text {
          font-weight: bold;
          @include ls(75);
          @include lh(16, 24);
          text-align: center;
          margin-top: 10px;

          @include mq {
            font-size: 1rem;
            line-height: 1.3;
            margin-top: 5px;
            max-width: 50px;
          }
        }
      }

      .li-conts {
        padding-left: 130px;

        @include mq {
          padding-left: 65px;
        }
      }

      .ttl-box {
        margin-bottom: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include mq {
          margin-bottom: 15px;
        }

        .circle {
          color: $white;
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 1.3;
          text-align: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin-right: 15px;
          padding-top: 10px;
          background: #65b2d6;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          @include mq {
            font-size: 1rem;
            width: 55px;
            height: 55px;
            padding-top: 6px;
          }

          div {
            font-size: 2rem;

            @include mq {
              font-size: 1.2rem;
            }
          }
        }

        h3 {
          font-size: 2rem;
          @include ls(75);
          width: calc(100% - 115px);

          @include mq {
            font-size: 1.3rem;
          }

          em {
            font-size: 3rem;

            @include mq(pc) {
              @include mq(spc) {
                font-size: 2.4rem;
              }
            }

            @include mq {
              font-size: 1.6rem;
            }
          }
        }
      }

      .spot-box {
        &:not(:first-of-type) {
          margin-top: 80px;
        }

        .flex {
          padding-top: 40px;
          margin-bottom: 30px;
          position: relative;

          @include mq(pc) {
            display: flex;
            justify-content: space-between;
          }

          >div {
            @include mq(pc) {
              width: calc(50% - 10px);

              @include mq(spc) {
                &.text-wrap {
                  width: calc(70% - 10px);
                }

                &.img-wrap {
                  width: 30%;
                }
              }
            }
          }

          .img-wrap {
            @include mq(pc) {
              margin-top: -30px;
            }

            @include mq {
              width: 150px;
              position: absolute;
              top: 0;
              right: 0;
              transform: translateY(-40%);
            }
          }
        }
      }

      .att-box {
        padding: 60px 40px 40px;
        margin-top: 100px;
        background: url(../../img/common/bg_paper03.jpg) repeat;
        background-size: 22px 22px;
        border-radius: 15px;

        @include mq {
          padding: 30px 15px;
          margin-top: 120px;
        }

        .att-ttl-wrap {
          text-align: center;
          margin: -88px 0 30px;
          position: relative;

          @include mq {
            margin: -55px 0 15px;
          }

          /*&:before {
            content: '';
            width: 113px;
            height: 90px;
            display: block;
            background: url(../../img/common/img_ganshokun03.png) no-repeat;
            background-size: contain;
            position: absolute;
            top: -79px;
            left: 50%;
            transform: translateX(-50%);
            @include mq {
              width: 70px;
              height: 57px;
              top: -46px;
            }
          }*/
        }

        .att-ttl {
          font-size: 2rem;
          font-weight: bold;
          text-align: center;
          border: 3px solid #ffb637;
          background: $white;
          padding: 15px 65px;
          display: inline-block;
          position: relative;
          z-index: 1;

          @include mq {
            font-size: 1.3rem;
            padding: 8px 15px;
          }

          em {
            color: $main-color;
          }
        }

        .text {
          margin-top: 20px;

          @include mq {
            font-size: 1.2rem;
            margin-top: 10px;
          }
        }

        .recom-ttl {
          font-size: 2rem;
          font-weight: bold;
          text-align: center;
          margin: 50px 0 25px;
          display: flex;
          justify-content: center;

          @include mq {
            font-size: 1.2rem;
            margin: 30px 0 10px;
          }

          &:before,
          &:after {
            content: '';
            display: block;
            width: 2px;
            height: 24px;
            background: #333;
            margin: 0 15px;
          }

          &:before {
            transform: rotate(-15deg) translateY(4px);
          }

          &:after {
            transform: rotate(15deg) translateY(4px);
          }
        }

        .map {
          margin-top: 30px;

          @include mq {
            margin-top: 15px;
          }
        }

        .btn {
          margin-top: 30px;
        }
      }

      .btn-dtl {
        margin-top: 20px;

        a {
          font-size: 1.4rem;
          font-weight: bold;
          width: 240px;
          height: 46px;
          border: 2px solid #65b2d6;
          margin-left: auto;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          @include mq {
            margin-right: auto;
          }

          &:after {
            content: '';
            width: 14px;
            height: 12px;
            background: url(../../img/common/icon_arrow02_pink02.svg) no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            transition: .3s ease;
          }

          &:hover {
            &:after {
              right: 11px;
            }
          }
        }
      }

      .sail-def {
        margin-bottom: 60px;

        >div {
          @include mq(pc) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          }

          @include mq {
            position: relative;
          }

          +div {
            margin-top: 60px;

            @include mq {
              margin-top: 40px;
              padding-top: 100px;
            }
          }

          >* {
            @include mq(pc) {
              width: 50%;
            }
          }

          >dd {
            @include mq(pc) {
              @include mq(spc) {
                width: 40%;
              }
            }
          }

          >div {
            @include mq(pc) {
              padding-left: 50px;

              @include mq(spc) {
                width: 60%;
              }
            }

            @include mq {
              margin-top: 10px;
            }

            .img {
              transform: translateY(-25px);

              @include mq {
                width: 115px;
                height: 115px;
                position: absolute;
                top: -10px;
                right: 0;
              }

              &._left {
                img {
                  @include mq(pc) {
                    margin: 0 auto 0 20px;
                  }
                }
              }

              img {
                width: auto;
                display: block;
                margin: 0 auto;

                @include mq {
                  @include object-fit(contain);
                }
              }
            }

            dt {
              font-size: 2rem;
              font-weight: bold;
              @include ls(75);
              margin-bottom: 25px;

              @include mq {
                font-size: 1.5rem;
                margin-bottom: 10px;
              }
            }

            dd:not(.img) {
              @include ls(75);
              @include lh(16, 28);
            }
          }
        }
      }

      .flex-box {
        @include mq(pc) {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }

        +.flex-box {
          margin-top: 15px;

          @include mq {
            margin-top: 30px;
          }
        }

        &._start {
          @include mq(pc) {
            align-items: flex-start;
          }
        }

        >* {
          @include mq(pc) {
            width: calc(50% - 20px);
          }
        }

        .img-wrap {
          &._sp-col {
            @include mq {
              margin-top: -20px;
              display: flex;
              justify-content: space-between;

              >img {
                width: calc(50% - 5px);
              }
            }
          }

          img {
            +img {
              @include mq(pc) {
                margin-top: 15px;
              }
            }
          }
        }

        .text-wrap {
          @include mq {
            margin-top: 10px;
          }
        }

        h3 {
          font-size: 3rem;
          font-weight: bold;
          margin-bottom: 20px;

          @include mq(spc) {
            font-size: 2rem;
          }

          @include mq {
            font-size: 1.5rem;
            margin-bottom: 8px;
          }
        }

        p {
          @include ls(75);
          @include lh(16, 28);
        }
      }

      .img-list {
        display: flex;
        justify-content: space-between;

        >li {
          width: calc(50% - 10px);

          @include mq {
            width: calc(50% - 5px);
          }
        }
      }

      .snap-wrap {
        position: relative;
        margin-bottom: 30px;

        &._02 {
          .snap {
            @include mq(pc) {
              transform: translateY(37%);

              @include mq(spc) {
                width: 200px;
                transform: translateY(67%);
              }
            }
          }
        }
      }

      .snap {
        width: 22vw;
        max-width: 410px;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateY(50%);

        @include mq(spc) {
          width: 200px;
        }

        @include mq {
          width: 150px;
          transform: translateY(80%);
        }
      }

      .btn._pdf {
        a {
          @include mq {
            padding-left: 30px;
            height: 54px;
          }
        }
      }
    }

    .img-comment {
      margin: 64px auto 0;
      width: auto;
      display: block;

      @include mq {
        margin-top: 40px;
        width: 100%;
        max-width: 432px;
      }
    }
  }
}

#p-notes {
  @include ls(25);

  .sec-notes {
    .sec-01 {
      .notes-box {
        background: #eff6f9;
        border-radius: 20px;
        padding: 50px 30px 60px;

        @include mq {
          padding: 25px 15px;
        }
      }

      .notes-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        img {
          width: auto;
        }

        li {
          background: $white;
          border-radius: 20px;
          margin-top: 40px;
          width: calc(100% / 3 - 15px);

          @include mq {
            width: 100%;
            margin-top: 10px;
          }
        }

        dl {
          padding: 15px 26px 59px;

          @include mq {
            padding: 15px;
          }
        }

        dt {
          font-size: 1.8rem;
          font-weight: bold;
          height: 180px;
          padding-bottom: 25px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          border-bottom: 1px solid #e0e0e0;
          position: relative;

          @include mq {
            font-size: 1.5rem;
            height: auto;
            padding: 80px 0 10px;
          }

          .img-wrap {
            position: absolute;
            top: 36%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        dd {
          padding-top: 30px;
          @include lh(16, 24);

          @include mq {
            padding-top: 20px;
          }
        }
      }
      
      .btn {
        margin-top: 40px;

        @include mq {
          display: none;
        }

        a {
          font-weight: bold;
          background: $white;
          border: 2px solid $main-color02;
          width: 380px;
          height: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          &:hover {
            color: $main-color02;
          }

          &:before {
            content: '\f02f';
            color: $main-color;
            font-family: $font-awesome;
            font-size: 2.4rem;
            position: absolute;
            top: 50%;
            left: 26px;
            transform: translateY(-50%);
          }
        }
      }
    }

    .sec-02 {
      padding: 80px 0;

      @include mq {
        padding: 50px 0;
      }
    }

    .sec-03 {
      background: #eff6f9;
      padding: 40px 0 60px;

      h3 {
        color: $white;
        font-size: 1.8rem;
        font-weight: bold;
        background: $main-color;
        height: 79px;
        border-radius: 10px 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq {
          font-size: 1.4rem;
          height: auto;
          padding: 10px 15px;
        }
      }

      .center {
        text-align: center;
        margin: 20px auto;
      }

      .box {
        background: $white;
        border-radius: 0 0 10px 10px;
        padding: 20px;
        text-align: center;

        @include mq {
          padding: 15px;
        }

        .def {
          @include mq(pc) {
            margin-top: 8px;
          }

          >div {
            padding: 18px 8px;
            line-height:40px;
            @include mq(pc) {
              //  display: flex;
              //  justify-content: space-between;
            }

            +div {
              border-top: 1px solid #b7b7b7;
            }
          }

          dt {
            font-weight: bold;
            text-align: center;
            font-size: 2rem;

            @include mq(pc) {
              //width: 200px;
            }
          }

          dd {
            @include mq(pc) {
              //  width: calc(100% - 200px);
              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;

              span {
                // font-size: 90%;
              }
            }

          }
        }
      }

    }
  }

  .sec-cancel {
    padding-top: 80px;

    @include mq {
      padding-top: 50px;
    }

    .blc-wrap {
      padding: 63px 0 80px;
      background: url(../../img/common/bg_paper.jpg) repeat;
      background-size: 30px 30px;

      @include mq {
        padding: 20px 0;
      }
    }

    .blc {
      background: $white;
      padding: 38px 30px 59px;

      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }

      @include mq {
        padding: 15px 15px 30px;
      }
    }

    .box {
      width: calc(50% - 20px);

      @include mq {
        width: 100%;
      }

      +.box {
        @include mq {
          margin-top: 40px;
        }
      }

      h4 {
        color: $white;
        font-size: 1.8rem;
        background: #333;
        width: 100%;
        height: 57px;
        padding: 10px 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include mq {
          font-size: 1.4rem;
          height: auto;
          padding: 10px 15px;
        }
      }

      .text {
        padding: 25px 30px;

        @include mq {
          padding: 10px 15px;
        }

        em {
          color: $main-color;
          font-weight: bold;
        }
      }

      .def {
        font-weight: bold;
        margin-top: 15px;

        >dt {
          color: $white;
          background: $main-color;
          padding: 7px 30px 5px;

          @include mq {
            padding: 7px 15px 5px;
          }
        }

        >div {
          padding: 18px 30px;
          border-bottom: 1px solid #b7b7b7;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include mq {
            padding: 10px 15px;
          }

          dd {
            color: $main-color;
          }
        }
      }
    }
  }

  .sec-refund {
    padding: 80px 0 0;

    @include mq {
      padding: 40px 0 0;
    }
  }
  .sec-refund2 {
    padding: 80px 0 60px;

    @include mq {
      padding: 40px 0 0;
    }
  }

  
  .modal {
    &-refund.sec-fee {
      .blc {
        padding: 20px;
      }
      .box h4 {
        span {
          margin-left: 10px;
          margin-top: 10px;
        }
      } 
      .scroll {
        @include mq {
          overflow: auto;
          width: calc(100vw - 140px);
        }
      }
      .def-box + .def-box {
        margin-top: 40px;
      }
      .def {
        @include mq {
          width: 440px;
        }
       > div {
        display: flex;
        dt {
          width: 150px;

          @include mq {
            width: 60px;
          }
        }
        dd {
          width: 140px;
          @include mq {
            width: 100px;
          }
        } 
      }
      }
    }
  }
}

.right-fee-text {
  text-align: right;
  font-size: 1.3rem;
  margin: 0 0 10px;
}

.fee-box {
  margin-top: 40px;

  .table-wrap {
    box-shadow: 0 10px 14px #E7E7E7;

    @include mq {
      overflow-x: scroll;
    }
  }

  table {
    width: 100%;
    background: $white;

    @include mq {
      width: 600px;
    }

    th,
    td {
      text-align: center;
      border-right: 1px solid #CBCFD7;
      border-bottom: 1px solid #CBCFD7;

      &:last-child {
        border-right: 0;
      }
    }

    tr:last-child {

      th,
      td {
        border-bottom: 0;
      }
    }

    thead {

      th,
      td {
        width: 25%;
        border-bottom: 1px solid #CBCFD7 !important;
      }

      th {
        color: $white;
        @include ls(50);

        div {
          font-size: 1.25vw;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (min-width: 1650px) {
            font-size: 2rem;
          }

          @include mq {
            font-size: 1.5rem;
          }
        }

        &.plan01 {
          background: #09704A;
        }

        &.plan02 {
          background: #124394;
        }

        &.plan03 {
          background: #B3A000;
        }

        &.plan04 {
          background: #6A6A6A;
        }

        &.plan05 {
          background: #0C3060;
        }

        &.plan06 {
          background: #B03009;
        }
      }
    }

    tbody {

      th,
      td {
        font-weight: bold;
        padding: 15px;
      }

      th {
        @include ls(25);
      }

      tr.fee {
        td {
          font-family: $font-readex;
          font-size: 1.8rem;
          font-weight: normal;

          span {
            font-family: $font-sans_serif;
            font-weight: bold;
            margin-right: 3px;
          }
        }
      }
    }
  }

  .btn03 {
    margin-top: 26px;
  }
}

.flow-table {
  padding: 0 60px 100px;

  @include mq {
    padding: 0 15px 60px;
  }

  .fee-box {
    margin-top: 0;

    table {
      width: 100%;

      thead {
        th {
          padding: 20px;
          background: $main-color;
          color: #fff;
          font-size: 1.8rem;
        }
      }

      tbody {

        td,
        th {
          text-align: left;
        }

        th {
          font-size: 1.7rem;
          width: 30%;
        }
      }
    }
  }
}

.modal-timetable {
  .fee-box {
    margin-top: 40px;

    table {
      @include mq {
        width: 100%;

        thead {
          th {
            padding: 10px !important;
            font-size: 1.3rem;
          }
        }

        tbody {

          td,
          th {
            font-size: 1.1rem;
            padding: 8px !important;
          }
        }
      }
    }
  }

}

.sec-about {
  .mb20 {
    margin-bottom: 20px;
  }

  .mb40 {
    margin-bottom: 40px;
  }

  .red {
    color: #B03009;
    font-weight: bold;
  }

  .note-box {
    background: #fff;
    padding: 25px;
    margin: 0 0 60px;
    border: 3px double #ddd;

    @include mq {
      padding: 10px;
    }
  }

  .note-text {
    color: #222;
    font-weight: bold;
    text-decoration: underline;
    text-indent: 0;
    padding: 0;
  }

  .ttl06 {
    text-align: left;
    border-left: 5px solid $main-color;
    padding: 5px 0 5px 15px;
    font-size: 2.2rem;
    margin-bottom: 0;
  }

  .time-def {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;

    dt {
      font-weight: bold;
      font-size: 1.8rem;
    }
  }

  .sp-btn {
    font-weight: bold;
    color: $main-color;

    a {
      color: $main-color;

      @include mq {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}