.tour-note {
  width: 1000px;
  margin: 0 auto;
  @include mq { 
    width: calc(100% - 20px);
  } 
  &-block {
    margin-bottom: 20px;
  }
  &-table {
    width: 100%;
    margin: 0 auto;
    table {
      width: 100%;
      padding: 5px 10px;
      margin: 20px 0 30px 0;
    }
    tr:first-child th {
      border-top: 2px solid #e1e1e1;
      @include mq { 
        border-top: none;
      } 
    }
    tr:last-child th {
      border-bottom: 2px solid #e1e1e1;
    }
    th {
      @include mq(pc) {
        border-top: 2px solid $white;
        border-bottom: 2px solid $white;
        background: #e1e1e1;
        padding: 12px 15px 12px 20px;
        margin-left: 50px;
        text-align: left;
        width: 200px;
      }
      @include mq {
        display: block;
        width: 100%;
        padding: 20px 0px 0 0px;
        text-align: left;
      }
    }
    td {
      padding: 20px;
      border-top: 2px solid #e1e1e1;
      border-bottom: 2px solid #e1e1e1;
      @include mq { 
        width: 100%;
        display: block;
        padding: 10px 0 20px 0;
        border-bottom: none;
      } 
      .stat-active {
        padding: 5px 15px;
        background: $main-color;
        border-radius: 14px;
        color: $white;
      }
      select,
      input {
        height: 40px;
        margin: 2.5px;
        padding: 0 5px;
        border: 1px solid $gray03;
        border-radius: 3px;
        @include mq(pc) {
        }
        @include mq { 
          background: $white;
        } 
      }
    }
    .value {
      font-weight: bold;
    }
    
  }
  &-list {
    margin-top: 10px;
    dt {
      font-weight: bold;
    }
  }
  &-caution {
    border: 2px solid $red;
    background-color: #FCF8F6;
    padding: 20px 10px;
    margin-top: 10px;
    color: $red;
    dt {
      font-weight: bold;
    }
  }
  &-pdf.btn._02 a {
    margin: 10px 0 30px auto;
    background-color: $black01;
    @include mq {
      margin: 10px auto 30px ;
    }
    &:hover {
      background-color: $black02;
    }
  }
}
