.rsv-info-def {
  dt {
    font-size: 1.6rem;
  }

  dd {
    &:before {
      content: '';
      display: inline-block;
      vertical-align: -1px;
      margin-right: 8px;
    }

    &.date {
      font-size: 1.6rem;
      margin-top: 10px;

      &:before {
        width: 16px;
        height: 14px;
        background: url(/img/common/icon_calendar.svg) no-repeat;
        background-size: contain;
      }
    }

    &.port {
      margin-top: 5px;

      &:before {
        width: 15px;
        height: 16px;
        background: url(/img/common/icon_port.svg) no-repeat;
        background-size: contain;
        vertical-align: -2px;
      }
    }
  }
}

.rsv-info-detail {
  background: $gray01;
  padding: 20px;
  margin-top: 10px;

  @include mq {
    padding: 15px;
  }

  .number,
  .ttl {
    font-size: 2.2rem;
    font-weight: bold;
  }

  dd {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    a {
      text-decoration: underline;
      word-break: break-all;

      &:hover {
        text-decoration: none;
      }
    }
  }

  li {
    position: relative;
    padding-left: 15px;

    &:before {
      content: "・";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.rsv-info-text {
  line-height: 24px;
  margin: 0 0 5px;

  +.rsv-info-text {
    margin-top: 35px;
  }

  span {
    font-size: 1.8rem;

    @include mq {
      font-size: 1.6rem;
    }
  }

  dt {
    font-weight: bold;
  }

  >dt {
    font-size: 1.8rem;
    margin-bottom: 5px;

    @include mq {
      font-size: 1.6rem;
    }
  }
}

.gray-bdr-box {
  &01 {
    border: 1px solid #D6D6D6;
    padding: 20px;
    width: 100%;
    text-align: center;
    margin: 25px 0 35px;
  }

  &02 {
    border: 4px solid $gray01;
    padding: 30px;
    width: 100%;
  }
}

#p-rsv {

  // p-rsv common
  .ticket {
    $this: &;
    color: $white;
    font-size: 1.2rem;
    font-weight: bold;
    width: 65px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(tablet) {
      font-size: 1.0rem;
      min-width: 38px;
      width: 100%;
      height: 16px;
    }

    &._sp {
      background: $super;
    }

    &._pr {
      background: $premium;
    }

    &._st {
      background: $standard;
    }

    &._triton {
      width: 50px;
      &:hover{
        opacity: 0.7;
      }
      img{
        width: 40px;
      }
    }

    &._gray {
      background: #B8B8B8 !important;

      span {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    span {
      font-family: $font-roboto;
      font-size: 1.3rem;
      font-weight: 500;
      text-align: center;
      margin-left: 7px;
      width: 25px;

      @include mq {
        font-size: 1.2rem;
        width: 20px;
        margin-left: 2px;
      }
    }

    +#{$this} {
      margin-top: 3px;
    }
  }

  .blc {
    @include mq(pc) {
      @include mq(tablet) {
        width: calc(100% - 40px);
        margin: 0 auto;
      }
    }
  }

  .rsv-flow {
    margin: 25px auto 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include mq {
      margin: 20px auto;
    }

    li {
      font-size: 1.3rem;
      text-align: center;
      width: 120px;

      span {
        &:first-child {
          font-family: $font-roboto;
          font-size: 1.4rem;
          font-weight: 500;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background: #FCF8F6;
          margin: 0 auto 7px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:last-child {
          @include mq {
            display: none;
          }
        }
      }

      &.active {
        span {
          &:first-child {
            color: $white;
            background: $main-color;
          }
        }
      }

      &.done {
        span {
          &:first-child {
            color: $white;
            background: #707070;
          }
        }
      }
    }
  }

  .btm-btn-box {
    width: 100%;
    margin: 20px 0;
    //margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq {
      padding: 0 20px;
      //margin-top: 10px;
      flex-direction: column;
    }

    &._reverse {
      >*:first-of-type {
        @include mq(pc) {
          order: 2;
        }
      }

      >*:last-of-type {
        @include mq(pc) {
          order: 1;
        }
      }
    }

    &._gray {

      // background: #D6D6D6;
      // margin-top: 0;
      @include mq {
        //  margin-top: 0;
      }

      .prev {
        background: $white;
      }
    }

    .prev,
    .next {
      font-size: 1.6rem;
      font-weight: bold;
      @include ls(50);
      height: 57px;
      border-radius: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px;

      @include mq(pc) {
        width: 350px;
      }

      @include mq {
        width: 100%;
        height: 40px;
      }
    }

    .prev {
      background: #e6e6e6;

      &:hover {
        @include mq(pc) {
          background: $hover-color02;
        }
      }

      @include mq {
        order: 2;
        margin-top: 14px;
      }
    }

    .next {
      color: $white;
      background: $key-color;
      position: relative;

      @include mq {
        order: 1;
      }

      &:hover {
        @include mq(pc) {
          background: $key-color-hover;
        }
      }

      /* &:after {
        content: '';
        width: 6px;
        height: 10px;
        background: url(/img/common/icon_arrow01_white.svg) no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }*/
    }
  }

  .blc {
    +.blc {
      margin-top: 40px;
    }
  }

  &._01 {
    .ship-type {
      border: 1px solid #D6D6D6;
      padding: 20px;
      margin: 20px 0 40px;

      @include mq {
        margin: 10px 0 20px;
        padding: 10px;
      }

      .title {
        font-size: 2.4rem;
        text-align: center;
        margin: 0 auto 10px;

        @include mq {
          font-size: 2rem;
        }
      }

      .text {
        text-align: center;
        line-height: 1.5;

        @include mq {
          text-align: left;
        }
      }

      span {
        font-weight: bold;
        font-size: 1.8rem;
        color: #000;

        @include mq {
          font-size: 1.6rem;
        }
      }

      .triton {
        color: #fff;
        padding: 1px 5px;
        text-align: center;
        display: inline-block;
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0 5px;
        background: $main-color;

        @include mq {
          margin: 0 3px 0;
          transform: scale(1);
          line-height: 1.3;
        }
      }

      .type-list {
        flex-wrap: nowrap;
        margin: 20px 0 0 0;
        display: flex;

        @include mq {
          display: block;
        }

        .ship-area {
          width: 48%;
          margin: 0 0 0 0;
          padding: 5px 2% 5px 0;
          border-right: 1px solid #ddd;

          &:last-child {
            border-right: none;
            margin: 0;
            padding: 5px 0 5px 2%;
          }

          @include mq {
            width: 100%;
            margin: 0 0 20px;
            padding: 0 0 20px;
            border-right: none;
            border-bottom: 1px solid #ddd;

            &:last-child {
              border-bottom: none;
              margin: 0;
              padding: 0 0 10px;
            }

          }

          h3 {
            font-size: 2rem;
            margin: 0 auto 5px;
            text-align: center;
            padding: 0 0 3px;

            @include mq {
              font-size: 1.7rem;
            }
          }

          figure {
            width: 200px;
            float: left;
            margin: 0 15px 10px;

            @include mq {
              float: none;
              width: 100%;
              box-sizing: border-box;
              margin: 0 0 10px;
            }
          }

          p {
            font-size: 1.2rem;
            text-align: left;

            .btn {
              display: block;
              color: $main-color;
              font-size: 1.2rem;
              border: 1px solid $main-color;
              width: 150px;
              height: 30px;
              border-radius: 14px;
              margin: 8px auto 0;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                background: $main-color;
                color: #fff;
              }

            }
          }

        }

      }
    }

    .ticket-info-box {
      //background: #F6F6F6;
      border: 1px solid #D6D6D6;
      padding: 20px;
      margin: 20px 0 40px;

      @include mq {
        margin: 10px 0 40px;
        padding: 10px;
      }

      .sample {
        background: $white;
        padding: 18px 8px 2px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        margin-right: 20px;
        position: relative;

        @include mq(pc) {
          width: 136px;
        }

        @include mq {
          padding: 6px 2px 0;
          margin-right: 6px;
          max-width: 136px;
          width: 15vw;
          min-width: 54px;
        }

        .day {
          font-family: $font-roboto;
          font-size: 1.5rem;
          font-weight: bold;
          position: absolute;
          top: 6px;
          left: 8px;

          @include mq {
            font-size: 1.2rem;
            top: 3px;
            left: 1px;
          }
        }

        .def {
          >div {
            padding-bottom: 6px;
            margin-bottom: 6px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-image: linear-gradient(to right, transparent, #ADADAD 2px, transparent 2px);
            background-size: 4px 1px;
            background-position: left bottom;
            background-repeat: repeat-x;


            @include mq {
              flex-direction: column;
              padding-bottom: 4px;
              margin-bottom: 4px;
            }
          }
        }

        dt {
          color: rgba(107, 107, 107, .8);
          font-family: $font-roboto;
          font-size: 1.1rem;
          font-weight: 500;

          @include mq(pc) {
            margin-right: 10px;
          }

          @include mq {
            font-size: 1rem;
          }
        }

        dd {
          @include mq {
            width: 100%;
            max-width: 70px;
          }

          >div {
            background: #F5F5F5;
            border: 1px solid #D6D6D6;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            transition: .2s ease;

            @include mq(pc) {
              width: 89px;
              height: 72px;
            }

            @include mq {
              padding: 4px 3px;
            }

            cursor: default;

            .ticket {
              +.ticket {
                margin-top: 3px;
              }
            }
          }
        }

        .holo {
          color: #898989;
          font-size: 1.0rem;
          font-weight: bold;
          margin-top: -3px;

          @include mq {
            text-align: center;
            transform: scale(.8);
            white-space: nowrap;
          }

          span {
            color: #00CBFF;
            font-size: 1.3rem;
            vertical-align: -1px;

            em {
              font-size: 1.0rem;
            }

            @include mq(pc) {
              margin-left: 6px;
            }

            @include mq {
              font-size: 1.5rem;
              line-height: 1.2em;
              display: block;
            }
          }
        }
      }

      .ticket-def {
        background: $white;
        width: 100%;

        //padding: 16px 10px;
        @include mq {
          //padding: 13px;
        }

        .type-ttl {
          font-size: 1.4rem;
          font-weight: bold;
        }

        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;       

          dt {
            @include mq(pc) {
              margin-right: 8px;
            }

            .ticket {
              font-size: 1.4rem;
              width: 81px;
              height: 22px;

              @include mq {
                font-size: 1.3rem;
                width: 80px;
              }
            }
          }
        }

        .wrap._01 {
          @include mq(pc) {
            display: flex;
            justify-content: center;
            align-content: center;
          }

          >div {
            @include mq(pc) {
              margin-right: 10px;
            }
          }

          .item {
            @include mq{
              margin-top: 6px;
              &:first-child {
                margin-top: 0;
              }
            }

            dd {
              font-weight: bold;

              @include mq {
                margin: 5px 0 0 10px;
              }

              &.sp-text {
                color: $super;
              }

              &.pr-text {
                color: $premium;
              }

              &.st-text {
                color: $standard;
              }

              &.triton-text {
                a{
                  color: $triton;
                  text-decoration: underline;
                  &:hover{
                    opacity: 0.7;
                    text-decoration: none;
                    }
                  }
                }
            }
          }

          .btn {
            @include mq {
              margin-top: 6px;
            }

            a {
              @include mq {
                min-width: 100%;
              }
            }
          }
        }

        .wrap._02 {
          margin-top: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          >div {
            @include mq {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
              margin-top: 0;
            }

            &:not(:last-child) {
              margin-right: 40px;

              @include mq {
                margin-right: 20px;
              }
            }
          }

          dt {
            @include mq {
              margin-right: 6px;
            }

            span {
              color: #BFC3CF;
              font-size: 1.2rem;
              font-weight: bold;
              background: #E1E5EB;
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          dd {
            font-size: 1.3rem;
          }
        }
      }

      .wrap._03 {
        margin-top: 15px;
        @include mq(pc) {
          display: flex;
          justify-content: center;
          align-content: center;
          font-size: 1.3rem;
        }

        >div {
          @include mq(pc) {
            margin-right: 40px;
          }
        }

        .item {
          dt {
            font-weight: bold;
          }
        }

      }

      .holo-text {
        margin-top: 20px;

        @include mq {
          width: 100%;
          margin-top: 12px;
        }

        p {
          font-size: 1.3rem;
        }

        .holo-def {
          margin-top: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          >div {
            font-size: 1.3rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            +div {
              margin-left: 20px;
            }
          }

          dt {
            color: #00CBFF;
            font-weight: bold;
            margin-right: 8px;

            &._yellow {
              color: #F9BF00;
            }

            &._gray {
              color: #B8B;
            }
          }
        }
      }
    }

    .calendar-box {
      margin-top: 20px;
      padding-bottom: 20px;

      .month-area {
        text-align: center;
        position: relative;

        .arrow {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 3px solid $key-color;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include mq{
            width: 40px;
            height: 40px;
          }

          &:hover {
            background: $hover-color;
          }

          &._next {
            padding-left: 2px;
            right: 0;
          }

          &._prev {
            padding-right: 2px;
            left: 0;

            &:after {
              transform: rotate(180deg);
            }
          }

          &:after {
            content: '';
            width: 9px;
            height: 15px;
            background: url(../../img/common/icon_arrow01.svg) no-repeat;
            background-size: contain;
          }
          &._invalid{
            border-color: #D6D6D6;
            &:hover {
              background: transparent;
            }
            &:after{
              background: url(../../img/common/icon_arrow01_gray.svg) no-repeat;
              background-size: contain;
            }
          }
        }

        .month {
          font-family: 'Roboto', "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
          font-size: 2.4rem;
          font-weight: bold;
          letter-spacing: .08em;

          @include mq {
            font-size: 1.7rem;
          }
        }
      }

      table {
        margin-top: 20px;
        width: 100%;
        table-layout: fixed;
        background: #fff;

        thead {
          background: #505050;
          color: $white;

          th {
            border: 1px solid #d6d6d6;
            font-size: 1.7rem;
            padding: 5.5px;
            @include mq{
              font-size: 1.4rem;
            }
            &:first-child {
              background: #D64900;
            }

            &:last-child {
              background: #008EED;
            }
          }
        }

        tbody {
          td {
            width: calc(100% / 7);
            border: 1px solid #d6d6d6;
            padding-bottom: 12px;
            vertical-align: top;
            position: relative;
            @include mq{
              padding-bottom: 8px;
            }

            &.holiday {
              .day{
                background: #FCE4E4;
              }
            }

            &.td-gray {
              background: #F6F6F6;
            }
          }

          tr {
            td {
              &:first-child {
                .day {
                  color: #D64900;
                }
              }

              &:last-child {
                .day {
                  color: #008EED;
                }
              }
            }
          }

          .day {
            font-family: $font-roboto;
            font-size: 1.5rem;
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            height: 52px;
            background: #EAEDF3;

            @include mq {
              font-size: 1.2rem;
              height: 42px;
            }
            .note{
              font-size: 1.1rem;
              line-height: 1;
              letter-spacing: .05em;
              width: 100%;
              text-align: center;
              color: #D64900;
              margin-top: -10px;
              @include mq{
                transform: scale(0.8);
              }
            }
          }

          .def {
            padding: 10px 10px 0;
            @include mq(sspc){
              padding: 6px 2px 0;
            }
            >div {
              margin-bottom: 8px;
              @include mq(spc){
                margin-bottom: 6px;
                min-height: 126.5px;
              }
              @include mq(sspc){
                min-height: 106.5px;
              }
              &.disable{
                background: #E1E5EB;
                border-radius: 10px;
                padding: 11px;
                position: relative;
                min-height: 100px;
                @include mq(spc){
                  min-height: 126.5px;
                }
                @include mq(sspc){
                  border-radius: 6px;
                  padding: 6px 3px;
                  min-height: 136.5px;
                }
                dt{
                  color: #BFC3CF;
                }
                dd{
                  font-size: 2.6rem;
                  font-weight: bold;
                  color: #BFC3CF;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  @include mq(sspc){
                    font-size: 1.6rem;
                  }
                }
              }
            }
            
            input[type="radio"] {
              display: none;

              &:checked {
                +label {
                  border-color: #0966AC;
                  background: #0966AC;

                  dt{
                    color: $white;
                  }

                  .ticket {
                    background: #0966AC;
                    span{
                      background: #0966AC;
                      color: $white;
                      border-color: $white;
                    }
                  }
                  .landing{
                    background: #1680CF;
                    color: $white;
                    &:before{
                      background: url(/img/common/icon_cruise_white.svg) no-repeat center center/contain;
                    }
                  }
                }
              }
            }
            label {
              border: 3px solid #E8E8E8;
              border-radius: 10px;
              display: block;
              padding: 8px;
              cursor: pointer;
              transition: .2s ease;
              &:hover {
                background-color: #E8E8E8;
              }
              @include mq(spc){
                margin-bottom: 6px;
                padding-bottom: 42px;
              }
              @include mq(sspc){
                border-width: 1px;
                border-radius: 6px;
                padding: 6px 3px 35px;
              }
              &.icon_triton{
                background: url(../../img/rsv/icon_triton.png) no-repeat 7px 60px;
                &:hover {
                  background-color: #E8E8E8;
                }
                @include mq(spc){
                  background-position: center 118px;
                  margin-bottom: 6px;
                  padding-bottom: 42px;
                }
                @include mq(sspc){
                  background-position: center 103px;
                  padding-bottom: 35px;
                }
              }
            }
            dl{
              display: flex;
              @include mq(spc){
                display: block;
              }
            }
            dt{
              font-size: 1.5rem;
              font-weight: bold;
              letter-spacing: .025em;
              color: $black02;
              @include mq(pc) {
                min-width: 31px;
                margin-right: 9px;
              }
              @include mq(spc){
                text-align: center;
                margin: 0 0 4px;
              }
              @include mq(sspc) {
                font-size: 1.2rem;
              }
            }
            .ticket{
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: none;
              width: auto;
              height: auto;
              span{
                font-family: $font-roboto;
                font-size: 1.4rem;
                letter-spacing: .025em;
                margin: 0;
                @include mq{
                  font-size: 1.3rem;
                }
              }
              .item{
                font-size: 1.2rem;
                font-weight: 700;
                letter-spacing: .08em;
                text-align: center;
                color: $white !important;
                width: 33px;
                border-radius: 8px;
                padding: 1px;
                margin-right: 4px;
                border: 1px solid;
                @include mq{
                  font-size: 1rem;
                  width: 6vw;
                  border-radius: 6px;
                  margin-right: 0;
                }
              }
              &._sp{
                span{
                  color: $super;
                }
                .item{
                  background: $super;
                  border-color: $super;
                }
              }
              &._pr{
                span{
                  color: $premium;
                }
                .item{
                  background: $premium;
                  border-color: $premium;
                }
              }
              &._st{
                span{
                  color: $standard;
                }
                .item{
                  background: $standard;
                  border-color: $standard;
                }
              }
              &._empty{
                .item{
                  opacity: .3;
                }
                span{
                  &:not(.item){
                    color: #B9BECE;
                  }
                }
              }
              +.ticket{
                margin-top: 6px;
              }
            }
            .landing {
              font-size: 1.2rem;
              font-weight: bold;
              letter-spacing: .05em;
              color: #006FA2;
              background: #ECF6FB;
              display: block;
              border-radius: 8px;
              padding: 3px 4px 3px 28px;
              margin-top: 6px;
              position: relative;
              @include mq{
                font-size: 1rem;
                text-align: center;
                border-radius: 6px;
                padding: 3px;
              }
              &:before{
                content: "";
                display: inline-block;
                width: 19px;
                height: 14px;
                background: url(/img/common/icon_cruise.svg) no-repeat center center/contain;
                position: absolute;
                top: 50%;
                left: 6px;
                transform: translateY(-50%);
                @include mq{
                  content: none;
                }
              }
            }
          }
          .holo {
            color: #859D95;
            font-size: 1.4rem;
            font-weight: bold;
            margin: 0 10px;
            background: #E1F9F2;
            border-radius: 8px;
            padding: 3px 12px;
            @include mq(spc){
              font-size: 1.2rem;
              text-align: center;
            }
            @include mq {
              font-size: 1rem;
              border-radius: 6px;
              padding: 3px 0;
              margin: 0;
              text-align: center;
              transform: scale(.8);
            }
            &._yellow {
              background: #F9F3E1;
              span{
                color: #F9BF00;
              }
            }

            &._gray {
              background: #E1E5EB;
              color: #BFC3CF;
              span{
                color: #BFC3CF;
              }
            }

            span {
              color: #00C488;
              font-size: 1.4rem;
              font-weight: bold;

              @include mq(pc) {
                margin-left: 10px;
              }
              @include mq(spc){
                display: block;
                margin-left: 0;
              }
              @include mq {
                font-size: 1.3rem;
                line-height: 1.2em;
              }
            }
          }
        }
      }
    }

    .port-box {
      background: #F6F6F6;
      padding: 20px;

      @include mq {
        padding: 30px 12px;
        margin: 0 -20px;
      }

      .port-box-inner {
        background: #fff;
        padding: 20px;

        @include mq {
          padding: 10px;
        }

        h2 {
          margin-top: 10px;

          @include mq {
            margin-top: 0px;
          }
        }

        .area-map {
          margin: 0 0 20px;

          figure {
            margin: 0 auto 5px;
          }

          .point {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            @include mq {
              font-size: 1.1rem;
            }

            li {
              margin: 0 20px 0 0;

              &:before {
                content: "";
                width: 21px;
                height: 21px;
                display: inline-block;
                position: relative;
                margin: 0 5px 0 0;

                top: 5px;
              }

              &.point01 {
                &:before {
                  background: url(/img/rsv/mark_g.svg) left top no-repeat;
                }
              }

              &.point02 {
                &:before {
                  background: url(/img/rsv/mark_m.svg) left top no-repeat;
                }
              }

              &.point03 {
                position: relative;
                top: -6px;

                &:before {
                  background: url(/img/rsv/mark_i.svg) left top no-repeat;
                  height: 27px;
                  top: 8px;
                }
              }

              &.point04 {
                position: relative;
                top: -6px;

                &:before {
                  background: url(/img/rsv/mark_t.svg) left top no-repeat;
                  height: 27px;
                  top: 8px;
                }
              }

              &.point05 {
                &:before {
                  background: url(/img/rsv/no_1.svg) left top no-repeat;
                }
              }

              &.point06 {
                &:before {
                  background: url(/img/rsv/no_2.svg) left top no-repeat;
                }
              }

              &.point07 {
                &:before {
                  background: url(/img/rsv/no_3.svg) left top no-repeat;
                }
              }

              &.point08 {
                &:before {
                  background: url(/img/rsv/no_4.svg) left top no-repeat;
                }
              }

              &.point09 {
                &:before {
                  background: url(/img/rsv/no_5.svg) left top no-repeat;
                }
              }

              &.point10 {
                &:before {
                  background: url(/img/rsv/no_6.svg) left top no-repeat;
                }
              }

              &.point11 {
                &:before {
                  background: url(/img/rsv/no_7.svg) left top no-repeat;
                }
              }
            }
          }
        }
      }

      @include mq {
        padding: 15px;
        margin-top: 10px;
      }

      dl {
        @include mq(pc) {
          text-align: center;
        }
      }

      dt {
        font-size: 1.8rem;
        font-weight: bold;

        @include mq {
          font-size: 1.8rem;
          text-align: center;
        }
      }

      dd {
        @include mq(pc) {
          width: 100%;
        }

        h3 {
          font-size: 1.4rem;
          text-align: center;
          @include lh(14, 25);
        }

        .note {
          color: $red;
          font-size: 1.3rem;

          @include mq(pc) {
            text-align: center;
          }
        }
      }

      .port-list {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          width: calc(50% - 10px);

          @include mq {
            width: calc(50% - 4px);
          }
        }

        input[type="radio"] {
          display: none;

          &:checked {
            +label {
              background: $key-color;
              border-color: $key-color;
              color: #fff;

              &:before {
                content: '';
                display: block;
                width: 22px;
                height: 24px;
                background: url(../../img/common/icon_port_white.svg) no-repeat;
                background-size: contain;

              }
            }
          }
        }

        label {
          font-size: 1.8rem;
          font-weight: bold;
          background: #F5F5F5;
          border: 1px solid #D6D6D6;
          border-radius: 4px;
          width: 100%;
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          transition: .2s ease;
          min-height: 110px;

          &.no-active {
            background: #bbb;
            cursor: default;
          }

          span {
            color: $red;
            font-size: 1.2rem;

            @include mq {
              font-size: 1rem;
            }
          }

          @include mq {
            font-size: 1.2rem;
            padding: 10px;
            min-height: 90px;
          }

          &:hover {
            background: #dad7d7;
          }

          &:before {
            content: '';
            display: block;
            width: 22px;
            height: 24px;
            background: url(../../img/common/icon_port.svg) no-repeat;
            background-size: contain;
          }
        }

        .btn-gmap {
          color: $key-color;
          font-size: 1.2rem;
          border: 1px solid $key-color;
          width: 201px;
          height: 28px;
          border-radius: 14px;
          margin: 8px auto 0;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq {
            width: auto;
          }

          &:hover {
            background: $hover-color;
            color: #fff;
          }

          &:before {
            content: '';
            width: 10px;
            height: 13px;
            background: url(../../img/common/icon_pin.svg) no-repeat;
            background-size: contain;
            margin-right: 6px;
            display: inline-block;
          }
        }
      }

      .note-list {
        margin-top: 15px;

        @include mq {
          margin-top: 10px;
        }
      }
    }
  }

  &._02 {
    .ccard-box {
      border: 4px solid $gray01;
      margin: 20px 0 30px;

      @include mq(pc) {
        padding: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      @include mq {
        padding: 15px;
      }

      h4 {
        font-size: 1.8rem;
        font-weight: bold;

        @include mq {
          text-align: center;
        }
      }

      dl {
        @include mq(pc) {
          margin-left: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        dt {
          @include mq {
            width: 230px;
            margin: 10px auto 0;
          }
        }

        dd {
          font-size: 1.2rem;
          font-weight: bold;

          @include mq(pc) {
            margin-left: 10px;
          }

          @include mq {
            margin-top: 10px;
          }
        }
      }
    }

    .select-def {
      flex-direction: column;

      >div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.plan-full {
          text-align: center;
          justify-content: center;
          font-size: 1.4rem;
          color: #999;
        }

        +div {
          margin-top: 15px;

          @include mq {
            margin-top: 15px;
          }

          &:last-child {
            margin-top: 0;
          }
        }

        dt {
          &:first-child {
            font-size: 1.3rem;
            font-weight: bold;
            background: #FCF8F6;
            padding: 3px 5px;
            line-height: 1;
            margin: 0 5px 0 0;
            min-width: 50px;
            //width: 68px;
            //height: 30px;
            //border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        dd {
          &:nth-of-type(1) {
            font-size: 1.3rem;
            width: 110px;
            line-height: 1.2;

            span {
              font-size: 1.1rem;
            }
          }

          &:nth-of-type(2) {
            width: 85px;
          }
        }
      }
    }

    .ticket-def-wrap {
      margin-bottom: 20px;

      dl {
        width: 100%;

        @include mq(pc) {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }

      .wrap {
        width: calc(100% / 3 - 13px);

        @include mq {
          width: 100%;
        }

        &._sp {
          strong {
            &:after {
              content: '(SP)';
              display: inline-block;
            }
          }
        }

        &._pr {
          strong {
            &:after {
              content: '(PM)';
              display: inline-block;
            }
          }
        }

        &._st {
          strong {
            &:after {
              content: '(ST)';
              display: inline-block;
            }
          }
        }

        +.wrap {
          @include mq {
            margin-top: 20px;
          }
        }

        >dt {
          color: $white;
          width: 100%;
          height: 104px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;

          img {
            width: 34px;
            height: 20px;
            margin-bottom: 6px;
          }

          strong {
            font-size: 1.8rem;
            text-align: center;
            @include lh(18, 24);
            /*&.js-modal {
              &:after {
                content: '';
                width: 18px;
                height: 18px;
                background: url(../../img/common/icon_info.svg) no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: -2px;
                margin-left: 6px;
              }
            }*/
          }

          span {
            font-weight: bold;
          }
        }

        >dd {
          padding: 10px 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq(pc) {
            height: 300px;
          }

          @include mq {
            padding: 15px;
          }
        }

        &._sp {
          >dt {
            background: $super;
          }

          >dd {
            border: 1px solid $super;
          }
        }

        &._pr {
          >dt {
            background: $premium;
          }

          >dd {
            border: 1px solid $premium;
          }
        }

        &._st {
          >dt {
            background: $standard;
          }

          >dd {
            border: 1px solid $standard;
          }
        }
      }
    }

    .holo-def {
      >dt {
        font-size: 1.4rem;
        font-weight: bold;
        background: $gray01;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        max-width: 860px;
        margin: 0 auto;

        a {
          color: $main-color;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        @include mq {
          font-size: 1.3rem;
        }
      }

      >dd {
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq {
          margin-top: 10px;
        }
      }

      .select-def {
        @include mq(pc) {
          width: 190px;
        }

        @include mq {
          padding-top: 50px;
        }

        >div {
          +div {
            margin-top: 35px;

            @include mq {
              margin-top: 40px;
            }
          }

          dd {
            &:nth-of-type(1) {
              @include mq {
                width: 75px;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }

    .select-time-def {
      display: flex;

      @include mq(pc) {
        height: 175px;
      }

      @include mq {
        // margin-top: -29px;
      }

      >div {
        @include mq(pc) {
          display: flex;
        }

        >div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      dt {
        font-family: $font-roboto;
        font-size: 1.4rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq(pc) {
          margin-left: 40px;
          background: $gray01;
          width: 173px;
          height: 100%;

          @include mq(tablet) {
            margin-left: 10px;
            width: 150px;
          }
        }

        @include mq {
          font-size: 0.9rem;
          margin-bottom: 5px;
          text-align: center;
        }
      }

      dd {
        margin-left: 20px;

        @include mq {
          margin-left: 10px;
        }

        +dd {
          margin-top: 15px;
        }

        .select-wrap {
          font-size: 1.4rem;
          height: 45px;
        }
      }
    }

    .modal {
      &._01 {
        .ticket-def-wrap {
          margin-top: 40px;

          @include mq {
            margin-top: 15px;
          }

          .wrap {
            position: relative;

            @include mq(pc) {
              width: 300px;

              &:before,
              &:after {
                content: '';
                background: #ccc;
                width: 40px;
                height: 10px;
                display: block;
                position: absolute;
                left: 50%;
              }

              &:before {
                bottom: -39px;
                transform: translateX(-50%);
              }

              &:after {
                bottom: -40px;
                transform: rotate(90deg) translateY(200%);
              }
            }

            @include mq {
              &:last-of-type {

                &:before,
                &:after {
                  content: '';
                  background: #ccc;
                  width: 40px;
                  height: 10px;
                  display: block;
                  position: absolute;
                  left: 50%;
                }

                &:before {
                  bottom: -39px;
                  transform: translateX(-50%);
                }

                &:after {
                  bottom: -40px;
                  transform: rotate(90deg) translateY(200%);
                }
              }
            }

            >dd {
              padding: 14px;
              flex-direction: column;

              @include mq(pc) {
                height: 284px;
              }
            }
          }
        }

        .price-def {
          margin-top: 20px;
          display: flex;
          justify-content: center;

          dt {
            font-size: 1.3rem;
            font-weight: bold;
            background: #FCF8F6;
            margin: 3px 12px 0 0;
            //width: 68px;
            //height: 30px;
            padding: 3px 5px;
            min-width: 50px;
            //border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          dd {
            font-size: 1.3rem;
            text-align: center;

            span {
              &:first-child {}

              &:last-child {
                margin-top: 4px;
                display: block;
              }
            }

            em {
              font-family: $font-roboto;
              font-size: 2.4rem;
              font-weight: 400;
              margin-right: 3px;
            }
          }
        }

        .btn {
          margin-top: 20px;
        }

        .holo-text {
          font-size: 1.8rem;
          font-weight: bold;
          background: $gray01;
          margin-top: 71px;
          width: 100%;
          height: 63px;
          display: flex;
          justify-content: center;
          align-items: center;

          >span {
            &:after {
              content: '';
              width: 18px;
              height: 18px;
              display: inline-block;
              margin-left: 4px;
              background: url(../../img/common/icon_question02.svg) no-repeat;
              background-size: contain;
              vertical-align: -3px;
            }
          }
        }
      }

      &._02 {
        .blc {
          margin-top: 40px;

          @include mq {
            margin-top: 15px;
          }

          +.blc {
            margin-top: 30px;
          }

          &._sp {
            h4 {
              background: $super;
            }

            .price-def {
              >div {
                @include mq {
                  border-color: $super;
                }

                >dd {
                  @include mq(pc) {
                    border-color: $super;
                  }
                }
              }
            }
          }

          &._pr {
            h4 {
              background: $premium;
            }

            .price-def {
              >div {
                @include mq {
                  border-color: $premium;
                }

                >dd {
                  @include mq(pc) {
                    border-color: $premium;
                  }
                }
              }
            }
          }

          &._st {
            h4 {
              background: $standard;
            }

            .price-def {
              >div {
                @include mq {
                  border-color: $standard;
                }

                >dd {
                  @include mq(pc) {
                    border-color: $standard;
                  }
                }
              }
            }
          }

          &._holo {
            h4 {
              background: $key-color;
            }

            .price-def {
              >div {
                @include mq {
                  border-color: $key-color;
                }

                >dd {
                  @include mq(pc) {
                    border-color: $key-color;
                  }
                }
              }
            }
          }
        }

        h4 {
          color: $white;
          font-size: 1.8rem;
          padding: 14px 20px;

          @include mq {
            font-size: 1.5rem;
            padding: 10px 15px 10px 60px;
            position: relative;
          }

          img {
            width: 34px;
            margin-right: 8px;
            vertical-align: -4px;

            @include mq {
              position: absolute;
              top: 50%;
              left: 15px;
              transform: translateY(-50%);
            }
          }
        }

        .price-def {
          @include mq(pc) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
          }

          +.price-def {
            margin-top: 10px;
          }

          &._col4 {
            >div {
              @include mq(pc) {
                width: calc(100% / 4 - 22px);
              }
            }
          }

          >dt {
            width: 100%;
            margin-top: 20px;

            @include mq(pc) {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            @include mq {
              text-align: center;
            }

            .sttl {
              font-size: 1.4rem;
              font-weight: bold;
              background: #F6F6F6;
              width: 160px;
              height: 34px;
              border-radius: 17px;
              margin-right: 14px;
              display: flex;
              justify-content: center;
              align-items: center;

              @include mq {
                margin: 0 auto 5px;
              }
            }

            strong {
              font-size: 2.0rem;
              font-weight: bold;
              margin-right: 6px;
            }

            .text {
              font-size: 1.6rem;
            }
          }

          >div {
            width: calc(100% / 3 - 20px);
            margin-top: 20px;

            @include mq {
              width: 100%;
              margin-top: 15px;
              border-bottom-width: 1px;
              border-bottom-style: dashed;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-bottom: 6px;
            }

            dt {
              font-size: 1.4rem;
              font-weight: bold;
              background: #FCF8F6;
              width: 100%;
              height: 30px;
              //border-radius: 15px;
              display: flex;
              justify-content: center;
              align-items: center;

              @include mq {
                width: 68px;
                margin-right: 10px;
              }
            }

            dd {
              font-size: 1.3rem;
              text-align: center;

              @include mq(pc) {
                margin-top: 10px;
                border-bottom-width: 1px;
                border-bottom-style: dashed;
              }

              em {
                font-family: $font-roboto;
                font-size: 2.4rem;
                margin-right: 6px;

                @include mq {
                  font-size: 2rem;
                }

                span {
                  font-size: 1.8rem;
                  margin-left: 3px;

                  @include mq {
                    font-size: 1.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .benefits-box {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      >div {
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        background: $gray01;
        border-radius: 10px;
        width: calc(50% - 7px);
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.seat {
          background: #FDF7F7;
        }

        @include mq {
          font-size: 1.2rem;
          width: calc(50% - 5px);
        }

        img {
          width: auto;
          height: 18px;
          margin-bottom: 8px;
        }
      }
    }
  }

  &._03 {
    .gray-bdr-box01 {
      .seat-def {
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq {
          margin-top: -10px;
          flex-wrap: wrap;
        }

        >div {
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq {
            justify-content: space-between;
            margin-top: 10px;
          }

          &:not(:last-child) {
            margin-right: 20px;
          }
        }

        dt {
          font-size: 1.3rem;
          font-weight: bold;
          background: #FCF8F6;
          //width: 68px;
          //eight: 30px;
          //border-radius: 15px;
          padding: 3px 5px;
          line-height: 1;
          margin: 0 5px 0 0;
          min-width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        dd {
          font-size: 1.2rem;
          margin-left: 10px;

          em {
            font-family: $font-roboto;
            font-size: 2.2rem;
            margin-right: 4px;
          }
        }
      }

      p {
        font-size: 1.6rem;
        margin-top: 10px;

        em {
          font-family: $font-roboto;
          font-size: 2.2rem;
          margin: 0 4px 0 10px;
        }
      }
    }

    .top-info-box {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
      }

      >dl {
        background: $gray01;
        padding: 18px 42px 20px;
        width: calc(50% - 20px);

        @include mq {
          padding: 20px 10px;
          width: 100%;
        }
      }
    }

    .seat-type-def {
      display: flex;
      justify-content: space-between;
      align-items: center;

      >div {
        color: #6A6A6A;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include mq {
          width: 30%;
        }

        &:not(:last-child) {
          margin-right: 30px;

          @include mq {
            margin-right: 5px;
          }
        }

        dt {
          margin-right: 14px;
          width: 50px;
          height: 50px;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq {
            width: 35px;
            height: 35px;
            margin-right: 4px;
          }
        }

        dd {
          font-size: 1.6rem;
          font-weight: bold;
          width: calc(100% - 64px);

          @include mq {
            font-size: 1.3rem;
            width: calc(100% - 41px);
          }
        }

        &:nth-of-type(1) {
          dt {
            background: $white;
            border: 2px solid #D6D6D6;
            color: #D6D6D6;
            font-family: "Roboto", sans-serif;
            font-size: 2.2rem;
            font-weight: 500;
          }
        }

        &:nth-of-type(2) {
          color: $key-color;

          dt {
            color: $white;
            font-size: 1.6rem;
            font-weight: bold;
            background: $key-color;

            @include mq {
              font-size: 1.1rem;
            }
          }
        }

        &:nth-of-type(3) {
          dt {
            background: #E1E1E1;
            position: relative;

            &:before,
            &:after {
              content: '';
              background: #6A6A6A;
              width: 24px;
              height: 1px;
              display: block;
              position: absolute;

              @include mq {
                width: 18px;
                height: 1px;
              }
            }

            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

    .select-num-def {
      @include mq {
        margin-top: 20px;
      }

      dt {
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 15px;
      }

      dd {
        font-family: $font-roboto;
        font-size: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
          font-family: $font-sans_serif;
          font-size: 1.6rem;
          line-height: 1em;
          margin: 0 5px;

          &:first-of-type {
            margin-top: -3px;

            em {
              color: $red;
              font-size: 3.6rem;
              font-weight: bold;
              margin-right: 3px;
            }
          }

          &:last-of-type {
            font-weight: normal;
            margin-top: 4px;

            em {
              margin-right: 2px;
            }
          }

          em {
            font-family: $font-roboto;
            font-size: 2.2rem;
          }
        }
      }
    }

    .note-text {
      margin-top: 15px;
    }

    .boat-box {
      margin: 30px 0 40px;

      @include mq {
        margin: 30px -5px;
      }

      .boat-draw {
        background: $gray01;
        width: 800px;
        border-radius: 400px 400px 0 0;
        padding: 30px 20px 0;
        margin: 0 auto;

        @include mq {
          width: 100%;
          max-width: 480px;
          padding: 30px 12px 0;
        }
      }

      h4 {
        font-family: $font-roboto;
        font-size: 2.8rem;
        margin-bottom: 56px;

        @include mq {
          font-size: 2.2rem;
          margin-bottom: 40px;
        }

        span {
          font-weight: 500;
          background: $white;
          width: 91px;
          height: 41px;
          border-radius: 21px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq {
            width: 80px;
            height: 35px;
          }
        }
      }

      .table-wrap {
        width: 570px;
        margin: 0 auto;

        @include mq {
          width: 100%;
        }

        table {
          width: 100%;
          table-layout: fixed;

          th {
            font-family: $font-roboto;
            font-size: 2.2rem;
            font-weight: 500;

            @include mq {
              font-size: 1.4rem;
            }
          }

          thead {
            th {
              width: 64px;

              @include mq {
                width: auto;
              }

              &:first-of-type {
                width: 26px;

                @include mq {
                  width: 15px;
                }
              }

              &:nth-of-type(4),
              &:nth-of-type(8) {
                width: 50px;

                @include mq {
                  width: 4%;
                }
              }
            }
          }

          tbody {
            th {
              padding-bottom: 20px;

              @include mq {
                padding-bottom: 5px;
              }
            }

            td {
              padding-bottom: 20px;

              @include mq {
                padding-bottom: 5px;
              }

              input[type="checkbox"] {
                display: none;


                &:checked+label {
                  background: $key-color;
                  border: none;
                  border-color: transparent;
                }

                &.reserved_select+label {
                  border: 2px solid $key-color;
                }

                &.disabled+label {
                  background: #E1E1E1;
                  position: relative;
                  border-color: transparent;
                  cursor: default;

                  &:before,
                  &:after {
                    content: '';
                    background: #6A6A6A;
                    width: 24px;
                    height: 1px;
                    display: block;
                    position: absolute;

                    @include mq {
                      width: 18px;
                      height: 1px;
                    }
                  }

                  &:before {
                    transform: rotate(45deg);
                  }

                  &:after {
                    transform: rotate(-45deg);
                  }
                }
              }

              label {
                color: #D6D6D6;
                font-family: $font-roboto;
                font-size: 2.2rem;
                font-weight: 500;
                width: 69px;
                height: 69px;
                border-radius: 6px;
                border: 2px solid #D6D6D6;
                background: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                @include mq {
                  font-size: 1.4rem;
                  width: 38px;
                  height: 38px;
                  border-radius: 4px;

                  @include mq(lsp) {
                    width: 55px;
                    height: 55px;
                  }

                  @include mq(lmsp) {
                    width: 48px;
                    height: 48px;
                  }

                  @include mq(msp) {
                    width: 38px;
                    height: 38px;
                  }

                  @include mq(ssp) {
                    width: 33px;
                    height: 33px;
                  }
                }

              }
            }
          }
        }
      }

      .deck-box {
        width: 100%;
        height: 160px;
        background: $white;
        border-radius: 25px 25px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include mq {
          height: 100px;
        }

        p {
          font-weight: bold;

          &:first-of-type {
            font-size: 2.2rem;

            @include mq {
              font-size: 1.8rem;
            }
          }

          &:last-of-type {
            font-size: 1.8rem;

            @include mq {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  &._04-1 {
    .gray-bdr-box02 {

      //margin-top: 30px;
      @include mq {
        padding: 20px;
      }
    }

    .box-wrap {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .box {
        width: calc(50% - 15px);

        @include mq {
          width: 100%;
        }

        +.box {
          @include mq {
            margin-top: 40px;
          }
        }

        .btn {
          margin-top: 20px;
        }

        &:nth-of-type(2) {
          .btn {
            &._02 {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .login-def {
      margin-top: 20px;

      >div {
        @include mq(pc) {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }

        +div {
          margin-top: 15px;
        }
      }

      dt {
        font-weight: bold;
        margin: 16px 10px 0 0;

        @include mq {
          margin: 0 0 10px;
        }
      }

      dd {
        width: 74%;

        @include mq {
          width: 100%;
        }
      }

      .text-pw {
        font-size: 1.3rem;
        margin-top: 7px;

        a {
          opacity: .8;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }

          &:before {
            content: '';
            width: 16px;
            height: 16px;
            margin-right: 5px;
            background: url(../../img/common/icon_question02.svg) no-repeat;
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }

    .note-box {
      margin-top: 40px;
      padding: 15px;
      background: #F6F6F6;

      @include mq(pc) {
        text-align: center;
      }

      .logo-wrap {
        text-align: center;

        a {
          margin: 0 3px;
          display: inline-block;
          vertical-align: middle;
        }

        img {
          width: 40px;
          height: auto;

          @include mq {
            width: 35px;
          }
        }
      }

      .note-text {
        text-indent: 0;

        @include mq(pc) {
          display: inline-block;
        }

        @include mq {
          font-size: 1.2rem;
          padding-left: 25px;
          text-indent: 0;
        }

        &:before {
          @include mq {
            left: 0;
          }
        }
      }
    }
  }

  &._02,
  &._04-2 {
    .price-tbl {
      @include mq {
        font-size: 1.2rem;
        margin: 0 -5px;
      }

      thead {
        th {
          background: $gray01;

          &:nth-of-type(3) {
            width: 210px;

            @include mq {
              width: 90px;
            }
          }

          &:nth-of-type(4) {
            @include mq {
              width: 70px;
            }
          }
        }
      }

      tbody {
        th {
          dl {
            @include mq(pc) {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          dt {
            font-size: 1.3rem;
            font-weight: bold;
            background: #FCF8F6;
            // width: 68px;
            // height: 30px;
            // border-radius: 15px;
            min-width: 50px;
            padding: 3px 5px;
            margin: 0 5px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mq {
              font-size: 1.1rem;
              width: 50px;
            }
          }
        }

        td {
          &:nth-of-type(2) {
            text-align: right;
          }

          &:last-of-type {
            text-align: right;
          }
        }
      }

      .ticket-def {
        >div {
          @include mq(pc) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          +div {
            margin-top: 10px;
          }
        }

        .ticket {
          width: 130px;
          height: 24px;

          @include mq(pc) {
            margin-right: 10px;
          }

          @include mq {
            width: 100px;
            height: 20px;
          }
        }

        dd {
          @include mq {
            text-align: right;
            margin-top: 5px;
          }
        }
      }
    }

    .sum-box {
      font-size: 1.8rem;
      font-weight: bold;
      background: $gray01;
      padding: 20px;
      margin-top: 20px;

      @include mq {
        font-size: 1.4rem;
      }

      dl {
        @include mq(pc) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        >div {
          display: flex;
          justify-content: center;
          align-items: center;

          +div {
            @include mq(pc) {
              margin-left: 40px;
            }
          }
        }
      }

      dt {
        margin-right: 10px;

        @include mq {
          margin-top: 1px;
        }
      }

      dd {
        span {
          font-size: 1.3rem;
          margin-left: 5px;
          vertical-align: -1px;
        }
      }

      em {
        color: $red;
        font-family: $font-roboto;
        font-size: 2.5rem;
        font-weight: 500;
        margin-right: 3px;
        vertical-align: -1px;

        @include mq {
          font-size: 2.1rem;
        }
      }
    }

    .seat-tbl {
      margin-top: 20px;

      thead {
        th {
          @include mq {
            font-size: 1.3rem;
          }
        }
      }

      tbody {
        th {
          width: 300px;

          @include mq {
            width: 140px;
          }
        }
      }
    }

    .btm-btn-box {
      @include mq(pc) {
        //margin-top: 50px;
      }
    }
  }

  &._06 {
    .blc {
      margin-top: 20px;
      width: 100%;
    }

    .policy-text {
      margin: 40px 0 30px;

      @include mq(pc) {
        text-align: center;
      }

      a {
        @include link_color($key-color);
        border-bottom: 1px solid currentColor;

        &:hover {
          border-bottom: 0;
        }
      }
    }

    .gray-bdr-box02 {
      text-align: center;
      margin-top: 50px;
    }
  }

  //#p-rsv .charter
  &.charter{

    //.header-rsv
    .box{
      &._right{
        display: none!important;
      }
    }

    .btn-spmenu{
      display: none;
    }

    .plan-box,
    .calendar-box{
      @include mq{
        display: block;
        width: 100%;
      }
    }

    .btn-box{
      margin-top: 0;
      padding: 30px 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn{
        width: calc(50% - 5px);
        margin-top: 0;
        
        a{
          font-size: 1.8rem;
          font-weight: bold;
          letter-spacing: 0.05em;
          border: 2px solid #DBDBDB;
          border-radius: 5px;
          background: #fff;
          height: 80px;
          width: 100%;
          position: relative;
          transition: .3s ease;
          &::before{
            content: '';
            width: 15px;
            height: 15px;
            background: url(../../img/common/icon_ex.svg) no-repeat;
            background-size: contain;
            margin-right: 14px;
            margin: -1px 14px 0 0;
          }
          &:hover{
            color: #006FA2;
            border-color: #006FA2;
          }
          @include mq{
            height: 45px;
            font-size: 1.4rem;
          }
        }

        @include mq{
          width: 100%;
          &:first-child{
            margin-bottom: 15px;
          }
        }
      }

      @include mq{
        padding: 0;
        display: block;
      }
    }

    .calendar-box{
      //.calendar
      .calendar{
        .select-wrap {

          &.calendar_tab{
            font-size: 1.6rem;
            padding: 15px 20px 15px 10px;
            cursor: pointer;
            text-indent: 0.01px;
            line-height: 1;
            text-overflow: ellipsis;
            outline: none;
            background: transparent;
            background-image: none;
            box-shadow: none;
            -webkit-appearance: none;
            appearance: none;

            &::-ms-expand {
              display: none;
            }
          
            @include mq {
              padding: 10px 15px 10px 5px;
              font-size: 1.4rem;
              line-height: 2;
            }
          }
        }

        .owl-carousel{
          padding: 20px 0;
          max-width: 580px;
          display: none;

          table{
            width: auto;
            margin: 0 auto;

            tr{
              text-align: center;

              &:not(:last-child){
                border-bottom: 1px solid #000;
              }

              th{
                font-size: 12px;
                width: auto;
                border-top: none;
                padding: 0;
                text-align: center;
                background: #000;
                color: #fff;

                &:nth-child(6){
                  background: $premium;
                }
                &:nth-child(7){
                  background: $super;
                }
              }

              td{
                border-top: none;
                padding: 0;
                width: 35px;
                height: 36px;
                color: #000;
                background: #F6F6F6;

                &:not(:first-child){
                  border-left: 1px solid #000;
                }
                label{
                  cursor: pointer;
                  display: block;
                  height: 100%;
                  span{
                    display: block;
                    height: 100%;
                    padding-top: 4px;
                    position: relative;
                    z-index: 1;
                  }
                }
                input[type="checkbox"] {
                  display: none;

                  &:checked + span:after{
                    content: "";
                    display: inline-block;
                    background: #00b8ee;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: -1;
                  }
                }
              }
            }

            caption{
              p{
                display: inline-block;

                &.year{
                  font-size: 2.5rem;
                }
                &.month{
                  font-size: 2rem;
                  margin-left: 26%;
                }
                span{
                  font-size: 3rem;
                }
              }
            }
          }

          .owl-nav{
            height: 0;

            .calendar_next,
            .calendar_prev{
              position: absolute;
              top: 54%;
              cursor: pointer;
              background: #fff url(../../img/common/icon_arrow01_black.svg) no-repeat center;
              border: 1px solid #333;
              border-radius: 50%;
              width: 30px;
              height: 30px;
            }
            .calendar_next{
              right: -10px;
              transform: translateY(-50%) rotate(0deg);
            }
            .calendar_prev{
              left: -10px;
              transform: translateY(-50%) rotate(180deg);
            }
          }

          @include mq {
            max-width: 100%;
          }
        }
      }
    }

    .done-text{
      text-align: center;
    }

    //.footer-rsv
    .modal{
      &._02{
        display: none;
      }
    }

  }
}

.gray-bdr-under {
  border-bottom: 1px solid #ddd;
  padding: 0 0 5px 0;
  text-align: left;
  width: 100%;
}

.rsv-info-text {
  padding: 0px 10px;
  line-height: 24px;

  @include mq(pc) {
    padding: 0;
  }
}

.rsv-error-text {
  margin: 20px 0;
  padding: 0px 10px;
  line-height: 24px;
  text-align: center;

  .rsv-error-lead {
    color: $red;
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.rule-text {
  margin-top: 5px;
  font-size: 1.3rem;
}

.item-text {
  margin-bottom: 5px;
  font-size: 1.5rem;
}


#p-rsv._03 .boat-box .table-wrap table thead tr.intro-thead th {
  width: auto;
  font-size: 13px;
  font-weight: bold;
  padding: 5px 2px;
  line-height: 1.2;

  @include mq {
    font-size: 10px;
  }
}

#p-rsv._03 .boat-box .table-wrap table thead tr.intro-thead th.none02 {
  width: 10px;
  padding: 0;
}

#p-rsv._03 .boat-box .table-wrap table thead tr.intro-thead th.sp-seat {
  background: $super;
  color: #fff;
}

#p-rsv._03 .boat-box .table-wrap table thead tr.intro-thead th.pr-seat {
  background: $premium;
  color: #fff;
}

#p-rsv._03 .boat-box .table-wrap table thead tr.row-number th {
  padding: 10px 0;
}

#p-rsv._03 .boat-box .table-wrap table thead tr.row-number th.sp-color {
  color: $super;
}

#p-rsv._03 .boat-box .table-wrap table thead tr.row-number th.pr-color {
  color: $premium;
}

#p-rsv._03 .boat-box .table-wrap table tbody td label.sp-check {
  // border:2px solid #F05948;
}

#p-rsv._03 .boat-box .sp-seat-text {
  text-align: center;
  font-size: 1.6rem;
  margin: 0 0 15px;
  font-weight: bold;

  span {
    color: $super;
  }
}

#p-rsv._03 .boat-box .pr-seat-text {
  text-align: center;
  font-size: 1.6rem;
  margin: 0 0 15px;
  font-weight: bold;

  span {
    color: $premium;
  }
}



/*zeus css*/
#zeus_token_action_type_quick,
label[for="zeus_token_action_type_quick"],
#zeus_registerd_card_area,
#zeus_token_action_type_new,
label[for="zeus_token_action_type_new"] {
  display: none;
}


#zeus_new_card_area select.zeus_token_input_normal,
#zeus_new_card_area select.zeus_token_input_error {
  font-size: 1.6rem;
  width: auto;
  height: 45px;
  padding: 0 30px 0 15px;
  box-sizing: border-box;
  cursor: pointer;
  text-indent: 0.01px;
  line-height: 1;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  border: 1px solid #898989;
  min-width: 66px;
}

#zeus_token_card_expires_month_suffix,
#zeus_token_card_expires_year_suffix {
  margin: 0 10px 0 5px;
  display: inline-block;
}


#zeus_new_card_area {
  line-height: 4;
  margin: 0 auto 0px !important;
  padding: 0 0 0 200px;
  background: #fff;

}


#zeus_new_card_area label {
  font-weight: bold;
  text-align: center;
  margin-right: 20px;
  width: 200px;
  display: inline-block !important;
  background: #eee;
}

#zeus_new_card_area br {
  display: none;
}

#zeus_token_card_number {
  margin-right: 300px;
}

#zeus_token_card_expires_note {
  //margin: 0 500px 0 10px;
  display: block;
}

#zeus_token_card_cvv {
  margin-right: 400px;
  width: 150px;

  @include mq(pc) {
    @include mq(tablet) {
      margin-right: 46vw;
    }
  }
}


#zeus_token_card_info_area .zeus_token_input_error {
  background-color: #fff !important;
}

#zeus_token_card_info_area .zeus_token_input_normal {
  background-color: #ffffff;
}

#zeus_token_card_info_area .zeus_token_input_disable {
  background-color: #f9f9f9;
}

#zeus_registerd_card_area,
#zeus_new_card_area {
  margin: 0 0 10px 4em;

  @include mq(pc) {
    @include mq(tablet) {
      padding: 0 20px;
    }
  }
}

#zeus_registerd_card_area label,
#zeus_new_card_area label {
  margin-top: 0 !important;
  margin-bottom: 5px;
  display: block;
}

#zeus_token_card_cvv_data_for_registerd_card,
#zeus_token_card_cvv_data {
  width: 4em;
  ime-mode: disabled;
}

#zeus_token_card_number {
  width: 16em;
  ime-mode: disabled;

  @include mq(pc) {
    @include mq(tablet) {
      margin-right: 32vw;
    }
  }
}

#zeus_token_card_name {
  width: 25em;
  ime-mode: disabled;
}

#p-rsv._06 .policy-text {
  margin: 15px 0 20px;
}

@include mq {

  #zeus_token_action_type_quick,
  label[for="zeus_token_action_type_quick"],
  #zeus_registerd_card_area,
  #zeus_token_action_type_new,
  label[for="zeus_token_action_type_new"] {
    display: none;
  }

  #zeus_new_card_area {
    margin: 0 !important;
    padding: 0 0 30px 0;
    border-top: none;
    background: #fff;
  }

  #zeus_new_card_area label {
    font-weight: bold;
    margin: 30px 0 12px !important;
    width: 100%;
    padding: 0 0 0 10px;
    background: #eee;
    font-size: 120%;
    text-align: left;
  }

  #zeus_new_card_area label:first-child {
    margin: 0 0 12px !important;
  }

  #zeus_token_card_number,
  #zeus_token_card_expires_month,
  #zeus_token_card_cvv,
  #zeus_token_card_name {
    margin-left: 0 !important;
  }

  #zeus_token_card_number,
  #zeus_token_card_name {
    width: 80% !important;
  }

  #zeus_new_card_area br {
    display: none;
  }

  #zeus_token_card_expires_note {
    font-size: 11px;
    //margin-left: 10px;
  }

  #p-rsv._06 .policy-text {
    margin: 20px 0;
  }
}


.modal {


  &._02 {
    .blc {
      margin-top: 40px;

      @include mq {
        margin-top: 15px;
      }

      +.blc {
        margin-top: 30px;
      }

      &._sp {
        h4 {
          background: $super;
        }

        .price-def {
          >div {
            @include mq {
              border-color: $super;
            }

            >dd {
              @include mq(pc) {
                border-color: $super;
              }
            }
          }
        }
      }

      &._pr {
        h4 {
          background: $premium;
        }

        .price-def {
          >div {
            @include mq {
              border-color: $premium;
            }

            >dd {
              @include mq(pc) {
                border-color: $premium;
              }
            }
          }
        }
      }

      &._st {
        h4 {
          background: $standard;
        }

        .price-def {
          >div {
            @include mq {
              border-color: $standard;
            }

            >dd {
              @include mq(pc) {
                border-color: $standard;
              }
            }
          }
        }
      }

      &._holo {
        h4 {
          background: $key-color;
        }

        .price-def {
          >div {
            @include mq {
              border-color: $key-color;
            }

            >dd {
              @include mq(pc) {
                border-color: $key-color;
              }
            }
          }
        }
      }
    }

    h4 {
      color: $white;
      font-size: 1.8rem;
      padding: 14px 20px;

      @include mq {
        font-size: 1.5rem;
        padding: 10px 15px 10px 60px;
        position: relative;
      }

      img {
        width: 34px;
        margin-right: 8px;
        vertical-align: -4px;

        @include mq {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
        }
      }
    }

    .price-def {
      @include mq(pc) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
      }

      +.price-def {
        margin-top: 10px;
      }

      &._col4 {
        >div {
          @include mq(pc) {
            width: calc(100% / 4 - 22px);
          }
        }
      }

      >dt {
        width: 100%;
        margin-top: 20px;

        @include mq(pc) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include mq {
          text-align: center;
        }

        .sttl {
          font-size: 1.4rem;
          font-weight: bold;
          background: #F6F6F6;
          width: 160px;
          height: 34px;
          border-radius: 17px;
          margin-right: 14px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq {
            margin: 0 auto 5px;
          }
        }

        strong {
          font-size: 2.0rem;
          font-weight: bold;
          margin-right: 6px;
        }

        .text {
          font-size: 1.6rem;
        }
      }

      >div {
        width: calc(100% / 3 - 20px);
        margin-top: 20px;

        @include mq {
          width: 100%;
          margin-top: 15px;
          border-bottom-width: 1px;
          border-bottom-style: dashed;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 6px;
        }

        dt {
          font-size: 1.4rem;
          font-weight: bold;
          background: #FCF8F6;
          width: 100%;
          height: 30px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq {
            width: 68px;
            margin-right: 10px;
          }
        }

        dd {
          font-size: 1.3rem;
          text-align: center;

          @include mq(pc) {
            margin-top: 10px;
            border-bottom-width: 1px;
            border-bottom-style: dashed;
          }

          em {
            font-family: $font-roboto;
            font-size: 2.4rem;
            margin-right: 6px;

            @include mq {
              font-size: 2rem;
            }

            span {
              font-size: 1.8rem;
              margin-left: 3px;

              @include mq {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

.c-card-area {
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  padding: 15px 15px 12px;
  margin: 0 auto;
  text-align: center;

  p.img {
    width: 300px;
    margin: 0 auto 5px;

    @include mq {
      width: 200px;
    }
  }

}

.check-text {
  max-width: 860px;
  margin: 20px auto 0;
  padding: 20px 0 0;
  border-top: 1px solid #D6D6D6;
  font-size: 1.3rem;

  .color {
    color: #FCE4E4;
    font-size: 2rem;
  }

  a {
    text-decoration: underline;
    color: $main-color;

    &:hover {
      text-decoration: none;
    }
  }
}

.attention-area {
  &.center {
    text-align: center;

    @include mq {
      text-align: left;
    }
  }

  &.mb0 {
    margin-bottom: 0;
  }

  &.mt0 {
    margin-top: 0;
  }

  padding: 15px 15px 15px 20px;
  margin: 20px auto;
  max-width: 860px;
  width: calc(100% - 40px);
  background: #fde9ef;
  border-radius: 4px;

  li {
    margin: 0 0 5px;

    &:last-child {
      margin: 0;
    }
  }

  li,
  p {
    font-weight: bold;
    font-size: 1.3rem;
    position: relative;
    padding-left: 1.1em;
    text-indent: -1.1em;
    line-height: 1.3;

    &:before {
      content: "※";
      position: relative;
      left: 0;
      top: 50%;
    }
  }

  @include mq {
    width: 100%;
    padding: 10px 10px 10px 10px;
    margin: 10px auto 15px;

    li,
    p {
      font-size: 1.2rem;
    }
  }
}

.reserve-content {
  background: #e6f2f7;
  padding: 10px;
  margin: 20px auto;
  max-width: 860px;
  width: 100%;
  border-radius: 4px;
  text-align: center;

  li {
    margin: 0 0 5px;
    font-size: 1.8rem;
    line-height: 1.3;
    color: #222;
    font-weight: bold;

    &:last-child {
      margin: 0;
    }
  }

  @include mq {
    width: 100%;
    padding: 10px 10px 10px 10px;
    margin: 20px auto;
    text-align: left;

    li,
    p {
      font-size: 1.2rem;
    }
  }
}

em.special-text {
  background: $main-color02;
  display: inline-block;
  color: #fff;
  padding: 0 0 0 5px;
  margin: 0 5px 0 0;
}



.youtube-movie {
  text-align: center;
  max-width: 860px;
  width: 100%;
  height: 450px;
  margin: 0 auto;

  @include mq(tablet) {
    width: 100%;
  }

  @include mq {
    padding-top: 56.25%;
    height: auto;
    position: relative;
  }

  iframe {
    @include mq(tablet) {
      width: 100% !important;
    }

    @include mq {
      height: 100% !important;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}