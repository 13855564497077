.btm-ig {
  background: url(../../img/common/bg_ig.jpg) no-repeat;
  background-size: cover;
  margin-left: 280px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq(tablet) {
    margin-left: 0;
  }

  a {
    font-family: $font-zen;
    color: $white;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  dt {
    font-size: 2.6rem;
    @include ls(275);
    line-height: 1.5;

    @include mq {
      font-size: 2rem;
    }
  }

  dd {
    font-size: 1.6rem;
    @include ls(195);
    margin-top: 19px;

    @include mq {
      font-size: 1.5rem;
    }

    i {
      margin-right: 5px;
    }
  }
}

.footer {
  color: $white;
  @include ls(25);
  padding-left: 280px;
  background: #0F0F0F;
  padding-top: 49px;

  @include mq(tablet) {
    padding-left: 0;
  }

  &-inner {
    border-top: 1px solid #363636;
    padding: 10px 60px 68px;
    display: flex;
    justify-content: space-between;

    @include mq(tablet) {
      padding: 30px 15px 15px;
      display: block;
    }
  }

  .box {
    &:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;

      @include mq(tablet) {
        width: 100%;
      }
    }
  }

  .bnr-list {
    display: flex;
    justify-content: flex-start;

    @include mq {
      display: block;
    }

    li {
      margin-right: 30px;

      @include mq {
        margin: 0 0 10px;
      }

      img {
       // height: 40px;

        @include mq {
          height: auto;
          width: 170px;
          display: block;
        }
      }

    }
  }

  .address {
    font-size: 1.4rem;
    @include ls(75);
    @include lh(14, 24);
    margin-top: 25px;

    @include mq {
      font-size: 1.2rem;
    }
  }

  .def {
    font-family: $font-readex;
    font-size: 1.5rem;
    @include ls(75);
    @include lh(14, 24);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include mq {
      font-size: 1.3rem;
    }

    dd {
      margin-right: 1em;
    }

    a {
      @include link_color($white);
    }
  }

  .sns-list {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include mq(tablet) {
      margin-top: 15px;
      justify-content: center;
    }

    li {
      margin: 0 5px;

      a {
        color: $white;
        font-size: 1.4rem;
        width: 30px;
        height: 30px;
        border: 1px solid #B4B4B4;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: .7;
        }
      }
    }
  }

  .link-list {
    display: flex;
    justify-content: flex-end;

    @include mq(tablet) {
      width: 100%;
      margin-top: 20px;
      justify-content: center;
    }

    li {
      &:not(:last-child) {
        &:after {
          content: ' ｜ ';
          pointer-events: none;
        }
      }

      a {
        color: #C1C1C1;
        font-size: 1.2rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .copy {
    margin-top: 11px;
    text-align: right;
    display: block;

    @include mq(tablet) {
      width: 100%;
      text-align: center;
    }

    small {
      color: #C1C1C1;
      font-family: $font-zen;
      font-size: 1.1rem;
      @include ls(100);
    }
  }
}

.footer-rsv {
  background: #101010;
  padding: 25px 0;
  text-align: center;

  //display: flex;
  //justify-content: center;
  //align-items: center;
  @include mq {
    //height: 40px;
  }

  .tel-def {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin: 10px 0 0;

    @include mq {
      font-size: 1.0rem;
    }

    div {
      padding: 0 15px;

      &:first-child {
        border-right: 1px solid #666;
      }
    }

    dt {
      margin: 0 0 5px;
      font-weight: bold;
    }

    dd {
      a {
        font-family: Arial, Helvetica, sans-serif;
        color: #fff;
      }
    }
  }

  p {
    small {
      color: $white;
      font-family: $font-roboto;
      font-size: 1.2rem;

      @include mq {
        font-size: 1.0rem;
      }
    }
  }
}

.kdsfooter1 {
  display: none !important;
}